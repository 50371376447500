import { MdDelete, MdDownload, MdEdit } from 'react-icons/md'
import { useLazyQuery, useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import { IPdfData } from '../graphql/model'
import { FIND_PDF_URL_BY_MACHINE_ID, GENERATE_PDF } from '../graphql/query'
import { REMOVE_MACHINE } from '../graphql/mutation'
import STATUS from '../../enums/STATUS'

interface IMachineSubHeaderProps {
	machineId?: number | undefined | string
	title: string
	machineStatus: number
	machineUpdated: boolean
	refetch: any
	machineLoading: boolean
}

const MachineSubHeader = (props: IMachineSubHeaderProps) => {
	const { machineId, title, machineStatus, machineUpdated, refetch, machineLoading } = props

	const { t } = useTranslation()
	const navigate = useNavigate()

	const { pathname } = useLocation()

	const getUrl = () => {
		if (pathname.split('/').includes('admin')) {
			return `/admin/machines/edit/${machineId}`
		}
		return `/company-machine/edit/${machineId}`
	}

	const [removeMachine, { loading: removeLoading }] = useMutation(
		REMOVE_MACHINE,
		{
			onCompleted() {
				toast.success(`${t('message.remove_machine_success')}`)
				navigate(-1)
			},
			onError(error) {
				toast.error(error.message || `${t('message.remove_machine_fail')}`)
			}
		}
	)

	const [generatePdf, { loading: generatePdfLoading }] = useMutation(
		GENERATE_PDF,
		{
			variables: {
				id: machineId
			},
			onCompleted: () => {
				toast.success(t('message.pdf_generate_success'))
				refetch(
					{
						id: parseInt(String(machineId), 10),
					}
				)
			},
			onError: error => {
				toast.error(error.message || `${t('message.pdf_not_found_generate')}`)
			}
		}
	)

	const [getPdf, { loading: pdfDataLoading }] = useLazyQuery<IPdfData>(
		FIND_PDF_URL_BY_MACHINE_ID,
		{
			variables: {
				machine_id: machineId
			},
			onCompleted: data => {
				window.open(data.findPdfUrlByMachineId, '_blank')
			},
			onError: error => {
				toast.error(error.message || `${t('message.pdf_not_found_generate')}`)
			}
		}
	)

	const handleDownloadPdf = async () => {
		if (machineUpdated) {
			await generatePdf()
		}
			await getPdf()
	}

	// const handleGeneratePdf = async () => {
	// 	await generatePdf()
	// }

	const onDeleteHandler = async () => {
		await removeMachine({
			variables: {
				id: machineId
			}
		})
	}

	return (
		<div className='flex justify-between items-center'>
			<h1 className='text-primary text-xl tracking-wide font-bold '>{title}</h1>
			<div className='w-fit flex justify-end flex-wrap gap-2'>
				{/* { */}
				{/*	machineUpdated && <button */}
				{/*		type='button' */}
				{/*		className={`flex items-center gap-2 btn btn-sm btn-primary ${ */}
				{/*			(generatePdfLoading) && 'loading disabled' */}
				{/*		}`} */}
				{/*		onClick={() => handleGeneratePdf()} */}
				{/*	> */}
				{/*		<MdCreate className='text-xl' /> {t('api.generate_pdf')} */}
				{/*	</button> */}
				{/* } */}
				<button
					type='button'
					className={`flex items-center gap-2 btn btn-sm btn-primary ${
						(pdfDataLoading || generatePdfLoading || machineLoading) && 'loading disabled'
					}`}
					onClick={() => handleDownloadPdf()}
				>
					<MdDownload className='text-xl' /> {t('api.download_pdf')}
				</button>
				{machineStatus === STATUS.CONCEPT && (
					<>
						<Link className='btn btn-secondary btn-sm' to={getUrl()} replace>
							<MdEdit className='text-xl' /> {t('api.edit_machine')}
						</Link>
						<button
							type='button'
							className={`flex items-center gap-2 btn btn-sm btn-error ${removeLoading ? 'loading btn-disabled' : ''}`}
							onClick={onDeleteHandler}
						>
							<MdDelete className='text-xl' />{' '}
							{t('api.delete_machine')}
						</button>
					</>
				)}
			</div>
		</div>
	)
}

export default MachineSubHeader
