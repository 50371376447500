import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { filter, map } from 'lodash'
import Tag from './components/tags/component/Tag'

type IMachineExtraData = {
	value: string
	value_id: string | number
	attribute_id: string | number
	attribute_name: string
	translatable: boolean
	translatable_value: boolean
}

interface MachineItemExtraInfoProps {
	extraInfo: IMachineExtraData[]
}
const replaceText = (text: string) => text.replaceAll('_', ' ')

const MachineItemExtraInfo = (props: MachineItemExtraInfoProps) => {
	const { extraInfo } = props
	const [textAreaCollection, setTextAreaCollection] = useState<any>([])
	const [otherCollection, setOtherCollection] = useState<any>([])
	const [tagsCollection, setTagCollection] = useState<any>([])

	const { t } = useTranslation()

	const mappedInformation = () => {
		setTextAreaCollection(
			filter(extraInfo, (item: any) => item.input_type === 'textarea') || []
		)
		setTagCollection(
			filter(extraInfo, (item: any) => item.input_type === 'tag') || []
		)
		setOtherCollection(
			filter(
				extraInfo,
				(item: any) => item.input_type !== 'textarea' && item.input_type !== 'tag'
			) || []
		)
	}

	useEffect(() => {
		mappedInformation()
	}, [extraInfo])
	return (
		<div className='flex justify-start gap-4 flex-wrap'>
				<div className='w-full md:w-fit md:flex-1'>
					{otherCollection.map(
						(machineExtraData: IMachineExtraData, index: string | number) => (
							<div
								className='flex items-center border'
								key={`${machineExtraData.attribute_name}-${machineExtraData.attribute_id}-${index}`}
							>
								<p className='bg-gray-100 flex-1 px-4 py-2 text-primary leading-6  break-words  font-avenirMedium'>
									{machineExtraData.translatable
										? t(`fields.${machineExtraData.attribute_name}`)
										: replaceText(machineExtraData.attribute_name)}
								</p>
								<p className=' flex-1 px-4 py-2 leading-6  break-words  font-avenirMedium bg-gray-50'>
									{machineExtraData.translatable_value ? t(`fields.${machineExtraData.value}`) : machineExtraData.value}
								</p>
							</div>
						)
					)}
			</div>
			<div className='w-full md:w-fit md:flex-1 flex flex-col gap-4'>
					{textAreaCollection.map(
						(textAreaItem: IMachineExtraData, index: string | number) => (
							<div
								className='flex flex-col gap-1 items-start bg-white'
								key={`${textAreaItem.attribute_name}-${textAreaItem.attribute_id}-${index}`}
							>
								<p className='flex-1 w-full bg-gray-200 px-4 py-2 text-primary leading-6  break-words  font-avenirMedium'>
									{textAreaItem.translatable
										? t(`fields.${textAreaItem.attribute_name}`)
										: replaceText(textAreaItem.attribute_name)}{' '}
								</p>
								<div className=' flex-1 px-4 py-2 leading-6  break-words  font-avenirMedium '>
									{textAreaItem.value}
								</div>
							</div>
						)
					)}
					{tagsCollection.map((tag: IMachineExtraData, index: number) => (
						<div
							className='flex flex-col gap-1 items-start bg-white'
							key={`${tag.attribute_name}-${tag.attribute_id}-${index}`}
						>
							<p className='flex-1 w-full bg-gray-200 px-4 py-2 text-primary leading-6  break-words  font-avenirMedium'>
								{tag.translatable
									? t(`fields.${tag.attribute_name}`)
									: replaceText(String(tag.attribute_name))}
							</p>
							<div className=' flex-1 px-4 py-2 leading-6  break-words  font-avenirMedium flex gap-2 flex-wrap '>
								{map(tag.value.split(','), (item: string, index) => (
									<Tag key={index} label={item} value={tag.translatable_value ? t(`fields.${item}`) : item} checked />
								))}
							</div>
						</div>
					))}
			</div>
		</div>
	)
}
export default MachineItemExtraInfo
