import Flatpickr from 'react-flatpickr'
import { ReactElement, useEffect, useRef, useState } from 'react'
import flatpickr from 'flatpickr'
import 'flatpickr/dist/themes/material_blue.css'
import { Dutch } from 'flatpickr/dist/l10n/nl.js'
import { english } from 'flatpickr/dist/l10n/default.js'
import MultiSelect from './MultiSelect'
import { IOptions } from './Select'
import useTranslationData from '../../modules/translation/hooks/useTranslationData'

interface IDatePicker {
	value?: any
	setDate?: (date: Date) => void
	variant: 'date_picker'
}

interface IYearPicker {
	value: string
	setDate?: (date: string) => void
	variant: 'year_picker'
}

interface IDatePickerProps {
	dataEnableTime: boolean
	disabled?: boolean
	option?: flatpickr.Options.Options | undefined
	placeHolder?: string
	style?: string
	rightContent?: ReactElement
	errorMessage?: any
}

type IConditionType = IDatePicker | IYearPicker

function DatePicker(props: IDatePickerProps & IConditionType) {
	const flatPickerRef = useRef<any>(null)

	const {
		dataEnableTime,
		value,
		disabled,
		option,
		variant,
		setDate,
		style,
		rightContent,
		placeHolder,
		errorMessage,
	} = props
	const defaultOutlineStyle =
		'w-full outline outline-[1px] outline-gray-300 drop-shadow-sm focus-within:outline-secondary p-2 rounded-sm justify-between items-center cursor-pointer'
	const inlineCalendar = 'flex flex-col justify-center item-center w-full'

	const defaultStyle = `outline-none ${option?.inline ? 'hidden' : 'block'} `

	const yearList: IOptions[] = []

	const date = new Date().getFullYear()
	for (let i = 0; i < 50; i++) {
		yearList.push({ label: (date - i).toString(), value: (date - i).toString() })
	}

	const { t, selectedLanguage } = useTranslationData()
	const [locale, setLocale] = useState<any>()

	useEffect(() => {
		switch (selectedLanguage) {
			case 'nl':
				setLocale(Dutch)
				break
			case 'en':
				setLocale(english)
				break
			default:
				setLocale(english)
		}
	}, [selectedLanguage])

	return (
		<>
			{variant === 'date_picker' && (
				<div
					className={option?.inline ? inlineCalendar : `${defaultOutlineStyle}`}
					onClick={() => {
						flatPickerRef?.current?.flatpickr?.open()
					}}
					aria-hidden='true'
				>
					<Flatpickr
						ref={flatPickerRef}
						className={`${defaultStyle} ${style}`}
						data-enable-time={dataEnableTime}
						value={value}
						placeholder={placeHolder}
						options={{
							...option,
							locale: {
								...locale,
							},
						}}
						disabled={disabled}
						onChange={([date]) => {
							variant === 'date_picker' && setDate && setDate(date)
						}}
					/>
					{rightContent && (
						<span className='ml-1 cursor-pointer' key='right'>
							{rightContent}
						</span>
					)}
					{errorMessage && <p className='text-sm text-error'>{t(errorMessage)}</p>}
				</div>
			)}
			{variant === 'year_picker' && (
				<MultiSelect
					placeHolder='Select Year'
					options={yearList}
					name='Year'
					value={value}
					variant='single'
					onChange={value =>
						variant === 'year_picker' && value && setDate && setDate(value.toString())
					}
				/>
			)}
		</>
	)
}

export default DatePicker
