import { gql } from '@apollo/client'

export const FIND_ALL_COMMISSION_WITH_MACHINE = gql`
	query commission {
		commission {
			id
			machine {
				id
				brand
				bid {
					id
					status
					value
				}
			}
			commission {
				id
				detail
				value_in
				machine_type
                user_type
			}
			section {
				id
			}
			commission_amount
			machine_type
			status
			created_at
		}
	}
`
