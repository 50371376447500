import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import Translation from "../../translation";
import ForgotPasswordForm from "../components/ForgotPasswordForm";

const ForgotPassword = () => {

    const { t } = useTranslation()

    return (
        <div className='h-full w-full p-8 md:p-12 flex flex-col gap-5'>
            <div className='flex justify-between items-end'>
                <h1 className='text-2xl font-semibold'>{t('api.login')}</h1>
                <div className='flex gap-2 items-center'>
                    <Translation/>
                    {/* <Link className='text-lg underline' to='/help'>{t('api.help')}</Link> */}
                </div>
            </div>
            <ForgotPasswordForm />
            <Link to='/auth/login' className='text-center underline'>{t('api.go_back_to_login')}</Link>
        </div>
)}

export default ForgotPassword
