interface IImage {
	source: string
	altText: string
	imageStyle?: string
	s3Image?: boolean
}

const imageLocation = process.env.REACT_APP_S3_LOCATION || ''

const Image = (props: IImage) => {
	const { source, altText, imageStyle, s3Image } = props

	return (
		<img
			src={s3Image ? `${imageLocation}/${source}` : source}
			alt={altText}
			loading='lazy'
			className={`${imageStyle}`}
		/>
	)
}

export default Image
