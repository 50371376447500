import { NavLink } from 'react-router-dom'
import { ISubHeaderProps } from './model'

const AdminSubHeader = (props: ISubHeaderProps) => {
	const { children, breadCrumbs } = props

	return (
		<div className='pb-5 flex justify-between items-center'>
			<div className='text-sm breadcrumbs'>
				<ul>
					{breadCrumbs && breadCrumbs.map((breadcrumb, index) => (
						<li key={index}>
							<NavLink to={breadcrumb.link}>
								<p className='text-lg tracking-normal'>{breadcrumb.label}</p>
							</NavLink>
						</li>
					))}
				</ul>
			</div>
			{children}
		</div>
	)
}

export default AdminSubHeader
