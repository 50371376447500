import STATUS from 'enums/STATUS'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { useBidContext } from 'context/useBidContext'
import { Key } from 'react'
import background from '../../../assets/images/authBackground.jpg'
import StatusCard from '../../../utils/getMachineStatus'
import { IBid } from '../../admin/views/Machine/graphql/model'
import { BREAK_POINT } from '../../../utils/constant'
import MachineBidForm from './MachineBidForm'
import { IMachineOverviewParams } from '../graphql/model'
import BID_STATUS from '../../../enums/BidStatus'

interface IMachineCardProps {
	id: number
	image: string | null
	machineName: string
	location: string
	machineDescription: IDescriptionItemProps[] | any
	hideBidPrice?: boolean
	machineBid?: IBid[]
	machineStatus: STATUS
	bidStatus?: any
	cardRef?: any
	queryParams?: IMachineOverviewParams
	isArchive?: boolean
	hideBidStatus?: boolean
	hideMachineBidStatus?: boolean
	machineBidStatus?: STATUS | BID_STATUS
	// minBidAmount?: number | string
}

interface IDescriptionItemProps {
	label: string
	description: string
}

const DescriptionItem = (props: IDescriptionItemProps) => {
	const { label, description } = props

	return (
		<div className='flex justify-between w-full'>
			<p className='text-sm md:text-lg text-gray-400 text-left'>{label}:</p>
			<p className='text-sm md:text-lg text-right'>{description}</p>
		</div>
	)
}

const MachineCard = (props: IMachineCardProps) => {
	const imageLocation = process.env.REACT_APP_S3_LOCATION || ''
	const { t } = useTranslation()
	const routeLocation = useLocation()

	const {
		id,
		image,
		machineName,
		location,
		machineDescription,
		hideBidPrice,
		machineStatus,
		machineBid,
		bidStatus,
		cardRef,
		queryParams,
		isArchive,
		machineBidStatus,
		hideBidStatus,
		hideMachineBidStatus,
		// minBidAmount,
	} = props

	const navigate = useNavigate()
	const baseUrl = !hideBidPrice ? '/machine' : '/company-machine'
	const currentRoute: string = routeLocation.pathname.substring(1)
	const { clearBidState } = useBidContext()

	const machineOverviewUrl = () => {
		if (machineStatus === STATUS.CONCEPT && currentRoute !== 'bidded-machine') {
			return `${baseUrl}/concept/${id}`
		}
		if (currentRoute === 'bidded-machine') {
			return `/bidded-machine/${id}`
		}
		return `${baseUrl}/${id}`
	}

	const width = window.innerWidth

	const handleMachineInfo = () => {
		clearBidState()
		// @ts-ignore
		queryParams
			? navigate({
				pathname: machineOverviewUrl(),
				search: `?categories=${queryParams.categories || ''}&type=${
					queryParams.type || ''
				}&subcategory=${queryParams.subCategories || ''}&source=${
					queryParams.source || ''
				}&page=${queryParams.page || ''}&status=${
					queryParams.status || ''
				}&bid-status=${queryParams.bidStatus || ''}
					&auction-date=${queryParams.auctionDay || ''}
					`
			})
			: navigate(machineOverviewUrl())
	}

	return (
		<div
			ref={cardRef}
			aria-hidden
			className='card w-full h-fit md:min-h-40 md:max-h-52 bg-white flex flex-row hover:shadow-md rounded-md'
			onClick={() => width < BREAK_POINT && handleMachineInfo()}
		>
			<div className='w-5/12 md:w-64'>
				<img
					src={image ? `${imageLocation}/${image}` : background}
					alt='machine'
					className='h-full w-full object-cover'
				/>
			</div>
			<div className='flex flex-row flex-1 justify-between items-start gap-2 md:gap-6 p-3 md:px-6 md:py-4'>
				<div className='flex w-full gap-1 md:gap-2 h-full flex-col justify-between'>
					<div className='flex justify-between flex-col md:flex-row'>
						<div className='flex gap-4'>
							<div>
								<h2 className='text-primary opacity-90 text-lg md:text-xl'>{`#${id} | ${machineName}`}</h2>
								<p className='text-gray-500 text-sm md:text-lg'>{location}</p>
							</div>
						</div>
						<div className='w-full md:w-fit flex flex-wrap justify-end gap-1'>
							 <StatusCard statusNumber={machineStatus} statusType='machineStatus' />
							{!hideBidStatus && bidStatus && (
								<StatusCard statusNumber={bidStatus} statusType='bidStatus' />
							)}
							{!hideMachineBidStatus &&
								<StatusCard statusNumber={machineBidStatus || 0} statusType='machineBidStatus' />}
							{isArchive && <StatusCard statusNumber={1} statusType='archive' />}
						</div>
					</div>
					<div className='grid grid-cols-1 md:grid-cols-2 gap-y-1 md:gap-y-2 gap-x-1 md:gap-x-4'>
						{machineDescription
							.slice(0, 6)
							.map(
								(
									machine: { label: string; description: string },
									index: Key | null | undefined
								) => (
									<DescriptionItem
										key={index}
										label={machine.label}
										description={machine.description}
									/>
								)
							)}
					</div>
				</div>
				<div className='hidden md:flex flex-col gap-2 w-64 h-full justify-end items-end'>
					<button
						type='button'
						className='btn btn-secondary btn-sm  btn-outline w-full flex items-center gap-2'
						onClick={() => {
							handleMachineInfo()
						}}
					>
						{t('api.more_info')}
					</button>
					{!hideBidPrice ? (
						<MachineBidForm
							machineId={id}
							machineBid={machineBid}
							isCard
							queryParams={queryParams}
							// minBidAmount={minBidAmount || 0}
						/>
					) : null}
				</div>
			</div>
		</div>
	)
}

export default MachineCard
