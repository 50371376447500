import { gql, useMutation, useQuery } from '@apollo/client'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import Loader from 'shared/components/loader'

const CHECK_UNSUBSCRIBE_GQL = gql`
	query CheckUnsubscribe($unsubscribeToken: String!) {
		checkSubscribe(token: $unsubscribeToken)
	}
`

const UNSUBSCRIBE_GQL = gql`
	mutation ToggleUserSubscribe($unsubscribeToken: String) {
		toggleUserSubscribe(token: $unsubscribeToken)
	}
`

const Unsubscribe = () => {
	const { unsubscribeToken } = useParams()
	const { t } = useTranslation()
	const navigate = useNavigate()
	const [alreadySubscribed, setAlreadySubscribed] = useState(false)

	const { loading } = useQuery(CHECK_UNSUBSCRIBE_GQL, {
		skip: !unsubscribeToken,
		variables: {
			unsubscribeToken,
		},
		onCompleted: data => {
			if (data.checkSubscribe === 'no_user') {
				toast.error(`${t('message .user_not_found')}`)
				navigate('/')
			} else {
				setAlreadySubscribed(Boolean(data.checkSubscribe === 'unsubscribed'))
			}
		},
		onError: error => {
			setAlreadySubscribed(false)
			navigate('/')
			toast.error(error.message || `${t('message.unsubscribe_failed')}`)
		},
	})

	const [unsubscribe] = useMutation(UNSUBSCRIBE_GQL, {
		onCompleted: data => {
			setAlreadySubscribed(data.toggleUserSubscibe)
		},
		onError: error => {
			setAlreadySubscribed(false)
			navigate('/')
			toast.error(error.message || `${t('message.unsubscribe_failed')}`)
		},
	})

	if (loading) {
		return <Loader />
	}

	if (alreadySubscribed) {
		return (
			<div className='h-full w-full py-16 flex justify-center'>
				<div className='flex flex-col gap-8'>
					<p className='text-4xl font-bolder uppercase text-center'>
						{t('api.unsubscribe_all_ready_subscribed')}
					</p>
				</div>
			</div>
		)
	}
	return (
		<div className='h-full w-full py-16 flex justify-center'>
			<div className='flex flex-col gap-8'>
				<p className='text-4xl font-bolder uppercase leading-3 text-center'>
					{t('api.unsubscribe_request')}
				</p>
				<p className='text-2xl text-center'>
					{t('api.unsubscribe_request_description')}
				</p>

				<div className='flex gap-8 items-center justify-center'>
					<button
						type='button'
						className='btn btn-primary btn-sm rounded-md '
						onClick={() =>
							unsubscribe({
								variables: {
									unsubscribeToken,
								},
							})
						}
					>
						{t('api.unsubscribe_request_button')}
					</button>
					<button
						type='button'
						className='btn btn-outline btn-sm rounded-md '
						onClick={() => navigate('/')}
					>
						{t('api.cancel')}
					</button>
				</div>
			</div>
		</div>
	)
}

export default Unsubscribe
