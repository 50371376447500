/* eslint-disable consistent-return */
import { useQuery } from '@apollo/client'
import AdminSubHeader from 'modules/admin/components/AdminSubHeader'
import { IBreadCrumbLink } from 'modules/admin/components/AdminSubHeader/model'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Checkbox from 'shared/components/Checkbox'
import Table from 'shared/components/table/table'
import { map } from 'lodash'
import { FIND_ALL_COMMISSION_WITH_MACHINE } from './graphql/query'

const Commission = () => {
	const { t } = useTranslation()
	const [commissionList, setCommissionList] = useState<any>([])

	const breadCrumb: IBreadCrumbLink[] = [
		{
			label: t('api.commission_sidebar'),
			link: '',
		},
	]

	const { loading } = useQuery(FIND_ALL_COMMISSION_WITH_MACHINE, {
		onCompleted: data => {
			setCommissionList([])
			const commissionData = map(data?.commission || [], item => ({
				id: item.id,
				status: item.status,
				machine: item.machine,
				commission: item.commission[0],
				commission_amount: item.commission_amount,
			}))
			setCommissionList([...commissionData])
		},
	})

	const columns: any = [
		{
			id: 'id',
			name: t('api.id'),
			right: true,
			width: '80px',
			sortable: true,
			selector: (row: any) => row.id,
			cell: (row: any) => <p className='text-lg'> {row.id}</p>,
		},
		{
			id: 'machine_id',
			name: t('api.machine_id'),
			left: true,
			sortable: true,
			selector: (row: any) => row.machine.id,
			cell: (row: any) => <p className='text-lg'> {row.machine?.id}</p>,
		},
		{
			id: 'commission',
			name: t('api.commission_title'),
			left: true,
			height: 'fit-content',
			wrap: true,
			sortable: true,
			width: '250px',
			cell: (row: any) => {
				const { detail } = row.commission
				return <p className='text-md'>{t(`api.${detail}`)} </p>
			},
		},
		{
			id: 'bid_amount',
			name: t('api.bid_amount'),
			left: true,
			sortable: true,
			width: '250px',

			selector: (row: any) => row.machine,
			cell: (row: any) => (
					<p className='text-md text-left'>Euro {row.machine?.bid[0]?.value}</p>
				),
		},
		{
			id: 'amount',
			name: t('api.commission_amount'),
			left: true,
			sortable: true,
			width: '250px',
			selector: (row: any) => row.commission_amount,
			cell: (row: any) => (
				<p className='text-md text-left'> Euro {row.commission_amount}</p>
			),
		},
		{
			id: 'total_amount',
			name: t('api.total_amount'),
			left: true,
			sortable: true,
			omit: true,
			width: '250px',
			cell: (row: any) => {
				const {
					machine: { bid },
				} = row
				const sum =
					row.commission.user_type === 1
						? bid[0].value - row.commission_amount
						: bid[0].value + row.commission_amount
				return <p className='text-md text-left'>Euro {sum}</p>
			},
		},
		{
			id: 'status',
			name: t('api.invoice_status'),
			sortable: true,
			width: '250px',
			selector: (row: any) => row.status,
			cell: (row: any) => (
				<div
					title={t(row.status === 0 ? 'api.invoice_not_sent' : 'api.invoice_sent')}
					className='flex justify-between items-center gap-4 cursor-pointer w-full'
				>
					<Checkbox checked={row.status !== 0} name='is_active' />
				</div>
			),
		},
		{
			id: 'user',
			name: t('api.user_title'),
			left: true,
			width: '250px',
			sortable: true,
			cell: (row: any) => (
				<p className='text-lg'>
					{row.commission.user_type === 1 ? 'Seller' : 'Buyer'}
				</p>
			),
		},
		{
			id: 'machine',
			name: t('api.machine_title'),
			left: true,
			width: '250px',
			sortable: true,
			cell: (row: any) => (
				<p className='text-l	g'>
					{row.commission.machine_type === 2 ? 'Auction' : 'Trade in price'}
				</p>
			),
		},
	]

	if (loading) {
		return <p>Loading.....</p>
	}

	return (
		<div className='w-full h-full px-10 py-5'>
			<AdminSubHeader breadCrumbs={breadCrumb} />
			<div className='flex justify-center items-center w-full rounded-l shadow-sm'>
				<Table
					columns={columns || []}
					data={commissionList}
					pagination={false}
					striped
				/>
			</div>
		</div>
	)
}

export default Commission
