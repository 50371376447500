import { useQuery } from '@apollo/client'
import { Key, useState } from 'react'
import { sumBy, sum } from 'lodash'

import { useTranslation } from 'react-i18next'
import { AiOutlineInfoCircle } from 'react-icons/ai'

import { currencyFormatter } from 'utils/currencyFormatter'
import { CALCULATE_COMMISSION } from '../graphql/query'

const MachineBidWithCommission = (props: any) => {
	const { bidAmount, machineId } = props
	const { t } = useTranslation()
	const [bidTotal, setTotalBid] = useState(bidAmount || 0)
	const [commissionList, setCommissionList] = useState([])

	const { loading } = useQuery(CALCULATE_COMMISSION, {
		variables: {
			machine_id: machineId,
			value: bidAmount,
		},
		onCompleted: (data: any) => {
			setCommissionList(JSON.parse(data.allMachineCalculation || '[]'))
			const sumOfCommissionAmount = sumBy(
				JSON.parse(data.allMachineCalculation || '[]'),
				(commission: { amount: number }) => commission.amount
			)
			const total = sum([
				typeof bidAmount === 'number' ? bidAmount : parseFloat(bidAmount),
				typeof sumOfCommissionAmount === 'number'
					? sumOfCommissionAmount
					: parseFloat(sumOfCommissionAmount),
			])
			setTotalBid(total)
		},
		onError: () => {
			setCommissionList([])
			setTotalBid(bidAmount)
		},
	})

	return (
		<div className='flex flex-col gap-2'>
			<div className='flex flex-col gap-1'>
				<div className='flex flex-1 justify-between items-center py-2 text-gray-500'>
					<div className='flex items-center gap-2'>
						<p className='font-bold'>{t('api.your_bid')}</p>
					</div>
					<p className=' font-bold flex justify-end'>
						{currencyFormatter(
							typeof bidAmount === 'number' ? bidAmount : parseFloat(bidAmount)
						)}
					</p>
				</div>
				{loading && <p>Loading...</p>}
				{(commissionList || []).map(
					(
						commissionItem: {
							commission_detail: string
							amount: number
						},
						index: Key | null | undefined
					) => (
						<div
							className='flex flex-1 justify-between items-center py-2'
							key={index}
						>
							<div className='flex items-center gap-2'>
								<p className='font-bold'>
									{t(`api.${commissionItem?.commission_detail || 'not_exist'}`)}
								</p>
								<div
									title={t(`api.${commissionItem?.commission_detail || 'not_exist'}`)}
									className='cursor-pointer'
								>
									<AiOutlineInfoCircle />
								</div>
							</div>
							<p className=' font-bold flex justify-end'>
								{currencyFormatter(
									typeof commissionItem?.amount === 'number'
										? commissionItem?.amount
										: parseFloat(commissionItem?.amount)
								)}
							</p>
						</div>
					)
				)}
			</div>
			<hr />
			<div className='flex flex-1 justify-between items-center py-4'>
				<p className='font-bold '>{t('api.total')}</p>
				<p className=' font-bold flex justify-end'>
					{currencyFormatter(
						typeof bidAmount === 'number' ? bidTotal : parseFloat(bidTotal)
					)}
				</p>
			</div>
		</div>
	)
}

export default MachineBidWithCommission
