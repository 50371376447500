/* eslint-disable */
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { toast } from 'react-toastify'
import { FormikProps, useFormik } from 'formik'
import * as Yup from 'yup'
import Stepper, {
	IStepBody,
	IStepperStep,
} from '../../../../../../../shared/components/Stepper'
import { ICategory, ICategoryData } from '../../../../Category/graphql/model'
import { IType } from '../../../../Types/graphql/model'
import {
	IAttribute,
	ISelect,
	ISelectsData,
} from '../../../../Attributes/graphql/model'
import {
	FIND_ALL_ATTRIBUTES_EVERY_FIELD,
	FIND_ALL_SELECTS,
} from '../../../../Attributes/graphql/query'
import {
	FIND_ALL_CATEGORY_WITH_TYPES_AND_SUBCATEGORY,
	FIND_SPECIFICATION_SELECTS_BY_ATTRIBUTE_IDS,
} from '../../../../Specification/graphql/query'
import { IOptions } from '../../../../../../../shared/components/Select'
import {
	ISubcategory,
	ISubcategoryData,
} from '../../../../Subcategories/graphql/model'
import MachineDetail from './MachineDetail'
import MachineSpecification from './MachineSpecification'
import { IExtraField, IImage, IMachineData } from '../graphql/model'
import { CREATE_MACHINE, UPDATE_MACHINE } from '../graphql/mutation'
import MachineImageUpload from './MachineImageUpload'
import { FIND_MACHINE_BY_ID } from '../graphql/query'
import { FIND_SUBCATEGORY_BY_ID } from '../../../../Subcategories/graphql/query'
import Loader from '../../../../../../../shared/components/loader'
import { sortBy } from 'lodash'
import { FIND_ALL_TRANSLATIONS } from '../../../../Translations/graphql/query'

enum CREATE_MACHINE_STEPS {
	MACHINE_DETAIL = 1,
	MACHINE_SPECIFICATION,
	UPLOAD_MEDIA,
	MACHINE_OVERVIEW,
}

export interface ISelectOption {
	key: string
	values: IOptions[] | any
}

export interface ISpecSelectFormatted {
	id: number
	attribute_id: number
	name: string
}

const CreateMachine = () => {
	const [stepperIndex, setStepperIndex] = useState<number>(
		CREATE_MACHINE_STEPS.MACHINE_DETAIL
	)

	const { t } = useTranslation()
	const { machineId } = useParams()
	const location = useLocation()
	const isMachineEdit = location.pathname.includes('edit')

	const [id, setId] = useState<string>('')

	const [selectOptions, setSelectOptions] = useState<ISelectOption[]>([])
	const [categories, setCategories] = useState<ICategory[]>([])
	const [fields, setFields] = useState<any[]>([])
	const [fieldsLoading, setFieldsLoading] = useState<boolean>(false)
	const [category, setCategory] = useState<ICategory>()
	const [type, setType] = useState<IType>()
	const [selectSpec, setSelectSpec] = useState<ISpecSelectFormatted[]>()
	const [specAttribIds, setSpecAttribIds] = useState<string[]>()
	const [images, setImages] = useState<IImage[]>([])
	const validationSchemaFirstForm = Yup.object().shape({
		category_id: Yup.string().required('message.is_required'),
		type_id: Yup.string().required('message.is_required'),
		subcategory_id: Yup.string().required('message.is_required'),
	})
	const [validationSchemaSecondForm, setValidationSchemaSecondForm] =
		useState<any>({})
	const [validationSchemaList, setValidationSchemaList] = useState<any[]>([
		validationSchemaFirstForm,
		// Yup.object().shape(validationSchemaSecondForm),
	])
	useEffect(() => {
		const formSchema = Yup.object().shape(validationSchemaSecondForm)
		setValidationSchemaList([validationSchemaFirstForm, formSchema])
	}, [validationSchemaSecondForm])

	const { refetch: refetchCategories } = useQuery<ICategoryData>(
		FIND_ALL_CATEGORY_WITH_TYPES_AND_SUBCATEGORY,
		{
			onCompleted: data => {
				if (data.findAllCategories) {
					setCategories(data.findAllCategories)
					const tempCategory: IOptions[] = data.findAllCategories?.map(
						(category: ICategory) => ({
							label: category.name,
							value: category.id,
						})
					)
					setSelectOptions([
						...selectOptions,
						{
							key: 'CATEGORY',
							values: tempCategory,
						},
					])
				}
			},
		}
	)

	const { data: attributes, refetch: refetchALlAttributes } = useQuery<any>(
		FIND_ALL_ATTRIBUTES_EVERY_FIELD
	)
	const {
		data: selects,
		loading: selectLoading,
		refetch: refetchALlSelect,
	} = useQuery<ISelectsData>(FIND_ALL_SELECTS)

	const [getSelectSpecification] = useLazyQuery<ISelectsData>(
		FIND_SPECIFICATION_SELECTS_BY_ATTRIBUTE_IDS,
		{
			onCompleted(data) {
				setSelectSpec(
					data.findSpecificationSelectsByAttributeIds.map(x => ({
						attribute_id: x.attribute.id,
						id: x.select.id,
						name: x.select.name,
					}))
				)
			},
		}
	)

	const [addMachine, { loading: addMachineLoading }] = useMutation(
		CREATE_MACHINE,
		{
			onCompleted(data) {
				setId(data.createMachine.id)
				toast.success(`${t('message.machine_saved_as_concept')}`)
			},
			onError(error) {
				toast.error(error.message || `${t('message.machine_add_fail')}`)
			},
		}
	)

	const [updateMachine, { loading: updateMachineLoading }] = useMutation(
		UPDATE_MACHINE,
		{
			onCompleted(data) {
				setId(data.updateMachine.id)
				toast.success(`${t('message.machine_add_success')}`)
			},
			onError(error) {
				toast.error(error.message || `${t('message.machine_add_fail')}`)
			},
		}
	)

	const get_dependent_attribute_data = (attribute: any) => {
		let dep_values = []
		const dep_attribute =
			attribute && attribute.dependent_to
				? attributes.findAllAttributes.find(
						(x: any) => parseInt(x.id, 10) === attribute.dependent_to
				  )
				: null

		// if (dep_attribute) {
		// 	switch (dep_attribute.type) {
		// 		case 1:
		// 			dep_values = [attribute.dependent_value]
		// 			break
		// 		default:
		// 			const dependent_values = attribute.dependent_value
		// 				.split(',')
		// 				.map((x: any) => Number(x))
		// 		// for (const dependent_value of dependent_values) {
		// 		// 	if (
		// 		// 		this.fieldsDetails[this.section][dep_attribute.attribute_name] &&
		// 		// 		this.fieldsDetails[this.section][dep_attribute.attribute_name].length > 0
		// 		// 	) {
		// 		// 		const dn = this.fieldsDetails[this.section][
		// 		// 			dep_attribute.attribute_name
		// 		// 		].find(x => x.id === dependent_value)
		// 		// 		dep_values.push(
		// 		// 			attribute.dependent_value &&
		// 		// 				(this.fieldsDetails[this.section][dep_attribute.attribute_name] ||
		// 		// 					dn.id == 1)
		// 		// 				? dn
		// 		// 					? dn.name
		// 		// 					: null
		// 		// 				: null
		// 		// 		)
		// 		// 	}
		// 		// }
		// 	}
		// }

		return {
			dep_attribute_name: dep_attribute ? dep_attribute.attribute_name : null,
			// dep_values,
		}
	}

	const specsLoad = (attributeIds: any, subcategory_id = null) => {
		setSpecAttribIds(attributeIds)

		getSelectSpecification({
			variables: {
				ids: attributeIds,
				category_id: formik.values.category_id,
				type_id: formik.values.type_id,
				subcategory_id: formik.values.subcategory_id || subcategory_id,
			},
		})
	}

	const formatFields = (extraFieldAttributes: any, subcategory_id = null) => {
		if (extraFieldAttributes) {
			setFieldsLoading(true)
			const tFields = []
			let tattributes
			tattributes = extraFieldAttributes.concat([
				{
					type: 8,
					attribute_name: 'location',
					mandatory: true,
					translatable: true,
				},
			])

			// eslint-disable-next-line no-restricted-syntax
			for (const attribute of tattributes) {
				const dependent_attribute = get_dependent_attribute_data(attribute)

				specsLoad(
					tattributes
						.filter((attrib: any) => {
							if (attrib && attrib.specification_type) {
								return (
									attrib.specification_type === 1 ||
									attrib.specification_type === 2 ||
									attrib.specification_type === 3 ||
									attrib.specification_type === 4
								)
							} else {
								return false
							}
						})
						.map((attrib: any) => attrib.id),
					subcategory_id
				)

				if (attribute && attribute.mandatory) {
					const tempValidation = validationSchemaSecondForm
					tempValidation[attribute.attribute_name] = Yup.string().required(
						`message.${attribute.attribute_name}_required`
					)
					setValidationSchemaSecondForm(tempValidation)
				}
				switch (attribute.type) {
					case 1: // checkbox - ATTRIBUTE_TYPES[1]
						tFields[attribute.attribute_name] = {
							formattedName: attribute.translatable
								? attribute.attribute_name.toLowerCase().replaceAll(' ', '_')
								: attribute.attribute_name,
							attributeName: attribute.attribute_name
								.toLowerCase()
								.replaceAll(' ', '_'),
							translatable: attribute.translatable,
							translatable_value: attribute.translatable_value,
							colspan: false,
							type: 'boolean',
							specification_type: attribute.specification_type,
							mandatory: attribute.mandatory,
							dependent_to: dependent_attribute.dep_attribute_name,
							// dependent_values: dependent_attribute.dep_values,
							editable: true,
							attributeId: attribute.id,
						}
						break
					case 2: // select_with_other - ATTRIBUTE_TYPES[2]
						tFields[attribute.attribute_name] = {
							formattedName: attribute.translatable
								? attribute.attribute_name.toLowerCase().replaceAll(' ', '_')
								: attribute.attribute_name,
							attributeName: attribute.attribute_name
								.toLowerCase()
								.replaceAll(' ', '_'),
							translatable: attribute.translatable,
							translatable_value: attribute.translatable_value,
							colspan: false,
							type: 'select_direct',
							option_attr: attribute.translatable == 1 ? 'translated' : 'name',
							specification_type: attribute.specification_type,
							mandatory: attribute.mandatory,
							show_type: attribute.show_type,
							dependent_to: dependent_attribute.dep_attribute_name,
							// dependent_values: dependent_attribute.dep_values,
							editable: true,
							attributeId: attribute.id,
						}
						break
					case 3: // select - ATTRIBUTE_TYPES[3]
						tFields[attribute.attribute_name] = {
							formattedName: attribute.translatable
								? attribute.attribute_name.toLowerCase().replaceAll(' ', '_')
								: attribute.attribute_name,
							attributeName: attribute.attribute_name
								.toLowerCase()
								.replaceAll(' ', '_'),
							translatable: attribute.translatable,
							translatable_value: attribute.translatable_value,
							colspan: false,
							type: 'select',
							option_attr: attribute.translatable == 1 ? 'translated' : 'name',
							with_other: true,
							specification_type: attribute.specification_type,
							mandatory: attribute.mandatory,
							show_type: attribute.show_type,
							dependent_to: dependent_attribute.dep_attribute_name,
							// dependent_values: dependent_attribute.dep_values,
							editable: true,
							attributeId: attribute.id,
						}
						break
					case 4: // dropdown_year - ATTRIBUTE_TYPES[4]
						tFields[attribute.attribute_name] = {
							formattedName: attribute.translatable
								? attribute.attribute_name.toLowerCase().replaceAll(' ', '_')
								: attribute.attribute_name,
							attributeName: attribute.attribute_name
								.toLowerCase()
								.replaceAll(' ', '_'),
							translatable: attribute.translatable,
							translatable_value: attribute.translatable_value,
							colspan: false,
							type: 'dropdown_year',
							option_attr: 'name',
							specification_type: attribute.specification_type,
							mandatory: attribute.mandatory,
							dependent_to: dependent_attribute.dep_attribute_name,
							// dependent_values: dependent_attribute.dep_values,
							editable: true,
							attributeId: attribute.id,
						}
						break
					case 5: // number - ATTRIBUTE_TYPES[5]
						tFields[attribute.attribute_name] = {
							formattedName: attribute.translatable
								? attribute.attribute_name.toLowerCase().replaceAll(' ', '_')
								: attribute.attribute_name,
							attributeName: attribute.attribute_name
								.toLowerCase()
								.replaceAll(' ', '_'),
							translatable: attribute.translatable,
							translatable_value: attribute.translatable_value,
							colspan: false,
							type: 'input_number',
							specification_type: attribute.specification_type,
							mandatory: attribute.mandatory,
							dependent_to: dependent_attribute.dep_attribute_name,
							// dependent_values: dependent_attribute.dep_values,
							editable: true,
							isNumber: attribute.type === 5,
							attributeId: attribute.id,
						}
						break
					case 6: // input - ATTRIBUTE_TYPES[6]
						tFields[attribute.attribute_name] = {
							formattedName: attribute.translatable
								? attribute.attribute_name.toLowerCase().replaceAll(' ', '_')
								: attribute.attribute_name,
							attributeName: attribute.attribute_name
								.toLowerCase()
								.replaceAll(' ', '_'),
							translatable: attribute.translatable,
							translatable_value: attribute.translatable_value,
							colspan: false,
							type: 'input',
							specification_type: attribute.specification_type,
							mandatory: attribute.mandatory,
							dependent_to: dependent_attribute.dep_attribute_name,
							// dependent_values: dependent_attribute.dep_values,
							editable: true,
							isNumber: attribute.type === 5,
							attributeId: attribute.id,
						}
						break
					case 7: // rating - ATTRIBUTE_TYPES[7]
						tFields[attribute.attribute_name] = {
							formattedName: attribute.translatable
								? attribute.attribute_name.toLowerCase().replaceAll(' ', '_')
								: attribute.attribute_name,
							attributeName: attribute.attribute_name
								.toLowerCase()
								.replaceAll(' ', '_'),
							translatable: attribute.translatable,
							translatable_value: attribute.translatable_value,
							colspan: false,
							type: 'rating',
							specification_type: attribute.specification_type,
							mandatory: attribute.mandatory,
							dependent_to: dependent_attribute.dep_attribute_name,
							// dependent_values: dependent_attribute.dep_values,
							editable: true,
							attributeId: attribute.id,
						}
						break
					case 8: // textarea - ATTRIBUTE_TYPES[7]
						tFields[attribute.attribute_name] = {
							formattedName: attribute.translatable
								? attribute.attribute_name.toLowerCase().replaceAll(' ', '_')
								: attribute.attribute_name,
							attributeName: attribute.attribute_name
								.toLowerCase()
								.replaceAll(' ', '_'),
							translatable: attribute.translatable,
							translatable_value: attribute.translatable_value,
							colspan: true,
							type: 'textarea',
							specification_type: attribute.specification_type,
							mandatory: attribute.mandatory,
							dependent_to: dependent_attribute.dep_attribute_name,
							// dependent_values: dependent_attribute.dep_values,
							editable: true,
							attributeId: attribute.id,
						}
						break
					case 9: // select_date - ATTRIBUTE_TYPES[9]
						tFields[attribute.attribute_name] = {
							formattedName: attribute.translatable
								? attribute.attribute_name.toLowerCase().replaceAll(' ', '_')
								: attribute.attribute_name,
							attributeName: attribute.attribute_name
								.toLowerCase()
								.replaceAll(' ', '_'),
							translatable: attribute.translatable,
							translatable_value: attribute.translatable_value,
							colspan: false,
							type: 'select_date',
							option_attr: 'name',
							specification_type: attribute.specification_type,
							mandatory: attribute.mandatory,
							dependent_to: dependent_attribute.dep_attribute_name,
							// dependent_values: dependent_attribute.dep_values,
							editable: true,
							attributeId: attribute.id,
						}
						break
					case 10: // select_date - ATTRIBUTE_TYPES[9]
						tFields[attribute.attribute_name] = {
							formattedName: attribute.translatable
								? attribute.attribute_name.toLowerCase().replaceAll(' ', '_')
								: attribute.attribute_name,
							attributeName: attribute.attribute_name
								.toLowerCase()
								.replaceAll(' ', '_'),
							translatable: attribute.translatable,
							translatable_value: attribute.translatable_value,
							colspan: false,
							type: 'tag',
							option_attr: 'name',
							specification_type: attribute.specification_type,
							mandatory: attribute.mandatory,
							dependent_to: dependent_attribute.dep_attribute_name,
							// dependent_values: dependent_attribute.dep_values,
							editable: true,
							attributeId: attribute.id,
						}
						break
					default:
						toast.error('message.something_went_wrong')
				}
				!machineId && formik.setFieldValue(attribute.attribute_name, '')
			}

			setFields(tFields)
			setFieldsLoading(false)
		}
	}

	const formik: FormikProps<any> = useFormik<any>({
		validateOnChange: false,
		initialValues: {
			id: null,
			category_id: '',
			type_id: '',
			subcategory_id: '',
			location: null,
			model_id: null,
			model: null,
			model_other: null,
			brand_id: null,
			brand: null,
			brand_other: null,
			machine_type_id: null,
			machine_type_other: null,
			machine_type: null,
			licence_plate_id: null,
			licence_plate: null,
			licence_plate_other: null,
			running_hours: null,
			year_of_manufacture_id: null,
			year_of_manufacture: null,
			year_of_manufacture_other: null,
		},
		validationSchema: validationSchemaList[stepperIndex - 1],
		onSubmit: async (values, { setSubmitting }) => {
			if (stepperIndex === CREATE_MACHINE_STEPS.MACHINE_SPECIFICATION) {
				const extraInput: IExtraField[] = []
				Object.keys(fields).forEach((field: any) => {
					if (
						fields[field].formattedName.toLowerCase().replaceAll(' ', '_') !==
							'location' &&
						fields[field].formattedName.toLowerCase().replaceAll(' ', '_') !==
							'brand' &&
						fields[field].formattedName.toLowerCase().replaceAll(' ', '_') !==
							'model' &&
						fields[field].formattedName.toLowerCase().replaceAll(' ', '_') !==
							'licence_plate' &&
						fields[field].formattedName.toLowerCase().replaceAll(' ', '_') !==
							'running_hours' &&
						fields[field].formattedName.toLowerCase().replaceAll(' ', '_') !==
							'machine_type' &&
						fields[field].formattedName.toLowerCase().replaceAll(' ', '_') !==
							'year_of_manufacture'
					) {
						let tempExtraField: IExtraField | null
						if (
							fields[field].type.toLowerCase() === 'select' ||
							fields[field].type.toLowerCase() === 'select_with_specification' ||
							fields[field].type.toLowerCase() === 'select_direct' ||
							fields[field].type.toLowerCase() === 'select_with_specification_direct'
						) {
							tempExtraField = {
								input_type: fields[field].type.toLowerCase(),
								attribute_id: fields[field].attributeId.toLowerCase(),
								value_id: formik.values[fields[field].attributeName],
								attribute:
									fields[field].formattedName.split('.')[
										fields[field].formattedName.split('.').length - 1
									],
								translatable: fields[field].translatable,
								translatable_value: fields[field].translatable_value,
								value:
									formik.values[fields[field].attributeName] === 'other'
										? formik.values[`${fields[field].attributeName}_other`]
										: formik.values[fields[field].attributeName] &&
										  getOptions(
												fields[field].attributeName,
												fields[field].attributeId,
												fields[field].type
										  )!.find(
												option =>
													option.value.toString() ===
													String(formik.values[fields[field].attributeName])
										  )!.label,
							}
							formik.values[fields[field].attributeName] &&
								extraInput.push(tempExtraField)
						} else if (fields[field].type === 'tag') {
							const tempOptionsIndex = getOptions(
								fields[field].attributeName,
								fields[field].attributeId,
								fields[field].type
							)!.filter(option =>
								formik.values[fields[field].formattedName]?.includes(
									parseInt(String(option.value), 10)
								)
							)
							const tagValues: string[] = tempOptionsIndex.map(option => option.label)
							tempExtraField = {
								input_type: fields[field].type,
								attribute_id: fields[field].attributeId,
								value_id: null,
								tag_ids: formik.values[fields[field].attributeName],
								attribute:
									fields[field].formattedName.split('.')[
										fields[field].formattedName.split('.').length - 1
									],
								translatable: fields[field].translatable,
								translatable_value: fields[field].translatable_value,
								value: tagValues.join(','),
							}
							formik.values[fields[field].attributeName] &&
								extraInput.push(tempExtraField)
						} else {
							tempExtraField = {
								input_type: fields[field].type,
								attribute_id: fields[field].attributeId,
								value_id: null,
								translatable: fields[field].translatable,
								translatable_value: fields[field].translatable_value,
								attribute:
									fields[field].formattedName.split('.')[
										fields[field].formattedName.split('.').length - 1
									],
								value:
									formik.values[fields[field].attributeName] &&
									String(formik.values[fields[field].attributeName]),
							}
							formik.values[fields[field].attributeName] &&
								extraInput.push(tempExtraField)
						}
					}
				})
				if (machineId) {
					await updateMachine({
						variables: {
							id: parseInt(machineId, 10),
							categoryId: formik.values.category_id,
							typeId: formik.values.type_id,
							subcategoryId: formik.values.subcategory_id,
							location: formik.values.location,
							brand_id:
								formik.values.brand_id === 'other' ? 0 : formik.values.brand_id,
							brand:
								formik.values.brand_id === 'other'
									? formik.values.brand_other
									: formik.values.brand,
							model_id:
								formik.values.model_id === 'other' ? 0 : formik.values.model_id,
							model:
								formik.values.model_id === 'other'
									? formik.values.model_other
									: formik.values.model,
							licencePlate:
								formik.values.licence_plate_id === 'other'
									? formik.values.licence_plate_other
									: formik.values.licence_plate,
							licencePlateId:
								formik.values.licence_plate_id === 'other'
									? 0
									: formik.values.licence_plate_id,
							runningHours: parseFloat(formik.values.running_hours),
							yearOfManufacture:
								formik.values.year_of_manufacture_id === 'other'
									? formik.values.year_of_manufacture_other
									: formik.values.year_of_manufacture,
							yearOfManufactureId:
								formik.values.year_of_manufacture_id === 'other'
									? 0
									: formik.values.year_of_manufacture_id,
							machine_type_id:
								formik.values.machine_type_id === 'other'
									? 0
									: formik.values.machine_type_id,
							machine_type:
								formik.values.machine_type_id === 'other'
									? formik.values.machine_type_other
									: formik.values.machine_type,
							extraInput: extraInput,
						},
					})
				} else {
					await addMachine({
						variables: {
							categoryId: formik.values.category_id,
							typeId: formik.values.type_id,
							subcategoryId: formik.values.subcategory_id,
							location: formik.values.location,
							brand_id:
								formik.values.brand_id === 'other' ? 0 : formik.values.brand_id,
							brand:
								formik.values.brand_id === 'other'
									? formik.values.brand_other
									: formik.values.brand,
							model_id:
								formik.values.model_id === 'other' ? 0 : formik.values.model_id,
							model:
								formik.values.model_id === 'other'
									? formik.values.model_other
									: formik.values.model,
							licencePlate:
								formik.values.licence_plate_id === 'other'
									? formik.values.licence_plate_other
									: formik.values.licence_plate,
							licencePlateId:
								formik.values.licence_plate_id === 'other'
									? 0
									: formik.values.licence_plate_id,
							runningHours: parseFloat(formik.values.running_hours),
							yearOfManufacture:
								formik.values.year_of_manufacture_id === 'other'
									? formik.values.year_of_manufacture_other
									: formik.values.year_of_manufacture,
							yearOfManufactureId:
								formik.values.year_of_manufacture_id === 'other'
									? 0
									: formik.values.year_of_manufacture_id,
							machine_type_id:
								formik.values.machine_type_id === 'other'
									? 0
									: formik.values.machine_type_id,
							machine_type:
								formik.values.machine_type_id === 'other'
									? formik.values.machine_type_other
									: formik.values.machine_type,
							extraInput: extraInput,
						},
					})
				}
			}
			if (stepperIndex !== CREATE_MACHINE_STEPS.MACHINE_OVERVIEW) {
				setStepperIndex(stepperIndex + 1)
				setValidationSchemaList([
					validationSchemaFirstForm,
					Yup.object().shape(validationSchemaSecondForm),
				])
			}
			setSubmitting(false)
		},
	})

	const { data: subCategory, refetch: refetchSubCategory } =
		useQuery<ISubcategoryData>(FIND_SUBCATEGORY_BY_ID, {
			skip: !(formik.values.subcategory_id && machineId),
			variables: {
				id: parseInt(formik.values.subcategory_id, 10),
			},
			onCompleted: data => {
				const subCategory = data.findSubcategoryById
					? data.findSubcategoryById[0]
					: null
				const extraAttrib =
					subCategory &&
					subCategory.extra_field &&
					subCategory.extra_field.map((subCategory: any) =>
						attributes?.findAllAttributes.find(
							(attributes: IAttribute) =>
								attributes.attribute_name.toLowerCase().replaceAll('_', ' ') ===
								subCategory.field_name.toLowerCase().replaceAll('_', ' ')
						)
					)
				extraAttrib && formatFields(extraAttrib.filter((ea: any) => ea))
			},
		})

	useEffect(() => {
		if (machineId && subCategory) {
			fields && setStepperIndex(CREATE_MACHINE_STEPS.MACHINE_SPECIFICATION)
		}
	}, [machineId, fields, subCategory])

	const getOptions = (
		field: string,
		attributeId: string | null = null,
		fieldType: string = ''
	): IOptions[] => {
		if (attributeId && selects && selectSpec) {
			let returnVal = null

			if (specAttribIds?.includes(attributeId)) {
				returnVal = selectSpec
					// @ts-ignore
					.filter(sel => sel.attribute_id === attributeId)
					.map((selOpt: any) => ({
						label: selOpt.name,
						value: selOpt.id,
					}))
			}

			if (returnVal && returnVal.length > 0) {
				return returnVal
			}

			return (
				sortBy(selects.findAllSelects, ['order'])
					// @ts-ignore
					.filter(sel => sel.attribute_id === attributeId)
					.map((selOpt: ISelect) => ({
						label: selOpt.name,
						value: selOpt.id,
					}))
			)
		}
		const temp = selectOptions.find(
			sel => sel.key.toLowerCase() === field.toLowerCase()
		)
		if (temp) {
			return temp.values
		}
		return []
	}

	const onChangeHandler = async (
		value: string | number | null,
		field: string
	) => {
		if (value) {
			formik.setFieldValue(`${field}_id`, value)

			switch (field.toLowerCase()) {
				case 'category':
					// eslint-disable-next-line no-case-declarations
					const selectedCategory = categories.find(
						sel =>
							// @ts-ignore
							sel.id === value
					)

					if (category !== selectedCategory) {
						setCategory(selectedCategory)
						formik.setFieldValue('type_id', null)
						formik.setFieldValue('subcategory_id', null)
					}

					// eslint-disable-next-line no-case-declarations
					const types = selectedCategory?.type

					if (types) {
						const filteredOption = selectOptions.filter(
							option => option.key !== 'TYPE'
						)
						await setSelectOptions([
							...filteredOption,
							{
								key: 'TYPE',
								values: types.map((type: IType) => ({
									label: type.name,
									value: type.id,
								})),
							},
						])
					}
					break
				case 'type':
					if (category) {
						// @ts-ignore
						const selectedType = category.type.find(sel => sel.id === value)

						if (type !== selectedType) {
							setType(selectedType)
							formik.setFieldValue('subcategory_id', null)
						}
						const subcategories = selectedType?.subcategory
						if (subcategories) {
							const filteredOption = selectOptions.filter(
								option => option.key !== 'SUBCATEGORY'
							)
							await setSelectOptions([
								...filteredOption,
								{
									key: 'SUBCATEGORY',
									values: subcategories.map((type: ISubcategory) => ({
										label: type.name,
										value: type.id,
									})),
								},
							])
						}
					}
					break
				case 'subcategory':
					if (type) {
						const tsubcategory = type?.subcategory.find(sel => sel.id === value)

						const extraAttrib =
							tsubcategory &&
							tsubcategory.extra_field &&
							tsubcategory.extra_field.map((subCategory: any) =>
								attributes?.findAllAttributes.find(
									(attributes: IAttribute) =>
										attributes.attribute_name.toLowerCase().replaceAll('_', ' ') ===
										subCategory.field_name.toLowerCase().replaceAll('_', ' ')
								)
							)
						formatFields(
							extraAttrib.filter((ea: any) => ea),
							tsubcategory.id
						)
					}

					break
				default:
					break
			}
		} else {
			formik.setFieldValue(`${field}_id`, value)

			switch (field.toLowerCase()) {
				case 'category':
					const types: IType[] = []
					const filteredOption = selectOptions.filter(
						option => option.key !== 'TYPE'
					)
					await setSelectOptions([
						...filteredOption,
						{
							key: 'TYPE',
							values: types.map((type: IType) => ({
								label: type.name,
								value: type.id,
							})),
						},
					])
					break
				case 'type':
					const subcategories: IType[] = []
					const filteredWithoutSubCategories = selectOptions.filter(
						option => option.key !== 'SUBCATEGORY'
					)
					await setSelectOptions([
						...filteredWithoutSubCategories,
						{
							key: 'TYPE',
							values: subcategories.map((type: IType) => ({
								label: type.name,
								value: type.id,
							})),
						},
					])
					break
				default:
					break
			}
		}
	}

	const { loading: machineLoading, refetch } = useQuery<any>(
		FIND_MACHINE_BY_ID,
		{
			skip: !machineId,
			variables: {
				id: parseInt(String(machineId), 10),
			},
			onCompleted: data => {
				refetchCategories()
				refetchALlAttributes()
				refetchALlSelect()
				const machineData = JSON.parse(data.findMachineById)
				if (machineData) {
					if (machineData.images) {
						setImages(machineData.images)
					}
					formik.setFieldValue('id', machineData.id)
					formik.setFieldValue('category_id', machineData.category.id)
					formik.setFieldValue('type_id', machineData.type.id)
					formik.setFieldValue('subcategory_id', machineData.subcategory.id)
					formik.setFieldValue('location', machineData.location)
					formik.setFieldValue('model_id', machineData.model_id)
					formik.setFieldValue('model', machineData.model)
					formik.setFieldValue('brand_id', machineData.brand_id)
					formik.setFieldValue('brand', machineData.brand)
					formik.setFieldValue(
						'machine_type_id',
						machineData.machine_type_id
					)
					formik.setFieldValue('machine_type', machineData.machine_type)
					formik.setFieldValue('licence_plate', machineData.licence_plate)
					formik.setFieldValue(
						'licence_plate_id',
						machineData.licence_plate_id
					)
					formik.setFieldValue('running_hours', machineData.running_hours)
					formik.setFieldValue(
						'year_of_manufacture',
						machineData.year_of_manufacture
					)
					formik.setFieldValue(
						'year_of_manufacture_id',
						machineData.year_of_manufacture_id
					)

					const extraFields = machineData.extra_data
						? JSON.parse(machineData.extra_data.extra_data)
						: []

					extraFields.forEach((fields: any) => {
						switch (fields.input_type) {
							case 'input':
							case 'input_number':
							case 'input_specification':
							case 'textarea':
							case 'boolean':
							case 'rating':
							case 'select_date':
							case 'dropdown_year':
								fields.attribute_name &&
									formik.setFieldValue(
										fields.attribute_name.toLowerCase().replaceAll(' ', '_'),
										fields.value
									)
								break
							case 'select':
							case 'select_from_specification':
								fields.attribute_name &&
									formik.setFieldValue(
										fields.attribute_name.toLowerCase().replaceAll(' ', '_'),
										fields.value_id
									)
								fields.value_id === 'other' &&
									formik.setFieldValue(
										`${fields.attribute_name.toLowerCase().replaceAll(' ', '_')}_other`,
										fields.value
									)
								break
							case 'select_direct':
							case 'select_direct_from_specification':
								fields.attribute_name &&
									formik.setFieldValue(
										fields.attribute_name.toLowerCase().replaceAll(' ', '_'),
										fields.value_id
									)
								break
							case 'tag':
								fields.attribute_name &&
									formik.setFieldValue(
										fields.attribute_name.toLowerCase().replaceAll(' ', '_'),
										fields.tag_ids
									)
								break
							default:
								toast.error(t('message.something_went_wrong'))
						}
					})
				}
			},
		}
	)

	const onToggleChangeHandler = (event: any, fieldName: string, field: any) => {
		formik.setFieldValue(fieldName, !formik.values[fieldName])
	}

	const ratingAndDateChangeHandler = (
		value: any,
		fieldName: string,
		field: any
	) => {
		if (field.formattedName === 'fields.year_of_manufacture') {
			formik.setFieldValue('year_of_manufacture', value)
			formik.setFieldValue('year_of_manufacture_id', value)
		} else {
			formik.setFieldValue(fieldName, value)
		}
	}

	const onValueChangeHandler = (
		value: number | string | null,
		field: any,
		fieldName: any
	) => {
		if (field.formattedName === 'location') {
			formik.setFieldValue('location', value)
			return
		} else if (
			field.formattedName.toLowerCase().replaceAll(' ', '_') === 'brand' &&
			fieldName !== 'brand_other'
		) {
			if (field.type === 'select_direct' || field.type === 'select') {
				const brandValue = getOptions(
					field.fieldName,
					fields[fieldName].attributeId,
					fields[fieldName].type
				).find(brand => brand.value.toString() === value)
				brandValue && formik.setFieldValue('brand', brandValue.label)
				formik.setFieldValue('brand_id', value)
			}
			if (field.type === 'input') {
				formik.setFieldValue('brand', value)
			}
			return
		} else if (
			field.formattedName.toLowerCase().replaceAll(' ', '_') === 'model' &&
			fieldName !== 'model_other'
		) {
			if (field.type === 'select_direct' || field.type === 'select') {
				const modelValue = getOptions(
					field.fieldName,
					fields[fieldName].attributeId,
					fields[fieldName].type
				).find(model => model.value.toString() === value)
				modelValue && formik.setFieldValue('model', modelValue.label)
				formik.setFieldValue('model_id', value)
			}
			if (field.type === 'input') {
				formik.setFieldValue('model', value)
			}
			if (field.type === 'select') {
				formik.setFieldValue('model', value)
			}
		} else if (
			field.formattedName.toLowerCase().replaceAll(' ', '_') === 'licence_plate' &&
			fieldName.toLowerCase().replaceAll(' ', '_') !== 'licence_plate_other'
		) {
			if (field.type === 'select_direct' || field.type === 'select') {
				const licencePlateValue = getOptions(
					field.fieldName,
					fields[fieldName].attributeId,
					fields[fieldName].type
				).find(model => {
					// @ts-ignore
					return model.value.toString() === value
				})
				licencePlateValue &&
					formik.setFieldValue('licence_plate', licencePlateValue.label)
				formik.setFieldValue('licence_plate_id', value)
			}
			if (field.type === 'input') {
				formik.setFieldValue('licence_plate', value)
			}
		} else if (field.formattedName.toLowerCase() === 'running_hours') {
			formik.setFieldValue('running_hours', value)
		} else if (
			field.formattedName.toLowerCase() === 'machine_type' &&
			fieldName.toLowerCase().replaceAll(' ', '_') !== 'machine_type_other'
		) {
			if (field.type === 'select') {
				const machineTypeValue = getOptions(
					field.fieldName,
					fields[fieldName].attributeId,
					fields[fieldName].type
				).find(machineType => {
					// @ts-ignore
					return machineType.value.toString() === machineType
				})
				machineTypeValue &&
					formik.setFieldValue('machine_type', machineTypeValue.label)
				formik.setFieldValue('machine_type_id', value)
			}
			if (field.type === 'input') {
				formik.setFieldValue('machine_type', value)
			}
			if (field.type === 'select') {
				formik.setFieldValue('machine_type', value)
			}
		} else {
			formik.setFieldValue(fieldName, value)
		}
	}

	const steps: IStepperStep[] = [
		{
			id: CREATE_MACHINE_STEPS.MACHINE_DETAIL,
			label: t(`api.machine_specification`),
			description: t(`api.machine_detail_description`),
		},
		{
			id: CREATE_MACHINE_STEPS.MACHINE_SPECIFICATION,
			label: t(`api.machine_specification`),
			description: t(`api.machine_specification_description`),
		},
		{
			id: CREATE_MACHINE_STEPS.UPLOAD_MEDIA,
			label: t(`api.upload_media`),
			description: t(`api.upload_media_description`),
		},
	]

	const StepBodies: IStepBody[] = [
		{
			id: CREATE_MACHINE_STEPS.MACHINE_DETAIL,
			step: (
				<MachineDetail
					formik={formik}
					getOptions={getOptions}
					selectOptions={selectOptions}
					onChangeHandler={onChangeHandler}
				/>
			),
		},
		{
			id: CREATE_MACHINE_STEPS.MACHINE_SPECIFICATION,
			step: (
				<MachineSpecification
					isEdit={Boolean(machineId)}
					machineLoading={addMachineLoading || updateMachineLoading}
					formik={formik}
					getOptions={getOptions}
					fields={fields}
					onToggleChangeHandler={onToggleChangeHandler}
					onValueChangeHandler={onValueChangeHandler}
					ratingAndDateChangeHandler={ratingAndDateChangeHandler}
					stepperIndex={stepperIndex}
					setStepperIndex={setStepperIndex}
				/>
			),
		},
		{
			id: CREATE_MACHINE_STEPS.UPLOAD_MEDIA,
			step: (
				<MachineImageUpload
					id={id}
					images={images}
					refetch={refetch}
					loading={machineLoading}
				/>
			),
		},
	]

	return (
		<div className='h-full w-full flex flex-col'>
			{(machineLoading || selectLoading || fieldsLoading) && <Loader />}
			{!machineLoading && !selectLoading && !fieldsLoading && (
				<Stepper Steps={steps} activeStep={stepperIndex} stepBodies={StepBodies} />
			)}
		</div>
	)
}

export default CreateMachine
