import { useTranslation } from 'react-i18next'
import STATUS from '../../../enums/STATUS'

interface IStatusFilterProps {
	selectedStatus: STATUS | null
	setSelectedStatus: (selectedStatus: STATUS | null) => void
}

const StatusFilter = (props: IStatusFilterProps) => {
	const { t } = useTranslation()

	const { selectedStatus, setSelectedStatus } = props

	return (
		<div className='flex gap-4 w-full md:w-fit md:pr-2 overflow-auto'>
			<button type='button' className={`btn btn-sm btn-primary ${selectedStatus !== null ? 'btn-outline' : ''}`}
											onClick={() => setSelectedStatus(null)}>{t('api.all')}</button>
			<button type='button' className={`btn btn-sm btn-primary ${selectedStatus !== STATUS.CONCEPT ? 'btn-outline' : ''}`}
											onClick={() => setSelectedStatus(STATUS.CONCEPT)}>{t('api.concept')}</button>
			<button type='button' className={`btn btn-sm btn-primary ${selectedStatus !== STATUS.AUCTION ? 'btn-outline' : ''}`}
											onClick={() => setSelectedStatus(STATUS.AUCTION)}>{t('api.auction')}</button>
			<button type='button' className={`btn btn-sm btn-primary ${selectedStatus !== STATUS.TAXATION ? 'btn-outline' : ''}`}
											onClick={() => setSelectedStatus(STATUS.TAXATION)}>{t('api.taxation')}</button>
		</div>
	)
}

export default StatusFilter
