import { MdCheck } from 'react-icons/md'
import { FC } from 'react'

interface ITagProps {
	label: string,
	value: string | number
	onClick?: (event: any) => void
	checked: boolean
	buttonStyle?: string
}

const Tag: FC<ITagProps> = (props) => {

	const {value, label, checked, onClick, buttonStyle}  = props

	const defaultButtonStyle =
		'outline outline-[1px] flex gap-2 items-center outline-gray-300 rounded-sm px-3 shadow-sm'

	return (
	<button
		key={value}
		type='button'
		className={`${defaultButtonStyle} ${buttonStyle}`}
		onClick={onClick}
	>
		{checked && (
				<MdCheck className='text-lg text-success' />
			)}
		<p>{label}</p>
	</button>
)}

export default Tag
