import AdminHeader from 'modules/admin/components/AdminHeader'
import { useTranslation } from 'react-i18next'

function TermAndCondition(props: { noHeader?: boolean }) {
	const { noHeader } = props
	const { t } = useTranslation()
	return (
		<div className='h-[92%] w-full flex flex-col container mx-auto px-2 md:p-0'>
			{!noHeader && (
				<div className='h-[8vh]'>
					<AdminHeader noHeader />
				</div>
			)}
			<div
				className="h-[calc(100% - 8vh)] overflow-auto flex mt-4 flex-col mx-auto items-center"
			>
				<div className="w-full flex flex-col gap-4">
					<div className='text-2xl font-bold tracking-wide'>
						{t('api.title_terms_and_conditions')}
					</div>
					<div>
						<p className='font-medium text-lg'>
							{t('api.description_terms_and_conditions')}
						</p>
					</div>
				</div>
			</div>
		</div>
	)
}

export default TermAndCondition
