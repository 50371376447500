import { IOptions } from '../shared/components/Select'

export const BREAK_POINT = 680

export const localeOptions: IOptions[] = [
	{
		label: 'en',
		value: 'en',
	},
	{
		label: 'nl',
		value: 'nl',
	},
]
