import { useTranslation } from 'react-i18next'
import whyChooseUs from 'assets/images/why_choose_us.png'
import whyChooseUs2 from 'assets/images/why_choose_us_2nd.png'
import machineImage from 'assets/images/machine.jpeg'
import dozerImage from 'assets/images/dozer.jpg'
import tractorImage from 'assets/images/tractor.jpg'
import makeAccount from 'assets/images/create_account.jpeg'
import { MdCheckCircle } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { useAuth } from 'modules/auth/context/useAuth'
import MachineCard from './machineCard'

const WhyMach = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const { isUserLoggedIn } = useAuth()

	return (
		<div className='flex flex-col gap-4 lg:gap-28'>
			<div className='flex flex-col lg:flex-row justify-between gap-4 lg:gap-12'>
				<div className='flex-1 flex flex-col justify-center gap-4'>
					<h2 className='text-2xl lg:text-5xl uppercase leading-tight font-bold max-w-md'>
						{t('api.why_choose_us')}
					</h2>
					<p className='font-normal'>{t('api.why_choose_us_description')}</p>
					<ol className='flex flex-col gap-2'>
						<li className='flex gap-4 items-center font-semibold'>
							<MdCheckCircle size={18} className='font-semibold text-primary' />
							<p className='flex-1'>{t('api.why_choose_us_list_1')}</p>
						</li>
						<li className='flex gap-4 items-center font-semibold'>
							<MdCheckCircle size={18} className='font-semibold text-primary' />
							<p className='flex-1'>{t('api.why_choose_us_list_2')}</p>
						</li>
						<li className='flex gap-4 items-center font-semibold'>
							<MdCheckCircle size={18} className='font-semibold text-primary' />
							<p className='flex-1'>{t('api.why_choose_us_list_3')}</p>
						</li>
						<li className='flex gap-4 items-center font-semibold'>
							<MdCheckCircle size={18} className='font-semibold text-primary' />
							<p className='flex-1'>{t('api.why_choose_us_list_4')}</p>
						</li>
					</ol>
				</div>
				<div className='flex flex-1 gap-4'>
					<img
						src={whyChooseUs}
						alt='user'
						loading='lazy'
						className='object-cover w-[calc(50%-0.5rem)]'
					/>
					<img
						src={whyChooseUs2}
						alt='user'
						loading='lazy'
						className='object-cover w-[calc(50%-0.5rem)]'
					/>
				</div>
			</div>
			<div className='flex flex-col-reverse lg:flex-row justify-between gap-6 lg:gap-12'>
				<div className='flex-1 h-96'>
					<img
						src={makeAccount}
						alt='user'
						className='object-cover h-full'
						loading='lazy'
					/>
				</div>
				<div className='flex-1 flex flex-col justify-center gap-4 md:gap-8'>
					<h2 className='text-2xl md:text-5xl uppercase leading-tight font-bold'>
						{t('api.create_an_account')}
					</h2>
					<p className='font-normal'>{t('api.what_mach_do_description')}</p>
					{!isUserLoggedIn && (
						<button
							type='button'
							className='btn btn-primary w-48'
							onClick={() => navigate('auth/signup')}
						>
							{t('api.create_an_account')}
						</button>
					)}
				</div>
			</div>
			<div className='flex flex-col lg:flex-row justify-between items-center gap-6 lg:gap-8'>
				<MachineCard
					image={machineImage}
					description={t('api.machine_description')}
					name={t('api.machine')}
				/>
				<MachineCard
					image={dozerImage}
					description={t('api.dozer_description')}
					name={t('api.dozers')}
				/>
				<MachineCard
					image={tractorImage}
					description={t('api.tractor_description')}
					name={t('api.tractors')}
				/>
			</div>
		</div>
	)
}

export default WhyMach
