import { Navigate, Route, Routes } from 'react-router-dom'
import { lazy, Suspense } from 'react'
import NotFound from 'modules/errors/NotFound'
import { BidProvider } from 'context/useBidContext'
import Landing from 'modules/landing'
import Role from '../../../enums/Roles'
import AddMachine from '../views/AddMachine'
import MyMachine from '../views/MyMachine'
import Settings from '../views/Settings'
import MachineUploadOverview from '../../admin/views/Machine/views/MachineUpload/components/MachineUploadOverview'
import MachineUpload from '../../admin/views/Machine/views/MachineUpload'
import BidedMachineDetail from '../views/BidedMachineDetail'
import Loader from '../../../shared/components/loader'

const Home = lazy(() => import('../views/Home'))
const BiddedMachine = lazy(() => import('../views/BiddedMachine'))
const MachineDetail = lazy(() => import('../views/MachineDetail'))
const Taxations = lazy(() => import('../views/Taxations'))

const UserRoute = () => (
	<BidProvider>
		<Suspense fallback={<Loader />}>
			<Routes>
				<Route path={`/${Role.ADMIN}/*`} element={<Navigate to='/' />} />
				<Route path={`/${Role.MANAGER}/*`} element={<Navigate to='/' />} />
				<Route path='landing' element={<Landing hideHeader />} />
				<Route path='' element={<Navigate to="auction" />} />
				<Route path='auction' element={<Home />} />
				<Route path='taxations' element={<Taxations />} />
				<Route path='taxations/:machineId' element={<BidedMachineDetail />} />
				<Route path='settings/*' element={<Settings />} />
				<Route path='/machine/:machineId' element={<BidedMachineDetail />} />
				<Route
					path='/company-machine/:machineId/overview'
					element={<MachineUploadOverview />}
				/>
				<Route
					path='/company-machine/concept/:machineId'
					element={<MachineUploadOverview />}
				/>
				<Route path='/company-machine/edit/*' element={<MachineUpload />} />
				<Route path='add-machine/*' element={<AddMachine />} />
				<Route path='/company-machine/:machineId' element={<MachineDetail />} />
				<Route path='bidded-machine/*' element={<BiddedMachine />} />
				<Route path='bidded-machine/:machineId' element={<BidedMachineDetail />} />
				<Route path='company-machine' element={<MyMachine />} />
				<Route path='*' element={<NotFound />} />
			</Routes>
		</Suspense>
	</BidProvider>
)

export default UserRoute
