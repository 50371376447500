import './index.css'

const ButtonLoader = () => (
        <div className="lds-ring">
            <div />
            <div />
            <div />
            <div />
        </div>
    )

export default ButtonLoader
