import { gql } from '@apollo/client'

const LOGIN = gql`
	mutation Login($email: String!, $password: String!) {
		login(email: $email, password: $password) {
			access_token
			user {
				id
			}
			message
		}
	}
`

const COMPANY_EXISTS = gql`
	query CompanyExists($company: String!) {
		companyExists(company: $company)
	}
`

const CURRENT_USER = gql`
	query CurrentUser {
		currentUser {
			id
			email
			user_details {
				first_name
				last_name
				mobile_number
			}
		}
	}
`

const AuthQueries = {
	LOGIN,
	COMPANY_EXISTS,
	CURRENT_USER,
}

export default AuthQueries
