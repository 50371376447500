import './loader.css'

const Loader = () => (
        <div className='h-full w-full flex justify-center items-center'>
         <div className="lds-roller m-auto">
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
         </div>
        </div>
    )

export default Loader
