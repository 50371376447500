import {
	MdArrowLeft,
	MdArrowRight,
	MdCategory,
	MdLanguage,
	MdOutlineAttachMoney,
	MdOutlineCategory,
	MdOutlineEditAttributes,
	MdOutlineMergeType,
	MdOutlineSupervisedUserCircle,
	MdOutlineViewColumn,
	MdSupervisedUserCircle,
	MdViewHeadline,
} from 'react-icons/md'
import { RiAuctionLine, RiMoneyPoundCircleLine } from 'react-icons/ri'
import { BsTruck } from 'react-icons/bs'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import SideBarMenu from '../../../shared/components/sideBarMenu'

const AdminSideBar = () => {
	const { t } = useTranslation()

	const [expandSidebar, setExpandSidebar] = useState<boolean>(true)

	return (
		<div className='flex flex-col h-full overflow-auto pt-4'>
			<button
				type='button'
				className='absolute right-[-12px] top-4 text-xl rounded-full p-[1px] bg-primary text-base-100'
				onClick={() => setExpandSidebar(!expandSidebar)}
			>
				{expandSidebar ? <MdArrowLeft /> : <MdArrowRight />}
			</button>
			<SideBarMenu
				logo={<MdCategory />}
				label={t('api.categories')}
				link='categories'
				expandSidebar={expandSidebar}
			/>
			<SideBarMenu
				logo={<MdOutlineMergeType />}
				label={t('api.types')}
				link='types'
				expandSidebar={expandSidebar}
			/>
			<SideBarMenu
				logo={<MdOutlineCategory />}
				label={t('api.sub_categories')}
				link='sub-categories'
				expandSidebar={expandSidebar}
			/>
			<SideBarMenu
				logo={<MdOutlineAttachMoney />}
				label={t('api.currencies')}
				link='currencies'
				expandSidebar={expandSidebar}
			/>
			<SideBarMenu
				logo={<MdLanguage />}
				label={t('api.translations')}
				link='translations'
				expandSidebar={expandSidebar}
			/>
			<SideBarMenu
				logo={<MdViewHeadline />}
				label={t('api.specifications')}
				link='specifications'
				expandSidebar={expandSidebar}
			/>
			<SideBarMenu
				logo={<MdOutlineEditAttributes />}
				label={t('api.attributes')}
				link='attributes'
				expandSidebar={expandSidebar}
			/>
			<SideBarMenu
				logo={<MdOutlineViewColumn />}
				label={t('api.column_platform')}
				link='column-platform'
				expandSidebar={expandSidebar}
			/>
			<SideBarMenu
				logo={<MdOutlineSupervisedUserCircle />}
				label={t('api.user_management')}
				link='user-management'
				expandSidebar={expandSidebar}
			/>
			<SideBarMenu
				logo={<MdOutlineSupervisedUserCircle />}
				label={t('api.company_management')}
				link='company-management'
				expandSidebar={expandSidebar}
			/>
			{/*			<SideBarMenu
				logo={<MdSupervisedUserCircle />}
				label={t('api.dealer')}
				link='dealer'
				expandSidebar={expandSidebar}
			/> */}
			<SideBarMenu
				logo={<MdSupervisedUserCircle />}
				label={t('api.group')}
				link='group'
				expandSidebar={expandSidebar}
			/>

			<SideBarMenu
				logo={<RiAuctionLine />}
				label={t('api.auction')}
				link='auction'
				expandSidebar={expandSidebar}
			/>

			<SideBarMenu
				logo={<BsTruck />}
				label={t('api.machines')}
				link='machines'
				expandSidebar={expandSidebar}
			/>

			<SideBarMenu
				logo={<RiMoneyPoundCircleLine />}
				label={t('api.commission_sidebar')}
				link='commission'
				expandSidebar={expandSidebar}
			/>
		</div>
	)
}

export default AdminSideBar
