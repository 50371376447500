import { useBidContext } from 'context/useBidContext'
import { useParams } from 'react-router-dom'
import MachineItemOverview from 'shared/MachineItemOverview'
import { createRef } from 'react'
import { BsArrowUp } from 'react-icons/bs'
import { useQuery } from '@apollo/client'
import { FIND_MACHINE_BY_ID } from 'modules/admin/views/Machine/views/MachineUpload/graphql/query'
import { IMachineData } from 'modules/admin/views/Machine/views/MachineUpload/graphql/model'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import BidConfirmation from '../components/BidConfirmation'
import MachineBidForm from '../components/MachineBidForm'
import ActionMenu from '../components/ActionMenu'

const BidedMachineDetail = () => {
	const { machineId } = useParams()
	const { bidConfirmationScreen } = useBidContext()
	// const [minBidAmount, setMinBidAmount] = useState<number>(0)
	const { t } = useTranslation()

	useQuery<IMachineData>(FIND_MACHINE_BY_ID, {
		fetchPolicy: 'no-cache',
		skip: !machineId,
		variables: {
			id: parseInt(machineId || '0', 10),
		},
		onError: error => {
			toast.error(t('message.machine_load_fail') || error.message)
		},
	})

	const userViewRef = createRef<any>()
	const executeScroll = () => {
		userViewRef.current.scrollIntoView({
			top: 0,
			behavior: 'smooth',
		})
	}

	const confirmationViewRef = createRef<any>()
	const executeScrollConfirmationView = () => {
		confirmationViewRef.current.scrollIntoView({
			top: 0,
			behavior: 'smooth',
		})
	}

	return (
		<>
			<div
				className='container mx-auto h-full flex flex-col'
				id='machine-detail'
				ref={userViewRef}
			>
				<div className='py-2 md:py-4 sticky bg-white top-0 z-10'>
					<ActionMenu />
				</div>
				<div ref={confirmationViewRef}>
					<MachineItemOverview
						asideContent={
							<MachineBidForm
								machineId={parseInt(machineId || '0', 10)}
								scrollToView={executeScrollConfirmationView}
							/>
						}
						content={bidConfirmationScreen ? <BidConfirmation /> : <span />}
						hideMagnifier={bidConfirmationScreen}
					/>
				</div>
			</div>
			<div className='fixed bottom-12 right-10' title='Scroll to top'>
				<button
					className='btn  btn-primary rounded-full transition-opacity'
					type='button'
					onClick={() => executeScroll()}
				>
					<BsArrowUp size={20} />
				</button>
			</div>
		</>
	)
}
export default BidedMachineDetail
