import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from '@apollo/client'
import { toast } from 'react-toastify'
import { MdAdd, MdDelete, MdEdit, MdSettingsSuggest } from 'react-icons/md'

import { NavLink, useNavigate } from 'react-router-dom'
import moment from 'moment'
import { IUser, IUserData } from '../graphql/model'
import { FIND_ALL_USERS, FIND_ALL_USERS_BY_COMPANY_ID } from '../graphql/query'
import Toggle from '../../../../../shared/components/Toggle'
import Loader from '../../../../../shared/components/loader'
import {
	REMOVE_COMPANY,
	TOGGLE_USER_CAN_PLACE_BID,
	TOGGLE_USER_STATUS,
} from '../graphql/mutation'
import ButtonLoader from '../../../../../shared/components/buttonLoader'
import AlertModal from '../../../../../shared/AlertModal'
import Table from '../../../../../shared/components/table/table'
import { IBreadCrumbLink } from '../../../components/AdminSubHeader/model'
import AdminSubHeader from '../../../components/AdminSubHeader'
import { useAuth } from '../../../../auth/context/useAuth'
import ROLE from '../../../../../enums/Roles'
import TextArea from '../../../../../shared/components/TextArea'

interface IUserList {
	showAllUser?: boolean | undefined
	companyId?: number | undefined | string
	breadCrumbProp?: IBreadCrumbLink[]
}

const UsersList = (props: IUserList) => {
	const { showAllUser = false, companyId, breadCrumbProp } = props
	const { t } = useTranslation()
	const navigate = useNavigate()
	const { user, role } = useAuth()

	const breadCrumb: IBreadCrumbLink[] = breadCrumbProp || [
		{
			label: t('api.user_management'),
			link: '',
		},
	]

	const Header = () => (
		<div className='flex justify-end items-center gap-4'>
			<NavLink
				to='create'
				className='w-42 btn btn-secondary btn-sm  gap-2 px-5 flex justify-center items-center'
			>
				<MdAdd className='text-lg' />
				<span className='font-bold'>{t('api.create')}</span>
			</NavLink>
		</div>
	)

	const [selectedUser, setSelectedUser] = useState<IUser | null>(null)
	const [showEnableDisableModal, setShowEnableDisableModal] = useState(false)
	const [showCanPlaceBidModal, setShowCanPlaceBidModal] =
		useState<boolean>(false)
	const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
	const [userEnableDisableRemark, setUserEnableDisableRemark] =
		useState<string>('')

	const { loading, data } = useQuery<IUserData>(
		role === ROLE.ADMIN && showAllUser
			? FIND_ALL_USERS
			: FIND_ALL_USERS_BY_COMPANY_ID,
		{
			variables: { id: companyId !== undefined ? companyId : user?.company?.id },
			onError: error => {
				toast.error(error.message || `${t('message.failed_to_load_user')}`)
			},
		}
	)

	const [removeUser, { loading: removeUserLoading }] = useMutation(
		REMOVE_COMPANY,
		{
			refetchQueries: [{ query: FIND_ALL_USERS }],
			onCompleted(data) {
				toast.success(data.message || t('message.user_removed_success'))
			},
			onError(error) {
				toast.error(error.message || t('message.user_removed_failed'))
			},
		}
	)

	const [enabledUser, { loading: isEnabledUserLoading }] = useMutation(
		TOGGLE_USER_STATUS,
		{
			onCompleted(data) {
				setUserEnableDisableRemark('')
				toast.success(
					data.message || (selectedUser && !selectedUser.is_enabled)
						? t('message.user_enable_success')
						: t('message.user_disable_success')
				)
			},
			onError(error) {
				toast.error(error.message || t('message.user_enable_failed'))
			},
		}
	)

	const [toggleCanPlaceBid, { loading: canPlaceBidLoading }] = useMutation(
		TOGGLE_USER_CAN_PLACE_BID,
		{
			onCompleted(data) {
				toast.success(
					data.message || (selectedUser && selectedUser.can_place_bid)
						? t('message.user_disable_can_place_bid')
						: t('message.user_enable_can_place_bid')
				)
			},
			onError(error) {
				toast.error(error.message || 'User edit Failed')
			},
		}
	)

	const closeModelHandler = () => {
		setShowEnableDisableModal(false)
		setShowDeleteModal(false)
		setShowCanPlaceBidModal(false)
	}

	const toggleUserEnableDisable = async () => {
		await enabledUser({
			variables: {
				id: selectedUser?.id,
				remark: userEnableDisableRemark,
			},
			refetchQueries: [{ query: FIND_ALL_USERS }],
		})
		setSelectedUser(null)
		closeModelHandler()
	}

	const toggleUserCanPlaceBid = async () => {
		closeModelHandler()
		await toggleCanPlaceBid({
			variables: {
				id: selectedUser?.id,
			},
			refetchQueries: [{ query: FIND_ALL_USERS }],
		})
		setSelectedUser(null)
	}

	const onUserEnableToggle = async (e: any, row: any) => {
		setSelectedUser(row)
		setShowEnableDisableModal(!showEnableDisableModal)
	}

	const onUserCanPlaceBid = async (e: any, row: any) => {
		setSelectedUser(row)
		setShowCanPlaceBidModal(!showCanPlaceBidModal)
	}

	const handleUserRemove = async (row: any) => {
		setSelectedUser(row)
		setShowDeleteModal(true)
	}

	const getTabRowAction = (row: any) => (
		<div className='p-2 flex gap-2 justify-around items-center'>
			<button
				type='button'
				className=' '
				title={t('api.preference')}
				onClick={() =>
					// eslint-disable-next-line no-console
					navigate(`${row.id}/preference`)
				}
			>
				<MdSettingsSuggest className='text-neutral hover:text-error text-2xl' />
			</button>
			<button
				type='button'
				className=' '
				title={t('api.edit')}
				onClick={() =>
					// eslint-disable-next-line no-console
					navigate(row.id)
				}
			>
				<MdEdit className='text-neutral hover:text-error text-2xl' />
			</button>
			<button
				type='button'
				className=' '
				title={t('api.delete')}
				onClick={() => handleUserRemove(row)}
			>
				{removeUserLoading ? (
					<ButtonLoader />
				) : (
					<MdDelete className='text-neutral hover:text-error text-2xl' />
				)}
			</button>
		</div>
	)

	const columns: any = [
		{
			id: 'id',
			name: t('api.id'),
			sortable: true,
			selector: (row: IUser) => row.id,
			cell: (row: IUser) => <p className='text-lg'> {row.id}</p>,
			maxWidth: '6rem',
		},
		{
			id: 'email',
			name: t('api.email'),
			sortable: true,
			selector: (row: IUser) => row.email,
			cell: (row: IUser) => <p className='text-lg'> {row.email}</p>,
		},
		{
			id: 'company',
			name: t('api.company'),
			sortable: true,
			selector: (row: IUser) => (row.company ? row.company.company_name : ''),
			cell: (row: IUser) => (
				<p className='text-lg'> {row.company ? row.company.company_name : ''}</p>
			),
		},
		{
			id: 'created_at',
			name: t('api.created_at'),
			sortable: true,
			selector: (row: IUser) => row.created_at,
			cell: (row: IUser) => (
				<p className='text-lg '>{moment(row.created_at).format('YYYY-MM-DD')}</p>
			),
			maxWidth: '12rem',
		},
		{
			id: 'is_enabled',
			name: t('api.is_enabled'),
			selector: (row: any) => (
				<Toggle
					name='is_enabled'
					id='my-modal-6'
					checked={row.is_enabled}
					onChange={event => onUserEnableToggle(event, row)}
				/>
			),
			maxWidth: '12rem',
			center: true,
		},
		{
			id: 'can_place_bid',
			name: t('api.can_place_bid'),
			selector: (row: any) => (
				<Toggle
					name='can_place_bid'
					id='my-modal-6'
					checked={row.can_place_bid}
					onChange={event => onUserCanPlaceBid(event, row)}
				/>
			),
			maxWidth: '12rem',
			center: true,
		},
		{
			name: t('api.actions'),
			right: true,
			cell: (row: any) => getTabRowAction(row),
		},
	]

	return (
		<>
			<AdminSubHeader breadCrumbs={role === ROLE.ADMIN ? breadCrumb : null}>
				<Header />
			</AdminSubHeader>
			{(loading || isEnabledUserLoading || canPlaceBidLoading) && (
				<div className='h-[80vh] w-[80vw] flex justify-center items-center'>
					<Loader />
				</div>
			)}
			{!loading && data && (
				<div className='flex justify-center items-center'>
					<div className='w-full'>
						<Table
							columns={columns}
							data={data.findAllUsers || data.findUsersByCompanyId}
							pagination={false}
						/>
					</div>
				</div>
			)}
			{showEnableDisableModal && selectedUser && (
				<AlertModal
					title={
						selectedUser.is_enabled
							? t('message.user_disabled_title')
							: t('message.user_enabled_title')
					}
					description={
						selectedUser.is_enabled
							? t('message.user_disabled_description')
							: t('message.user_enabled_description')
					}
					cancelOnClick={closeModelHandler}
					okOnClick={toggleUserEnableDisable}
					okClickLoader={isEnabledUserLoading}
				>
					<TextArea
						name='enableDisableRemark'
						value={userEnableDisableRemark}
						onChange={event => setUserEnableDisableRemark(event.target.value)}
						label={t('fields.remark')}
						labelStyles='text-base normal-case font-normal'
					/>
				</AlertModal>
			)}
			{showCanPlaceBidModal && selectedUser && (
				<AlertModal
					title={t(
						selectedUser.can_place_bid
							? 'message.user_can_not_place_bid_title'
							: 'message.user_can_place_bid_title'
					)}
					description={t(
						selectedUser.can_place_bid
							? 'message.user_can_not_place_bid_description'
							: 'message.user_can_place_bid_description'
					)}
					cancelOnClick={closeModelHandler}
					okOnClick={toggleUserCanPlaceBid}
					okClickLoader={canPlaceBidLoading}
				/>
			)}
			{showDeleteModal && selectedUser && (
				<AlertModal
					title={t('message.delete_user_title')}
					description={t('message.delete_user_description')}
					cancelOnClick={closeModelHandler}
					okOnClick={async () => {
						await removeUser({ variables: { id: selectedUser.id } })
						setSelectedUser(null)
					}}
				/>
			)}
		</>
	)
}

export default UsersList
