import { useTranslation } from 'react-i18next'
import HeroImage from 'assets/images/what_we_do.jpg'
import { useNavigate } from 'react-router-dom'
import { useAuth } from 'modules/auth/context/useAuth'
import { MdCheckCircle } from 'react-icons/md'

const WhatWeDo = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const { isUserLoggedIn } = useAuth()

	return (
		<div className='w-full flex flex-col-reverse lg:flex-row justify-between gap-4 lg:gap-12'>
			<div className='flex-1 flex flex-col pt-8 h-fit gap-4'>
				<h2 className='text-2xl lg:text-5xl leading-tight uppercase font-extrabold max-w-xl'>
					{t('api.what_does_mach_machinery_do')}
				</h2>
				<p className='font-normal max-w-[39rem]'>
					{t('api.what_we_do_description')}
				</p>
				<ol className='flex flex-col gap-2'>
					<li className='flex gap-4 items-start font-semibold'>
						<MdCheckCircle
							size={18}
							className='font-semibold text-primary mt-2'
						/>
						<p className='flex-1'>{t('api.what_we_do_list_1')}</p>
					</li>
					<li className='flex gap-4 items-start font-semibold'>
						<MdCheckCircle
							size={18}
							className='font-semibold text-primary mt-2'
						/>
						<p className='flex-1'>{t('api.what_we_do_list_2')}</p>
					</li>
					<li className='flex gap-4 items-start font-semibold'>
						<MdCheckCircle
							size={18}
							className='font-semibold text-primary mt-2'
						/>
						<p className='flex-1'>{t('api.what_we_do_list_3')}</p>
					</li>
				</ol>
				{!isUserLoggedIn && (
					<button
						type='button'
						className='btn btn-primary w-48 mt-4'
						onClick={() => navigate('auth/signup')}
					>
						{t('api.create_an_account')}
					</button>
				)}
			</div>
			<div className='flex-1 h-full max-h-[478px]'>
				<img
					src={HeroImage}
					alt='user'
					className='h-full object-cover'
					loading='lazy'
				/>
			</div>
		</div>
	)
}

export default WhatWeDo
