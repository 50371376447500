import { useQuery } from '@apollo/client'

import { useCallback, useEffect, useRef, useState } from 'react'
import { FormikProps, useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { IMachine } from 'modules/admin/views/Machine/views/MachineUpload/graphql/model'
import UserSidebar, {
	ISideBarForm,
} from '../components/UserSidebar/UserSidebar'
import { FilterMachine } from '../graphql/query'
import { IMyMachineData } from '../graphql/model'
import FilterBar from '../components/FilterBar'
import MachineError from '../../../shared/components/MachineError'
import generateMachineDescription from '../../../utils/generateMachineDesctiption'
import { IMachineDescription } from '../model'
import Loader from '../../../shared/components/loader'
import STATUS from '../../../enums/STATUS'
import StatusFilter from '../components/StatusFilter'
import MachineCard from '../components/MachineCard'

const initialPageNumber = 1

const MyMachine = () => {
	const { t } = useTranslation()

	const [search, setSearch] = useState<string>('')
	const [machine, setMachine] = useState<IMachine[]>([])
	const [pageNum, setPageNum] = useState(initialPageNumber)
	const [hasMore, setHasMore] = useState(false)

	const formik: FormikProps<ISideBarForm> = useFormik<ISideBarForm>({
		initialValues: {
			selectedCategories: [],
			selectedTypes: [],
			selectedSubCategories: [],
			search: '',
			status: [STATUS.AUCTION, STATUS.CONCEPT, STATUS.TAXATION],
		},
		onSubmit: async () => {},
	})

	const [status, setStatus] = useState<STATUS | null>(null)

	const { loading: machineLoading } = useQuery<IMyMachineData>(FilterMachine, {
		fetchPolicy: 'no-cache',
		variables: {
			category_ids: formik.values.selectedCategories,
			type_ids: formik.values.selectedTypes,
			subcategory_ids: formik.values.selectedSubCategories,
			status: formik.values.status,
			bid_status: [],
			page: pageNum,
			first: 25,
		},
		onCompleted: data => {
			setHasMore(data?.findMachineByCompany?.paginatorInfo?.hasMorePages || false)
			const newMachine =
				data.findMachineByCompany.paginatorInfo.currentPage === 1
					? data.findMachineByCompany.data
					: [...machine, ...data.findMachineByCompany.data]
			setMachine(newMachine)
		},
	})

	const observer = useRef<any>()
	const lastMachineElementRef = useCallback(
		// (*)
		// eslint-disable-next-line consistent-return
		node => {
			if (machineLoading) return <Loader />
			if (observer.current) observer.current.disconnect()
			observer.current = new IntersectionObserver(entries => {
				if (entries[0].isIntersecting && hasMore) {
					setPageNum(prev => prev + 1)
				}
			})
			if (node) observer.current.observe(node)
		},
		[machineLoading, hasMore]
	)

	useEffect(() => {
		setPageNum(initialPageNumber)
		if (status !== null) {
			formik.setFieldValue('status', [status])
		} else {
			formik.setFieldValue('status', [
				STATUS.AUCTION,
				STATUS.CONCEPT,
				STATUS.TAXATION,
			])
		}
		setMachine([])
	}, [status])
	const [backDrop, setBackDrop] = useState<boolean>(false)

	const getContent = () => {
		if (!machine.length) {
			return <MachineError message={t('message.no_machine_available')} />
		}
		if (machine && machine.length > 0) {
			return machine.map((machineItem, index) => {
				const machineDescriptions: IMachineDescription[] =
					generateMachineDescription(machineItem)
				return (
					<MachineCard
						id={machineItem.id}
						key={index}
						machineName={`${machineItem.brand || ''} ${
							machineItem.model ? `- ${machineItem.model}` : ''
						}`}
						location={machineItem.location}
						image={
							machineItem.images &&
							machineItem.images.length &&
							machineItem.images[0].thumbnail_image
								? machineItem.images[0].thumbnail_image
								: null
						}
						queryParams={{
							categories: formik.values.selectedCategories.toString(),
							type: formik.values.selectedTypes.toString(),
							subCategories: formik.values.selectedSubCategories.toString(),
							source: 'company_machine',
							page: String(index + 1),
							status: formik.values.status ? formik.values.status.toString() : '',
						}}
						hideBidPrice
						// minBidAmount={machineItem.min_bid_amount || 0}
						machineStatus={machineItem.status}
						machineDescription={machineDescriptions}
						machineBidStatus={machineItem.bid_status}
						cardRef={machine.length === index + 1 ? lastMachineElementRef : null}
					/>
				)
			})
		}
		if (machineLoading && !hasMore && machine.length === 0) {
			return <Loader />
		}
		return null
	}

	return (
		<div className='flex flex-col h-full gap-4 mx-auto'>
			<div className='py-2 md:py-4 sticky bg-white  top-0 z-10'>
				<FilterBar
					search={search}
					searchName='search'
					setSearch={setSearch}
					searchPlaceholder='Search Machine'
					handleFilterClick={() => {
						setBackDrop(!backDrop)
					}}
				>
					<StatusFilter selectedStatus={status} setSelectedStatus={setStatus} />
				</FilterBar>
			</div>
			<div className='flex container mx-auto h-full gap-4'>
				<UserSidebar
					formik={formik}
					backDrop={backDrop}
					setBackDrop={setBackDrop}
					setPageNumber={setPageNum}
				/>
				<div className='w-full flex flex-col justify-start'>
					<div className='w-full h-full flex flex-col gap-2 md:gap-4'>
						{getContent()}
						{machineLoading && hasMore && <Loader />}
					</div>
					{!hasMore && machine.length > 0 && (
						<div className='flex justify-center items-center py-4'>
							<p>{t('api.no_machine_remains')}</p>
						</div>
					)}
				</div>
			</div>
		</div>
	)
}

export default MyMachine
