import NotFoundImage from '../../assets/svg/404.svg'

const NotFound = () => (
	<div className='w-full h-full'>
		<div className='flex justify-center items-center gap-8 h-full w-full flex-wrap md:flex-nowrap'>
			<div className='flex flex-col gap-16 md:mr-16 w-full md:w-1/4'>
				<h1 className='text-9xl font-black leading-5 tracking-widest'>404</h1>
				<div className='flex flex-col gap-2'>
					<h2 className='text-2xl md:text-5xl  font-black'>Oops!!</h2>
					<h2 className='text-2xl md:text-5xl font-black'>Page not found</h2>
				</div>
				<a href='/' className='btn btn-success'>
					Back to Home
				</a>
			</div>
			<div className='hidden md:block'>
				<img
					src={NotFoundImage}
					alt='Page Not Found'
					className='h-fit w-fit object-contain'
				/>
			</div>
		</div>
	</div>
)

export default NotFound
