import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { BrowserRouter } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'
import { I18nextProvider } from 'react-i18next'
import App from './App'
import reportWebVitals from './reportWebVitals'
import apolloClient from './config/ApolloClient'
import { AuthProvider } from './modules/auth/context/useAuth'
import i18n from './plugins/i18n'

const app = (
	<React.StrictMode>
		<BrowserRouter>
			<ApolloProvider client={apolloClient}>
				<I18nextProvider i18n={i18n}>
					<AuthProvider>
						<App />
					</AuthProvider>
				</I18nextProvider>
			</ApolloProvider>
		</BrowserRouter>
	</React.StrictMode>
)

ReactDOM.render(app, document.getElementById('mach-machinery-root'))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
