import { gql } from '@apollo/client'

const FIND_ALL_ATTRIBUTES = gql`
	query FindAllAttributes {
		findAllAttributes {
			id
			attribute_name
			type
			specification_type
			mandatory
			dependent_to
			translatable
			translatable_value
		}
	}
`

const FIND_ATTRIBUTE_BY_ATTRIBUTE_NAME_WITH_PAGINATION = gql`
	query FindAttributeByAttributeNameWithPagination ($attribute_name: String, $first: Int!, $page: Int) {
		findAttributeByAttributeNameWithPagination(attribute_name: $attribute_name, first: $first, page: $page) {
				paginatorInfo {
					count
					currentPage
					hasMorePages
					total
				}
				data {
						id
						attribute_name
						type
						specification_type
						mandatory
						dependent_to
						translatable
				}
		}
	}
`

const FIND_ATTRIBUTE_BY_ID = gql`
	query FindAttributeById($id: Int!) {
		findAttributeById(id: $id) {
			id
			attribute_name
			type
			specification_type
			mandatory
			dependent_to
			dependent_value
			show_type
			status
			translatable
			translatable_value
		}
	}
`

const FIND_ALL_SELECTS = gql`
	query FindAllSelects {
		findAllSelects {
			id
			attribute_id
			name
			order
		}
	}
`

const FIND_SELECTS_BY_ID = gql`
	query FindSelectsById($id: Int!) {
		findSelectsById(id: $id)
	}
`

const FIND_SELECTS_BY_ATTRIBUTE_ID = gql`
	query FindSelectsByAttributeId($attributeId: Int!) {
		findSelectsByAttributeId(attribute_id: $attributeId) {
			id
			attribute_id
			name
			order
		}
	}
`

const FIND_ALL_ATTRIBUTES_EVERY_FIELD = gql`
	query FindAllAttributes {
		findAllAttributes {
			id
			attribute_name
			type
			specification_type
			mandatory
			dependent_to
			dependent_value
			show_type
			status
			translatable
			translatable_value
		}
	}
`

export {
	FIND_ALL_ATTRIBUTES,
	FIND_ALL_SELECTS,
	FIND_ATTRIBUTE_BY_ID,
	FIND_SELECTS_BY_ATTRIBUTE_ID,
	FIND_SELECTS_BY_ID,
	FIND_ALL_ATTRIBUTES_EVERY_FIELD,
	FIND_ATTRIBUTE_BY_ATTRIBUTE_NAME_WITH_PAGINATION
}
