import machIcon from 'assets/images/MACH_ICON-GRADIENT.png'
import { MdCopyright } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Footer = () => {
	const { t } = useTranslation()

	return (
		<div className='bg-gray-300 h-full px-8 flex justify-between items-center gap-8'>
			<div className='w-4'>
				<img
					src={machIcon}
					alt='Mach Machinery'
					className='h-full w-full object-contain'
				/>
			</div>
			<div className='flex-1 flex flex-col-reverse md:flex-row justify-end gap-1 md:gap-8 items-end md:items-center'>
				<Link to="/" className='flex gap-2 items-center font-bold'>
					<MdCopyright size={12}/>
					<p className='text-sm align-baseline'>
						{new Date().getFullYear()} Mach B.V. All Rights Reserved
					</p>
				</Link>
				<Link className='hidden md:block' to='/privacy-policy'>
					<p className=' text-sm font-bold'>{t('api.title_privacy_policy')}</p>
				</Link>
				<Link className='hidden md:block' to='/term-and-condition'>
					<p className=' text-sm font-bold'>{t('api.terms_of_use')}</p>
				</Link>
			</div>
		</div>
	)
}

export default Footer
