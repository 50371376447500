import { ChangeEvent } from 'react'

interface ICheckboxProps {
	name: string
	checked: boolean
	disabled?: boolean
	onChange?: (event: ChangeEvent<HTMLInputElement>) => void
	label?: string
	id?: string
	title?: string
}

const Toggle = (props: ICheckboxProps) => {
	const { name, checked, disabled, onChange, label, id, title } = props

	return (
		<div className='form-control' title={title}>
			<label className='label cursor-pointer flex gap-4' htmlFor={name}>
				{label && <span className='text-lg font-semibold  '>{label}</span>}
				<input
					id={id || name}
					type='checkbox'
					disabled={disabled}
					name={name}
					value={name}
					checked={checked}
					onChange={onChange}
					className='toggle toggle-primary'
					title={title}
				/>
			</label>
		</div>
	)
}

export default Toggle
