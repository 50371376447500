import { Route, Routes } from 'react-router-dom'
import CreateMachine from '../components/createMachine'
import MachineUploadOverview from '../components/MachineUploadOverview'

const MachineUploadRoutes = () => (
	<Routes>
		<Route path='' element={<CreateMachine />} />
		<Route path=':machineId' element={<CreateMachine />} />
		<Route path=':machineId/overview' element={<MachineUploadOverview />} />
		<Route path='*' element={<p> Not found</p>} />
	</Routes>
)

export default MachineUploadRoutes
