import { ReactElement } from 'react'

interface IUserSubHeaderProps {
	children?: ReactElement
}

const UserSubHeader = (props: IUserSubHeaderProps) => {
	const { children } = props

	return <div className='ml-32 h-16'>{children}</div>
}

export default UserSubHeader
