import { Route, Routes } from 'react-router-dom'
import Preferences from 'modules/user/views/Settings/components/Preferences'
import UsersList from '../components/UsersList'
import UserForm from '../components/UserForm'

const UserManagementRoutes = () => (
	<Routes>
		<Route path='' element={<UsersList showAllUser />} />
		<Route path='create' element={<UserForm />} />
		<Route path=':id' element={<UserForm />} />
		<Route path=":id/preference" element={<Preferences />} />
		<Route path='*' element={<p> Not found</p>} />
	</Routes>
)

export default UserManagementRoutes
