import { ChangeEvent, forwardRef, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

interface IInputProps {
	type: 'text' | 'email' | 'number' | 'password' | 'file' | 'tel' | 'search'
	placeholder?: string
	value?: string | number | null
	label?: string
	name: string
	disabled?: boolean
	autoComplete?: 'on' | 'off'
	leftContent?: ReactElement
	onClickLeftContent?: () => void
	rightContent?: ReactElement
	onClickRightContent?: () => void
	onChange?: (event: ChangeEvent<HTMLInputElement>) => void
	labelStyles?: string
	inputStyles?: string
	errorMessage?: string
	pattern?: string | any
	dataInputMask?: string
}

const Input = forwardRef<HTMLInputElement, IInputProps>(
	(props: IInputProps, ref) => {
		const {
			type,
			placeholder,
			value,
			label,
			name,
			disabled,
			autoComplete,
			leftContent,
			onClickLeftContent,
			rightContent,
			onClickRightContent,
			onChange,
			labelStyles,
			inputStyles,
			errorMessage,
			pattern,
			dataInputMask,
		} = props

		const { t } = useTranslation()

		const defaultLabelStyle = 'font-semibold '
		const defaultInputStyle = `w-full text-md ${
			type === 'file'
				? 'outline-none'
				: 'outline outline-[1px] outline-gray-300 drop-shadow-sm'
		} focus-within:outline-secondary rounded-sm flex justify-between items-center`

		return (
			<div className='flex flex-col gap-1 w-full'>
				{label && (
					<label
						htmlFor={name}
						className={`${defaultLabelStyle} ${labelStyles || ''}`}
					>
						{label}
					</label>
				)}
				<div className={`${defaultInputStyle} ${inputStyles || ''}`}>
					{leftContent && (
						<span
							className='ml-2 mr-1'
							onClick={onClickLeftContent}
							aria-hidden='true'
						>
							{leftContent}
						</span>
					)}
					<input
						id={name}
						ref={ref}
						type={type || 'text'}
						placeholder={placeholder}
						value={value || ''}
						onChange={onChange}
						autoComplete={autoComplete}
						name={name}
						pattern={pattern}
						data-inputmask={dataInputMask}
						disabled={Boolean(disabled)}
						className={`rounded-none w-full focus:outline-none flex-1 p-2 ${inputStyles}`}
					/>
					{rightContent && (
						<span
							className='ml-1 mr-2'
							key='right'
							onClick={onClickRightContent}
							aria-hidden='true'
						>
							{rightContent}
						</span>
					)}
				</div>
				{errorMessage && <p className='text-sm text-error'>{t(errorMessage)}</p>}
			</div>
		)
	}
)

export default Input
