import { gql } from '@apollo/client'

const CREATE_BID = gql`
	mutation createBid($machineId: ID!, $value: Int!, $valueCurrency: String!) {
		createBid(
			machine_id: $machineId
			value: $value
			value_currency: $valueCurrency
		) {
			id
		}
	}
`

const ACCEPT_BID = gql`
	mutation acceptBid($bid_id: ID!, $remarks: String) {
		acceptBid(bid_id: $bid_id, remarks: $remarks) {
			id
		}
	}
`

const REJECT_BID = gql`
	mutation rejectBid($bid_id: ID!, $remark: String) {
		rejectBid(bid_id: $bid_id, remarks: $remark) {
			id
		}
	}
`

const PENDING_BID = gql`
	mutation pendingBid($bid_id: ID!, $remark: String) {
		pendingBid(bid_id: $bid_id, remarks: $remark) {
			id
		}
	}
`

const REMOVE_MACHINE = gql`
	mutation RemoveMachine($id: ID!) {
		removeMachine(id: $id) {
			id
		}
	}
`

const CHANGE_USER_LOCALE = gql`
	mutation changeLocale($locale: String!) {
		changeLocale(locale: $locale) {
			id
		}
	}
`

const STORE_COMMISSION = gql`
	mutation calculateCommission(
		$machine_id: ID!
		$amount: Float!
		$bid_id: ID!
		$remarks: String
	) {
		calculateCommission(machine_id: $machine_id, value: $amount)
		acceptBid(bid_id: $bid_id, remarks: $remarks) {
			id
		}
	}
`

export {
	ACCEPT_BID,
	PENDING_BID,
	REJECT_BID,
	CHANGE_USER_LOCALE,
	CREATE_BID,
	REMOVE_MACHINE,
	STORE_COMMISSION,
}
