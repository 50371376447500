import { Trans } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { FormikProps, useFormik } from 'formik'
import * as Yup from 'yup'
import { useLazyQuery, useMutation } from '@apollo/client'
import { toast } from 'react-toastify'
import { useEffect, useState } from 'react'
import countries from 'i18n-iso-countries'
import enLocale from 'i18n-iso-countries/langs/en.json'
import nlLocale from 'i18n-iso-countries/langs/nl.json'
import Translation from '../../translation'
import { ISignupData } from '../model'
import { REGISTER_USER } from '../graphql/mutation'
import ButtonLoader from '../../../shared/components/buttonLoader'
import Input from '../../../shared/components/Input'
import PhoneInputComponent from '../../../shared/components/PhoneInputComponent'
import MultiSelect from '../../../shared/components/MultiSelect'
import Checkbox from '../../../shared/components/Checkbox'
import AuthQueries from '../graphql/query'
import { ICompanyExist } from '../graphql/model'
import useDebounce from '../../../shared/hooks/useDebounce'
import TermAndCondition from '../../common/TermAndCondition'
import AlertModal from '../../../shared/AlertModal'
import PrivacyPolicy from '../../common/PrivacyPolicy'
import i18n from '../../../plugins/i18n'
import { IOptions } from '../../../shared/components/Select'
import useTranslationData from '../../translation/hooks/useTranslationData'


countries.registerLocale(enLocale)
countries.registerLocale(nlLocale)

const Signup = () => {
	const { t, selectedLanguage } = useTranslationData()

	const navigate = useNavigate()

	const [countryOptions, setCountryOptions] = useState<IOptions[]>()

	useEffect(() => {
		const countryObj = countries.getNames(i18n.language === 'en-US' ? 'en' : i18n.language === 'nl-NL' ? 'nl' : i18n.language || 'en', { select: "official" })
		setCountryOptions(
			Object.entries(countryObj).map(([key,value]) => ({
					label: value,
					value: key
				}))
		)
	}, [i18n.language])

	const [openTermsAndConditionModal, setOpenTermsAndConditionModal] =
		useState<boolean>(false)
	const [openPrivacyPolicyModal, setOpenPrivacyPolicyModal] =
		useState<boolean>(false)

	const [registerUser, { loading }] = useMutation(REGISTER_USER, {
		onCompleted(data) {
			navigate('/auth/login')
			toast.success(data.message || t('message.register_user_success'))
		},
		onError(error) {
			toast.error(error.message || t('message.register_user_failed'))
		},
	})

	const handleRegisterUser = async (formData: ISignupData) => {
		await registerUser({
			variables: {
				first_name: formData.firstName,
				last_name: formData.lastName,
				mobile_number: formData.mobileNumber,
				email: formData.email.toLowerCase(),
				role_id: 2,
				company_name: formData.companyName,
				country_name: formData.countryName,
				chamber_of_commerce: formData.chamberOfCommerceNumber,
				vat_number: formData.vatNumber,
				phone_number: formData.phoneNumber,
				city: formData.city,
				postal_code: formData.postalCode,
				street_name: formData.streetName,
				street_number: formData.streetNumber,
				interest_trader: 1,
				locale: selectedLanguage || 'nl'
			},
		})
	}

	const formik: FormikProps<ISignupData> = useFormik<ISignupData>({
		initialValues: {
			companyName: '',
			countryName: '',
			chamberOfCommerceNumber: '',
			vatNumber: '',
			phoneNumber: '',
			mobileNumber: '',
			address: '',
			city: '',
			streetName: '',
			streetNumber: '',
			postalCode: '',
			firstName: '',
			lastName: '',
			email: '',
			companyLogo: '',
			subscription: 0,
			agreeTermsAndCondition: false,
		},
		onSubmit: async values => {
			await handleRegisterUser(values)
		},
		validationSchema: Yup.object().shape({
			companyName: Yup.string().required('message.company_is_required'),
			chamberOfCommerceNumber: Yup.string().required(
				'message.chamber_of_commerce_number_is_required'
			),
			vatNumber: Yup.string().required('message.vat_number_is_required'),
			mobileNumber: Yup.string().required('message.mobileno_is_required'),
			countryName: Yup.string().required('message.country_is_required'),
			city: Yup.string().required('message.city_is_required'),
			streetName: Yup.string().required('message.street_name_is_required'),
			streetNumber: Yup.string().required('message.street_number_is_required'),
			postalCode: Yup.string().required('message.postal_code_is_required'),
			firstName: Yup.string().required('message.first_name_is_required'),
			lastName: Yup.string().required('message.last_name_is_required'),
			agreeTermsAndCondition: Yup.boolean().oneOf(
				[true],
				'message.must_accept_terms_and_conditions'
			),
			email: Yup.string()
				.required('message.email_is_required')
				.email('message.enter_valid_email'),
		}),
	})

	const debouncedValue = useDebounce<string>(formik.values.companyName, 500)

	const [companyExistQuery] = useLazyQuery<ICompanyExist>(
		AuthQueries.COMPANY_EXISTS,
		{
			onCompleted(data) {
				if (data.companyExists) {
					formik.setFieldError('companyName', t('message.company_exists'))
				}
			},
		}
	)

	useEffect(() => {
		companyExistQuery({
			variables: {
				company: debouncedValue,
			},
		})
	}, [debouncedValue])

	const termsOfUse = t('api.terms_of_use')
	const privacyPolicy = t('api.privacy_policy')
	const login = t('api.login')

	return (
		<>
			<div className='h-full w-full p-6 md:p-12 flex flex-col gap-5 max-h-[80%]'>
				<div className='flex justify-between items-end'>
					<h1 className='text-2xl font-semibold'>{t('api.signup')}</h1>
					<div className='flex gap-2 items-center'>
						<Translation />
						{/* <Link className='text-lg underline' to='/help'>
							{t('api.help')}
						</Link> */}
					</div>
				</div>
				<form onSubmit={formik.handleSubmit}>
					<div className='w-full grid grid-cols-1 md:grid-cols-2 gap-4'>
						<div className='flex flex-col gap-4'>
							<label htmlFor='companyName' className='font-semibold text-xl'>
								{t('fields.company_data')}
							</label>
							<Input
								type='text'
								value={formik.values.companyName}
								name='companyName'
								placeholder={t('fields.enter_company_name')}
								label={t('fields.enter_company_name')}
								onChange={formik.handleChange}
								errorMessage={
									formik.touched.companyName ? formik.errors.companyName : ''
								}
							/>
							<div className='flex gap-4'>
								<Input
									type='text'
									value={formik.values.streetName}
									name='streetName'
									placeholder={t('fields.enter_street_name')}
									label={t('fields.enter_street_name')}
									onChange={formik.handleChange}
									errorMessage={
										formik.touched.streetName ? formik.errors.streetName : ''
									}
								/>
								<Input
									type='text'
									value={formik.values.streetNumber}
									name='streetNumber'
									placeholder={t('fields.enter_street_number')}
									label={t('fields.enter_street_number')}
									onChange={formik.handleChange}
									errorMessage={
										formik.touched.streetNumber ? formik.errors.streetNumber : ''
									}
								/>
							</div>
							<div className='flex gap-4'>
								<Input
									type='text'
									value={formik.values.postalCode}
									name='postalCode'
									placeholder={t('fields.enter_postal_code')}
									label={t('fields.enter_postal_code')}
									onChange={formik.handleChange}
									errorMessage={
										formik.touched.postalCode ? formik.errors.postalCode : ''
									}
								/>
								<Input
									type='text'
									value={formik.values.city}
									name='city'
									placeholder={t('fields.enter_city')}
									label={t('fields.enter_city')}
									onChange={formik.handleChange}
									errorMessage={formik.touched.city ? formik.errors.city : ''}
								/>
							</div>
							{
								countryOptions && <MultiSelect
									variant='single'
									options={countryOptions}
									name='countryName'
									showSearch
									value={formik.values.countryName}
									placeHolder={t('fields.enter_country')}
									label={t('fields.enter_country')}
									onChange={value => formik.setFieldValue('countryName', value)}
									errorMessage={
										formik.touched.countryName ? formik.errors.countryName : ''
									}
								/>
							}
							<Input
								type='text'
								value={formik.values.vatNumber}
								name='vatNumber'
								placeholder={t('fields.enter_vat_number')}
								label={t('fields.enter_vat_number')}
								onChange={formik.handleChange}
								errorMessage={formik.touched.vatNumber ? formik.errors.vatNumber : ''}
							/>
							<Input
								type='text'
								value={formik.values.chamberOfCommerceNumber}
								name='chamberOfCommerceNumber'
								placeholder={t('fields.enter_chamber_of_commerce_number')}
								label={t('fields.enter_chamber_of_commerce_number')}
								onChange={formik.handleChange}
								errorMessage={
									formik.touched.chamberOfCommerceNumber
										? formik.errors.chamberOfCommerceNumber
										: ''
								}
							/>
						</div>
						<div className='flex flex-col justify-between gap-4'>
							<div className='flex flex-col gap-4'>
								<label htmlFor='firstName' className='font-semibold text-xl'>
									{t('fields.contact_person_detail')}
								</label>
								<Input
									type='text'
									value={formik.values.firstName}
									name='firstName'
									placeholder={t('fields.enter_first_name')}
									label={t('fields.enter_first_name')}
									onChange={formik.handleChange}
									errorMessage={formik.touched.firstName ? formik.errors.firstName : ''}
								/>
								<Input
									type='text'
									value={formik.values.lastName}
									name='lastName'
									placeholder={t('fields.enter_last_name')}
									label={t('fields.enter_last_name')}
									onChange={formik.handleChange}
									errorMessage={formik.touched.lastName ? formik.errors.lastName : ''}
								/>
								{/* <PhoneInputComponent
									placeHolder={t('fields.enter_company_phone_number')}
									label={t('fields.enter_company_phone_number')}
									value={formik.values.phoneNumber}
									onChange={value => formik.setFieldValue('phoneNumber', value, true)}
									name='phoneNumber'
								/> */}
								<PhoneInputComponent
									value={formik.values.mobileNumber}
									placeHolder={t('fields.enter_personal_mobile_number')}
									label={t('fields.enter_personal_mobile_number')}
									onChange={value => formik.setFieldValue('mobileNumber', value, true)}
									name='mobileNumber'
									errorMessage={
										formik.touched.mobileNumber ? formik.errors.mobileNumber : ''
									}
								/>
							</div>
							<div className='flex flex-col gap-4'>
								<label htmlFor='firstName' className='font-semibold text-xl'>
									{t('fields.account_detail')}
								</label>
								<Input
									type='email'
									value={formik.values.email}
									name='email'
									placeholder={t('fields.enter_email')}
									label={t('fields.enter_email')}
									onChange={formik.handleChange}
									errorMessage={formik.touched.email ? formik.errors.email : ''}
								/>
							</div>
						</div>
					</div>
					<div className='w-full mt-4 gap-4 flex flex-col justify-center items-center'>
						<div className='flex gap-2 justify-center items-center'>
							<Checkbox
								name='agreeTermsAndCondition'
								checked={formik.values.agreeTermsAndCondition}
								label={
									<Trans
										i18nKey='api.accept_terms_and_condition'
										values={{ termsOfUse, privacyPolicy }}
										components={{
											1: (
												<button
													type='button'
													className='text-secondary font-semibold'
													onClick={e => {
														e.stopPropagation()
														setOpenTermsAndConditionModal(true)
													}}
												/>
											),
											2: (
												<button
													type='button'
													className='text-secondary font-semibold'
													onClick={e => {
														e.stopPropagation()
														setOpenPrivacyPolicyModal(true)
													}}
												/>
											),
										}}
									/>
								}
								onChange={() =>
									formik.setFieldValue(
										'agreeTermsAndCondition',
										!formik.values.agreeTermsAndCondition
									)
								}
								errorMessage={
									formik.touched.agreeTermsAndCondition
										? formik.errors.agreeTermsAndCondition
										: ''
								}
							/>
						</div>
						<button
							type='submit'
							className='btn w-60 btn-primary rounded-md place-content-center'
						>
							{loading ? <ButtonLoader /> : t('api.signup')}
						</button>
						<p className='font-bold'>
							<Trans
								i18nKey='fields.already_logged_in_login'
								values={{ login }}
								components={{ 1: <Link className='text-secondary' to='/auth/login' /> }}
							/>
						</p>
					</div>
				</form>
			</div>
			{openTermsAndConditionModal && (
				<AlertModal
					okOnClick={() => setOpenTermsAndConditionModal(false)}
					fullSize
					noCancel
				>
					<TermAndCondition />
				</AlertModal>
			)}
			{openPrivacyPolicyModal && (
				<AlertModal
					okOnClick={() => setOpenPrivacyPolicyModal(false)}
					fullSize
					noCancel
				>
					<PrivacyPolicy />
				</AlertModal>
			)}
		</>
	)
}

export default Signup
