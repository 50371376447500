import { ApolloClient, from, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { createUploadLink } from 'apollo-upload-client'
import { onError } from '@apollo/client/link/error'
import { toast } from 'react-toastify'

const REACT_APP_HTTP_GRAPHQL_API_URL =
	process.env.REACT_APP_HTTP_GRAPHQL_API_URL ||

	'https://api.machmachinery.com/graphql'

const httpLinkWithUpload = from([
	createUploadLink({ uri: REACT_APP_HTTP_GRAPHQL_API_URL }),
])

const authLink = setContext((_, { headers }) => {
	const token = localStorage.getItem('TOKEN') || ''
	const language = localStorage.getItem('i18nextLng') || ''
	return {
		headers: {
			...headers,
			'Accept-Language': language.toLowerCase(),
			authorization: token ? `Bearer ${token}` : '',
		},
	}
})

const errorLink = onError(({ graphQLErrors }) => {
	if (graphQLErrors) {
		graphQLErrors.forEach(error => {
			if (error.message && error.message === 'Unauthenticated.') {
				localStorage.removeItem('USER')
				localStorage.removeItem('TOKEN')
				window.location.href = '/'
				toast.error('message.token_expired_logging_out')
			}
		})
	}
})

const client = new ApolloClient({
	link: from([errorLink, authLink, httpLinkWithUpload]),
	cache: new InMemoryCache(),
})

export default client
