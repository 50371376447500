import { useMutation, useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { FormikProps, useFormik } from 'formik'
import { toast } from 'react-toastify'

import { IUserFormData } from '../../../graphql/model'
import Input from '../../../../../shared/components/Input'
import { UPDATE_USER_DETAIL } from '../../../graphql/mutation'
import { useAuth } from '../../../../auth/context/useAuth'
import { IUserData } from '../../../../admin/views/UserManagement/graphql/model'
import { FIND_USER_BY_ID } from '../../../../admin/views/UserManagement/graphql/query'
import Loader from '../../../../../shared/components/loader'
import { IUserDetails } from '../../../../auth/graphql/model'

const UserDetail = () => {
	const { t } = useTranslation()
	const { hasUserDetail, user, setUser, setHasUserDetail } = useAuth()
	const [isDisabled, setIsDisabled] = useState<boolean>(true)

	useEffect(() => {
		if (hasUserDetail) {
			setIsDisabled(true)
		} else {
			setIsDisabled(false)
		}
	}, [hasUserDetail])

	const { loading: userDataLoading } = useQuery<IUserData>(FIND_USER_BY_ID, {
			skip: !user.id,
			fetchPolicy: 'network-only',
			variables: {
				id: user.id
			},
			onCompleted: data => {
				if (data.findUserById && data.findUserById.user_details) {
					const tempUser = user
					const tempUserDetail: IUserDetails = {
						id: data.findUserById?.user_details.id,
						first_name: data.findUserById?.user_details.first_name,
						last_name: data.findUserById?.user_details.last_name,
						mobile_number: data.findUserById?.user_details.mobile_number,
						phone_number: data.findUserById?.user_details.phone_number
					}
					tempUser.user_details = tempUserDetail
					setUser && setUser(tempUser)
					if(tempUserDetail.first_name && tempUserDetail.last_name && tempUserDetail.mobile_number){
						setHasUserDetail && setHasUserDetail(true)
					}
					localStorage.setItem('USER', JSON.stringify(tempUser))
				} else {
					toast.error(t('message.something_went_wrong'))
				}
			}
		}
	)

	const [updateUserDetail, { loading: updateLoading }] = useMutation(
		UPDATE_USER_DETAIL,
		{
			refetchQueries: [{ query: FIND_USER_BY_ID }],
			async onCompleted() {
				toast.success(`${t('message.user_update_success')}`)
				setIsDisabled(true)
			},
			onError(error) {
				toast.error(error.message || `${t('message.user_update_failed')}`)
				setIsDisabled(true)
			}
		}
	)

	const formik: FormikProps<IUserFormData> = useFormik<IUserFormData>({
		initialValues: {
			id: user.id || 0,
			email: user.email || '',
			firstName: user.user_details?.first_name || '',
			lastName: user.user_details?.last_name || '',
			mobileNumber: user.user_details?.mobile_number || ''
		},
		onSubmit: async values => {
			await updateUserDetail({
				variables: {
					id: values.id,
					firstName: values.firstName,
					lastName: values.lastName,
					mobileNumber: values.mobileNumber,
					email: values.email
				}
			})
		},
		validationSchema: Yup.object().shape({
			firstName: Yup.string().required('message.firstname_is_required'),
			lastName: Yup.string().required('message.lastname_is_required'),
			mobileNumber: Yup.string().required('message.mobile_number_is_required'),
			email: Yup.string().required('message.email_is_required')
		})
	})

	return (
		userDataLoading ? <Loader /> : <div className='flex justify-center items-start flex-col'>
			<form onSubmit={formik.handleSubmit} className='w-full'>
				<div className='flex flex-col gap-6'>
					<div className='flex flex-col md:flex-row gap-4'>
						<Input
							type='text'
							value={formik.values.firstName}
							name='firstName'
							placeholder={t('fields.enter_first_name')}
							label={t('fields.first_name')}
							onChange={formik.handleChange}
							errorMessage={formik.touched.firstName ? formik.errors.firstName : ''}
							disabled={isDisabled}
						/>
						<Input
							type='text'
							value={formik.values.lastName}
							name='lastName'
							placeholder={t('fields.enter_last_name')}
							label={t('fields.last_name')}
							onChange={formik.handleChange}
							errorMessage={formik.touched.lastName ? formik.errors.lastName : ''}
							disabled={isDisabled}
						/>
					</div>
					<div className='flex flex-col md:flex-row gap-4'>
						<Input
							type='text'
							value={formik.values.email}
							name='email'
							placeholder={t('fields.enter_email')}
							label={t('fields.email')}
							onChange={formik.handleChange}
							errorMessage={formik.touched.email ? formik.errors.email : ''}
							disabled={isDisabled}
						/>
						<Input
							type='text'
							value={formik.values.mobileNumber}
							name='mobileNumber'
							placeholder={t('fields.enter_mobile_number')}
							label={t('fields.mobile_number')}
							onChange={formik.handleChange}
							errorMessage={
								formik.touched.mobileNumber ? formik.errors.mobileNumber : ''
							}
							disabled={isDisabled}
						/>
					</div>
				</div>
				<div className='w-full mt-4 gap-4 flex justify-end items-center'>
					{!isDisabled && (
						<>
							<button
								type='button'
								onClick={() => setIsDisabled(true)}
								className='btn min-w-[8rem] btn-secondary rounded-md place-content-center'
							>
								{t('api.cancel')}
							</button>
							<button
								type='submit'
								className={`btn min-w-[8rem] btn-primary rounded-md place-content-center ${updateLoading ? 'loading btn-disabled' : ''}`}
							>
								{t('api.update')}
							</button>
						</>
					)}
					{isDisabled && (
						<button
							type='button'
							className='btn min-w-[8rem] btn-primary rounded-md place-content-center'
							onClick={() => setIsDisabled(false)}
						>
							{t('api.edit')}
						</button>
					)}
				</div>
			</form>
		</div>
	)
}

export default UserDetail
