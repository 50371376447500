import { ReactElement } from 'react'

interface IUserProfileMenuItemProps {
	icon: ReactElement
	label: string
	onClickHandler?: () => void
}

const UserProfileMenuItem = (props: IUserProfileMenuItemProps) => {
	const { icon, label, onClickHandler } = props

	return (
		// eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
		<li
			className='border-b-neutral hover:bg-primary flex flex-row gap-1 flex-nowrap items-center hover:text-base-100 px-4'
			onClick={() => onClickHandler && onClickHandler()}
		>
			<span>{icon}</span>
			<p>{label}</p>
		</li>
	)
}

export default UserProfileMenuItem
