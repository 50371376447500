import { useTranslation } from 'react-i18next'
import { IOptions } from '../Select'
import Tag from './component/Tag'

interface ITagsProps {
	tags: IOptions[]
	label?: string
	labelStyles?: string
	selectTagIds: number[]
	translatable?: boolean
	translatableGroup?: string
	onChange?: (tagIds: number[]) => void
}

const Tags = (props: ITagsProps) => {
	const { t } = useTranslation()

	const { label, labelStyles, tags, translatable, translatableGroup, selectTagIds, onChange } = props

	const defaultLabelStyle = 'font-semibold '

	const handleChange = (id: number) => {
		let tempSelectTagsId = selectTagIds
		if (tempSelectTagsId.includes(id)) {
			tempSelectTagsId = tempSelectTagsId.filter(selectTag => selectTag !== id)
			onChange && onChange(tempSelectTagsId)
		} else {
			onChange && onChange([...selectTagIds, id])
		}
	}

	return (
		<div className='flex flex-col gap-1 w-full'>
			{label && (
				<div className={`${defaultLabelStyle} ${labelStyles || ''}`}>{label}</div>
			)}
			<div className='flex gap-2 flex-wrap items-center'>
				{tags.map((tag, index) => (
					<Tag
						key={index}
						label={t(tag.label)}
						value={translatable ? t(`${translatableGroup}.${tag.value}`) : tag.value}
						onClick={e => {
							handleChange(parseInt(tag.value.toString(), 10))
							e.preventDefault()
						}}
						checked={
							selectTagIds && selectTagIds.includes(parseInt(tag.value.toString(), 10))
						}
					/>
				))}
			</div>
		</div>
	)
}

export default Tags
