import { useLocation } from 'react-router-dom'
import UserRoute from './routes'
import Header from './components/UserHeader'

const User = () => {
	const location = useLocation()
	const isLandingPage = location.pathname.includes('landing')
	return (
		<div className='h-full w-full flex flex-col'>
			<div className='h-12 md:h-[82px] z-20 bg-white sticky top-0 border-b-2'>
				<Header />
			</div>
			<div className='h-[calc(96vh-3rem)] md:h-[calc(96vh-82px)] overflow-auto w-full relative'>
				<div className={isLandingPage ? '' : 'h-fit container mx-auto px-2 md:p-0'}>
					<UserRoute />
				</div>
			</div>
		</div>
	)
}

export default User
