/* eslint-disable camelcase */
import moment from 'moment'
import i18n from '../plugins/i18n'
import STATUS from '../enums/STATUS'
import { currencyFormatter } from './currencyFormatter'

const dateFormat = 'MMM-DD-YYYY'

const generateMachineDescription = (machine: any) => {
	const {
		machine_type,
		year_of_manufacture,
		licence_plate,
		valid_until,
		running_hours,
		availability_date,
		min_bid_amount,
		status,
	} = machine

	const description = [
		{
			label: i18n.t('api.machine_type'),
			description: machine_type
				? i18n.t(`${machine_type}`)
				: i18n.t('api.not_available'),
		},
		{
			label: i18n.t('api.year_of_manufacture'),
			description: year_of_manufacture
				? moment(year_of_manufacture).format('yyyy')
				: i18n.t('api.not_available'),
		},
		{
			label: i18n.t('fields.licence_plate'),
			description: String(licence_plate) || i18n.t('api.not_available'),
		},
		{
			label: i18n.t('fields.running_hours'),
			description: running_hours
				? `${String(running_hours)} hrs`
				: i18n.t('api.not_available'),
		},
		{
			label: i18n.t(
				status === STATUS.AUCTION ? 'api.auction_date' : 'api.availability_date'
			),
			description:
				availability_date || valid_until
					? moment(
							status === STATUS.AUCTION ? valid_until : availability_date
					  ).format(dateFormat)
					: i18n.t('api.not_available'),
		},
	]

	if(min_bid_amount) {
		description.push({
			label: i18n.t('fields.min_bid_amount'),
			description: min_bid_amount
				? currencyFormatter(min_bid_amount)
				: i18n.t('api.not_available'),
		})
	}

	return description
}

export default generateMachineDescription
