import React from 'react';
import { NavLink } from "react-router-dom"

interface ScrollNavLinkProps {
	to: string
	children: React.ReactNode
	[key: string]: any
}

const ScrollNavLink: React.FC<ScrollNavLinkProps> = ({
	to,
	children,
	...props
}) => {
	const handleClick = (
		event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
	) => {
		event.preventDefault()
		const target = document.querySelector(to)
		if (target) {
			target.scrollIntoView({ behavior: 'smooth' })
		}
	}

	return (
		<NavLink to={to} className='uppercase' onClick={handleClick} {...props}>
			{children}
		</NavLink>
	)
}

export default ScrollNavLink
