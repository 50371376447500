/* eslint-disable camelcase */
import { useQuery } from '@apollo/client'
import { BidProvider, useBidContext } from 'context/useBidContext'
import { t } from 'i18next'
import { FIND_BID_BY_MACHINE_ID_WITH_AUTH } from 'modules/admin/views/Machine/views/MachineUpload/graphql/query'
import { useAuth } from 'modules/auth/context/useAuth'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Input from 'shared/components/Input'
import Loader from 'shared/components/loader'

import moment from 'moment-timezone'
import { IBid, IBidData } from '../../admin/views/Machine/graphql/model'
import { IMachineOverviewParams } from '../graphql/model'
import MachineBidWithCommission from './MachineBidWithCommission'
import BID_STATUS from '../../../enums/BidStatus'

interface MachineBidFormProps {
	machineId: number
	machineBid?: IBid[]
	isCard?: boolean
	queryParams?: IMachineOverviewParams
	scrollToView?: () => void
}

const MachineBidForm = (props: MachineBidFormProps) => {
	const { machineId, machineBid, isCard, queryParams, scrollToView } = props
	const {
		user: { id, can_place_bid, company },
	} = useAuth()
	const [bidPrice, setBidPrice] = useState<number>(0)
	const [validUntil, setValidUntil] = useState<string>()
	const [bidStatus, setBidStatus] = useState<BID_STATUS>()
	const [isBidTimeExpired, setIsBidTimeExpired] = useState<boolean>(false)
	const [alreadyBided, setAlreadyBided] = useState<boolean>(false)
	const [bidBySomeOneElse, setBySomeOneElse] = useState(false)
	const [error, setError] = useState<string>('')

	const {
		placeBidAction,
		isRefreshRequired,
		setIsRefreshRequired,
		bid,
		calculateCommission,
	} = useBidContext()

	const navigate = useNavigate()

	useEffect(() => {
		if (
			validUntil &&
			!(
				bidStatus === BID_STATUS.STATUS_ACCEPTED ||
				bidStatus === BID_STATUS.STATUS_BIDDED ||
				bidStatus === BID_STATUS.STATUS_REJECTED
			)
		) {
			const currentTimeInServer = moment.tz('Europe/Amsterdam')
			const currentValidUntilDate = moment(validUntil).tz('Europe/Amsterdam')
			if (currentValidUntilDate.isBefore(currentTimeInServer, 'hour')) {
				setIsBidTimeExpired(true)
			}
		}
	}, [validUntil])

	const { loading, refetch } = useQuery<IBidData>(
		FIND_BID_BY_MACHINE_ID_WITH_AUTH,
		{
			variables: {
				machine_id: parseInt(`${machineId}`, 10),
			},
			onCompleted: data => {
				data?.findBidByMachineIdWithAuth &&
					data?.findBidByMachineIdWithAuth.valid_until &&
					setValidUntil(data?.findBidByMachineIdWithAuth.valid_until)
				data?.findBidByMachineIdWithAuth &&
					data?.findBidByMachineIdWithAuth.status &&
					setBidStatus(data?.findBidByMachineIdWithAuth.status)
				if (
					data?.findBidByMachineIdWithAuth !== null &&
					data?.findBidByMachineIdWithAuth?.value
				) {
					setBySomeOneElse(
						Boolean(
							data?.findBidByMachineIdWithAuth?.user?.company?.id === company.id &&
								data?.findBidByMachineIdWithAuth?.user?.id !== id
						)
					)
					setBidPrice(data?.findBidByMachineIdWithAuth?.value)
					setAlreadyBided(true)
				} else {
					setAlreadyBided(false)
				}
			},
			onError: error => {
				toast.error(
					'some went wrong, failed to fetch machine data' || error.message
				)
				setAlreadyBided(false)
			},
		}
	)

	const handlePlaceBid = async () => {
		// not needed for now
		// const bidAmount = bidPrice === 0 ? bid.bidValue : bidPrice
		// if (bidAmount < parseFloat(String(minBidAmount))) {
		// 	toast.error(
		// 		t('message.bid_must_be_more_than') + currencyFormatter(parseFloat(String(minBidAmount)))
		// 	)
		// 	return
		// }
		if (bidPrice === 0 && bid.bidValue === 0) {
			setError(t('message.bid_price_cannot_be_zero'))
		} else {
			placeBidAction({
				id: machineId,
				amount: bidPrice === 0 ? bid.bidValue : bidPrice,
			})
			await calculateCommission({
				variables: {
					machine_id: machineId,
					value: bidPrice,
				},
			})
			if (isCard && queryParams) {
				navigate({
					pathname: `/bidded-machine/${machineId}`,
					search: `?categories=${queryParams.categories || ''}&type=${
						queryParams.type || ''
					}&subcategory=${queryParams.subCategories || ''}&source=${
						queryParams.source || ''
					}&page=${queryParams.page || ''}&status=${
						queryParams.status || ''
					}&bid-status=${queryParams.bidStatus || ''}
					&auction-date=${queryParams.auctionDay || ''}
					`,
				})
			}
		}
	}

	const handleRefetch = async () => {
		await refetch({
			machine_id: parseInt(`${machineId}`, 10),
		})
		setIsRefreshRequired(false)
	}

	useEffect(() => {
		if (machineBid && machineBid.length > 0) {
			setBidPrice(machineBid[0].value)
		}
	}, [machineBid])

	useEffect(() => {
		if (isRefreshRequired && (bid.machineId !== 0 || bid.machineId !== null)) {
			handleRefetch()
		}
	}, [isRefreshRequired])

	if (loading && can_place_bid) {
		return <Loader />
	}

	if (!can_place_bid) {
		return <p>{t('api.user_cannot_bid')}</p>
	}

	if (alreadyBided && !isCard) {
		return (
			<div className='flex flex-col gap-2'>
				{bidBySomeOneElse && !isCard ? (
					<div className=' rounded-lg p-2 w-full border flex items-center justify-between mb-4'>
						<div className='flex items-center gap-4'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								fill='none'
								viewBox='0 0 24 24'
								className='stroke-info flex-shrink-0 w-6 h-6'
							>
								<path
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeWidth='2'
									d='M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
								/>
							</svg>
							<span>{t('api.machine_bid_by_other')}</span>
						</div>
					</div>
				) : null}
				<MachineBidWithCommission machineId={machineId} bidAmount={bidPrice} />
			</div>
		)
	}

	return (
		<BidProvider>
			<div className='flex flex-col gap-2 w-full'>
				{bidBySomeOneElse && !isCard ? (
					<div className=' rounded-lg p-2 w-full border flex items-center justify-between mb-4'>
						<div className='flex items-center gap-4'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								fill='none'
								viewBox='0 0 24 24'
								className='stroke-info flex-shrink-0 w-6 h-6'
							>
								<path
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeWidth='2'
									d='M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
								/>
							</svg>
							<span>{t('api.machine_bid_accepted')}</span>
						</div>
					</div>
				) : null}
				{isBidTimeExpired ? (
					<p className='text-center w-full'>{t('api.bid_time_expired')}</p>
				) : (
					<Input
						type='number'
						value={bidPrice === 0 && !isCard ? bid.bidValue : bidPrice}
						inputStyles='text-right'
						rightContent={<p className='text-gray-500 font-bold'>€</p>}
						placeholder='0.00'
						disabled={alreadyBided}
						onChange={event => {
							if (setBidPrice) {
								setBidPrice(event.target.valueAsNumber)
								setError('')
							}
						}}
						name='Price'
						errorMessage={error}
					/>
				)}
				<button
					type='button'
					className='btn btn-primary btn-sm w-full'
					disabled={alreadyBided || isBidTimeExpired}
					onClick={() => {
						handlePlaceBid()
					    scrollToView &&	scrollToView()
					}}
				>
					{t('api.place_bid')}
				</button>
			</div>
		</BidProvider>
	)
}

export default MachineBidForm
