import { useMutation, useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { FormikProps, useFormik } from 'formik'
import { toast } from 'react-toastify'

import countries from 'i18n-iso-countries'
import Loader from '../../../../../shared/components/loader'
import { ICompany, ICompanyData, ICompanyFormData } from '../../../graphql/model'
import { FIND_COMPANY_BY_ID } from '../../../graphql/query'
import { useAuth } from '../../../../auth/context/useAuth'
import Input from '../../../../../shared/components/Input'
import MultiSelect from '../../../../../shared/components/MultiSelect'
import FileUpload from '../../../../../shared/components/FileUpload'
import { UPDATE_COMPANY } from '../../../graphql/mutation'
import { IOptions } from '../../../../../shared/components/Select'
import AlertModal from '../../../../../shared/AlertModal'
import { ENABLE_DISABLE_COMPANY } from '../../../../admin/views/CompanyManagement/graphql/mutation'
import i18n from '../../../../../plugins/i18n'

interface IComapnyDetailProps {
	isAdminRoute?: boolean
	companyId?: number | undefined | string
}

const CompanyDetail = (props: IComapnyDetailProps) => {
	const { isAdminRoute = false, companyId } = props
	const { t } = useTranslation()
	const { user } = useAuth()
	const [isDisabled, setIsDisabled] = useState<boolean>(true)
	const [files, setFiles] = useState<any>([])
	const imageLocation = process.env.REACT_APP_IMAGE_LOCATION || ''
	const [isenabled, setIsEnabled] = useState<number>(0)
	const [showConfimationModal, setConfimationModal] = useState<boolean>(false)

	const [countryOptions, setCountryOptions] = useState<IOptions[]>()

	useEffect(() => {
		const countryObj = countries.getNames(i18n.language === 'en-US' ? 'en' : i18n.language === 'nl-NL' ? 'nl' : i18n.language || 'en', { select: "official" })
		setCountryOptions(
			Object.entries(countryObj).map(([key,value]) => ({
				label: value,
				value: key
			}))
		)
	}, [i18n.language])

	const isEnabledOptions: IOptions[] = [
		{
			label: 'Yes',
			value: 1,
		},
		{
			label: 'No',
			value: 0,
		},
	]

	const [updateCompany, { loading: updateLoading }] = useMutation(
		UPDATE_COMPANY,
		{
			refetchQueries: [
				{ query: FIND_COMPANY_BY_ID, variables: { id: user.company.id } },
			],
			async onCompleted() {
				toast.success(`${t('message.company_edit_success')}`)
			},
			onError(error) {
				toast.error(error.message || `${t('message.category_update_failed')}`)
			},
		}
	)

	const [updateEnableDisableCompany] = useMutation(ENABLE_DISABLE_COMPANY, {
		refetchQueries: [
			{ query: FIND_COMPANY_BY_ID, variables: { id: user.company.id } },
		],
		async onCompleted() {
			toast.success(`${t('message.company_edit_success')}`)
		},
		onError(error) {
			toast.error(error.message || `${t('message.category_update_failed')}`)
		},
	})
	const onChangeHandler = (value: any) => {
		setIsEnabled(value)
	}

	const closeModelHandler = () => {
		setConfimationModal(false)
	}

	const onConfirmHandler = () => {
		setConfimationModal(false)
		updateEnableDisableCompany({
			variables: {
				id: companyId || user.company.id,
				value: isenabled,
			},
		})
	}

	const formik: FormikProps<ICompanyFormData> = useFormik<ICompanyFormData>({
		initialValues: {
			id: 0,
			companyName: '',
			countryName: '',
			chamberOfCommerceNumber: '',
			vatNumber: '',
			phoneNumber: '',
			city: '',
			streetName: '',
			streetNumber: '',
			postalCode: '',
			companyLogo: '',
		},

		onSubmit: async values => {
			setIsDisabled(true)
			await updateCompany({
				variables: {
					id: values.id,
					companyName: values.companyName,
					countryName: values.countryName,
					chamberOfCommerceNumber: values.chamberOfCommerceNumber,
					vatNumber: values.vatNumber,
					phoneNumber: values.phoneNumber,
					city: values.city,
					streetName: values.streetName,
					streetNumber: values.streetNumber,
					postalCode: values.postalCode,
					companyLogo: files[0],
				},
			})
		},
		validationSchema: Yup.object().shape({
			companyName: Yup.string().required('message.company_is_required'),
			chamberOfCommerceNumber: Yup.string().required(
				'message.chamber_of_commerce_number_is_required'
			),
			vatNumber: Yup.string().required('message.vat_number_is_required'),
			phoneNumber: Yup.string().required('message.telephone_is_required'),
			countryName: Yup.string().required('message.country_is_required'),
			city: Yup.string().required('message.city_is_required'),
			streetName: Yup.string().required('message.street_name_is_required'),
			streetNumber: Yup.string().required('message.street_number_is_required'),
			postalCode: Yup.string().required('message.postal_code_is_required'),
		}),
	})

	const setFormData = (company: ICompany) => {
		formik.setFieldValue('id', company.id)
		formik.setFieldValue('companyName', company.company_name)
		formik.setFieldValue('chamberOfCommerceNumber', company.chamber_of_commerce)
		formik.setFieldValue('vatNumber', company.vat_number)
		formik.setFieldValue('phoneNumber', company.phone_number)
		formik.setFieldValue('countryName', company.country_name)
		formik.setFieldValue('city', company.city)
		formik.setFieldValue('streetName', company.street_name)
		formik.setFieldValue('streetNumber', company.street_number)
		formik.setFieldValue('postalCode', company.postal_code)
		formik.setFieldValue('companyLogo', company.company_logo)
	}

	const { loading } = useQuery<ICompanyData>(FIND_COMPANY_BY_ID, {
		variables: { id: companyId || user.company.id },
		skip: !(companyId || (user && user.company && user.company.id)),
		fetchPolicy: 'no-cache',
		onCompleted: data => {
			if (data && data.findCompanyById && data.findCompanyById.length) {
				const company = data.findCompanyById[0]
				setFormData(company)
				setIsDisabled(true)
				setFiles([])
			}
		},
	})

	useEffect(() => {
		if (files && files.length > 0) {
			setIsDisabled(false)
		}
	}, files)

	return (
		<>
			{loading && <Loader />}
			{!loading && (
				<div className='flex h-full justify-center items-start flex-col'>
					<form onSubmit={formik.handleSubmit} className='w-full h-full overflow-auto px-2'>
						<div className='w-full flex flex-col-reverse md:flex-row justify-between items-start'>
							<div className='flex flex-col gap-6 w-full md:w-8/12'>
								<Input
									type='text'
									value={formik.values.companyName}
									name='companyName'
									placeholder={t('fields.enter_company_name')}
									onChange={formik.handleChange}
									label={t('fields.company_name')}
									errorMessage={
										formik.touched.countryName ? formik.errors.companyName : ''
									}
									disabled={isDisabled}
								/>
								<MultiSelect
									variant='single'
									options={countryOptions || []}
									name='countryName'
									showSearch
									label={t('fields.country')}
									value={formik.values.countryName}
									placeHolder={t('fields.enter_country')}
									onChange={value => formik.setFieldValue('countryName', value)}
									errorMessage={
										formik.touched.countryName ? formik.errors.countryName : ''
									}
									disabled={isDisabled}
								/>
								<div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
									<Input
										type='text'
										value={formik.values.city}
										name='city'
										placeholder={t('fields.enter_city')}
										label={t('fields.city')}
										onChange={formik.handleChange}
										errorMessage={formik.touched.city ? formik.errors.city : ''}
										disabled={isDisabled}
									/>
									<Input
										type='text'
										value={formik.values.streetName}
										name='streetName'
										placeholder={t('fields.enter_street_name')}
										label={t('fields.street_name')}
										onChange={formik.handleChange}
										errorMessage={
											formik.touched.streetName ? formik.errors.streetName : ''
										}
										disabled={isDisabled}
									/>
								</div>
								<div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
									<Input
										type='text'
										value={formik.values.streetNumber}
										name='streetNumber'
										placeholder={t('fields.enter_street_number')}
										label={t('fields.street_number')}
										onChange={formik.handleChange}
										errorMessage={
											formik.touched.streetNumber ? formik.errors.streetNumber : ''
										}
										disabled={isDisabled}
									/>
									<Input
										type='text'
										value={formik.values.postalCode}
										name='postalCode'
										placeholder={t('fields.enter_postal_code')}
										label={t('fields.postal_code')}
										onChange={formik.handleChange}
										errorMessage={
											formik.touched.postalCode ? formik.errors.postalCode : ''
										}
										disabled={isDisabled}
									/>
								</div>
								<div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
									<Input
										type='text'
										value={formik.values.vatNumber}
										name='vatNumber'
										placeholder={t('fields.enter_vat_number')}
										label={t('fields.vat_number')}
										onChange={formik.handleChange}
										errorMessage={formik.touched.vatNumber ? formik.errors.vatNumber : ''}
										disabled={isDisabled}
									/>
									<Input
										type='text'
										value={formik.values.chamberOfCommerceNumber}
										name='chamberOfCommerceNumber'
										placeholder={t('fields.enter_chamber_of_commerce_number')}
										label={t('fields.chamber_of_commerce_number')}
										onChange={formik.handleChange}
										errorMessage={
											formik.touched.chamberOfCommerceNumber
												? formik.errors.chamberOfCommerceNumber
												: ''
										}
										disabled={isDisabled}
									/>
								</div>
								{isAdminRoute && (
									<div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
										<MultiSelect
											options={isEnabledOptions}
											name='company_enable'
											value={isenabled}
											label={t('api.company_is_enabled')}
											variant='single'
											onChange={onChangeHandler}
										/>
									</div>
								)}
							</div>
							<div className='w-full md:w-4/12 px-5'>
								<p className='font-semibold font-avenirRegular  '>
									{t('fields.upload_company_logo')}
								</p>
								<div className='flex justify-center items-center p-5'>
									<img
										src={`${imageLocation}/${formik.values.companyLogo}`}
										alt='Category'
										className='h-28 w-36 object-cover'
									/>
								</div>
								{!isDisabled && (
									<FileUpload files={files} setFiles={setFiles} max={1} isSingleView/>
								)}
							</div>
						</div>
						<div className='w-full mt-4 gap-4 flex justify-end items-center'>
							{!isDisabled && (
								<>
									<button
										type='button'
										onClick={() => setIsDisabled(true)}
										className='btn min-w-[8rem] btn-secondary rounded-md place-content-center'
									>
										{t('api.cancel')}
									</button>
									<button
										type='submit'
										className={`btn min-w-[8rem] btn-primary rounded-md place-content-center ${updateLoading ? 'loading btn-disabled' : ''}`}
									>
										{t('api.update')}
									</button>
								</>
							)}
							{isDisabled && (
								<button
									type='button'
									className='btn min-w-[8rem] btn-primary rounded-md place-content-center'
									onClick={() => setIsDisabled(false)}
								>
									{t('api.edit')}
								</button>
							)}
						</div>
					</form>
					{showConfimationModal && (
						<AlertModal
							title={t('message.delete_translation_title')}
							description={t('message.delete_translation_description')}
							cancelOnClick={closeModelHandler}
							okOnClick={onConfirmHandler}
						/>
					)}
				</div>
			)}
		</>
	)
}

export default CompanyDetail
