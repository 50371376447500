import { useTranslation } from 'react-i18next'
import { IOptions } from '../../../../../../../shared/components/Select'
import MultiSelect from '../../../../../../../shared/components/MultiSelect'

export interface ISelectOption {
	key: string
	values: IOptions[] | any
}

interface IMachineDetailProps {
	formik: any
	selectOptions: ISelectOption[]
	getOptions: (
		field: string,
		attributeId?: string | null,
		fieldType?: string
	) => IOptions[]
	onChangeHandler: (
		value: string | number | null,
		field: string
	) => Promise<void>
}

const MachineDetail = (props: IMachineDetailProps) => {
	const { selectOptions, formik, getOptions, onChangeHandler } = props

	const { t } = useTranslation()

	return (
		<form
			className='w-full md:w-[760px] mx-auto flex flex-col gap-4 bg-white shadow-sm p-4'
			onSubmit={formik.handleSubmit}
		>
			{selectOptions && selectOptions.length > 0 && (
				<>
					<MultiSelect
						variant='single'
						options={getOptions('category')}
						name='category_id'
						label={t(`api.category_value`)}
						placeHolder={t(`api.category_value`)}
						value={formik.values.category_id ? formik.values.category_id : ''}
						onChange={value => onChangeHandler(value, 'category')}
						translatable
						translatableGroup='categories'
						// @ts-ignore
						errorMessage={formik.errors.category_id}
					/>
					<MultiSelect
						variant='single'
						options={getOptions('type')}
						name='type_id'
						label={t(`api.type_value`)}
						placeHolder={t(`api.type_value`)}
						translatable
						translatableGroup='type'
						value={formik.values.type_id ? formik.values.type_id : ''}
						onChange={value => onChangeHandler(value, 'type')}
						// @ts-ignore
						errorMessage={formik.errors.type_id}
					/>
					<MultiSelect
						variant='single'
						options={getOptions('subcategory')}
						name='subcategory_id'
						label={t(`api.subcategory_value`)}
						placeHolder={t(`api.subcategory_value`)}
						translatable
						translatableGroup='subcategory'
						value={formik.values.subcategory_id ? formik.values.subcategory_id : ''}
						onChange={value => onChangeHandler(value, 'subcategory')}
						// @ts-ignore
						errorMessage={formik.errors.subcategory_id}
					/>
				</>
			)}
			<div className='flex justify-end mt-4'>
				<button type='submit' className='btn btn-secondary btn-sm  w-24'>
					{t('api.next')}
				</button>
			</div>
		</form>
	)
}

export default MachineDetail
