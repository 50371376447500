import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import Auth from "../modules/auth";

const AuthRoutes = () => {

    const location = useLocation()

    // @ts-ignore
    const fromLocation = location.state ? location.state.from : location

    return (
        <Routes>
            <Route path='/*' element={<Auth/>}/>
            <Route
                path='*'
                element={<Navigate to='/' replace state={{ from: fromLocation}}/>}
            />
        </Routes>
    )
}

export default AuthRoutes
