import { AiFillStar, AiOutlineStar } from 'react-icons/ai'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import RATING from '../../enums/RATING'

interface IRatingProps {
	value: RATING
	style?: string
	showNumbers?: boolean
	disabled?: boolean
	setRating?: (rating: RATING) => void
	errorMessage?: string
}

interface IShowFilledOrOutlineStarProps {
	value: RATING
	rating: RATING
	style: string
	hoverIndex: RATING
	disabled?: boolean
}

const ShowFilledOrOutlineStar = (props: IShowFilledOrOutlineStarProps) => {
	const { value, rating, style, disabled, hoverIndex } = props
	if (!disabled && hoverIndex) {
		if (rating <= hoverIndex) {
			return <AiFillStar key={value} className={`${style}`} />
		}
		return <AiOutlineStar key={value} className={`${style}`} />
	}
	if (value >= rating) {
		return <AiFillStar key={value} className={`${style}`} />
	}
	return <AiOutlineStar key={value} className={`${style}`} />
}

const Rating = (props: IRatingProps) => {
	const { value, style, disabled, showNumbers, setRating, errorMessage } = props

	const ratingStart: RATING[] = [
		RATING.ONE,
		RATING.TWO,
		RATING.THREE,
		RATING.FOUR,
		RATING.FIVE,
	]
	const defaultStyle = 'text-3xl cursor-pointer'

	const [hoverIndex, setHoverIndex] = useState<RATING>(RATING.ONE)

	const { t } = useTranslation()

	return (
		<div>
			<div className='flex items-center'>
				{ratingStart.map((rating, index) => (
					<div
						key={index}
						aria-hidden
						onMouseEnter={() => setHoverIndex(rating)}
						onMouseLeave={() => setHoverIndex(RATING.ZERO)}
						onClick={() => setRating && !disabled && setRating(rating)}
					>
						<ShowFilledOrOutlineStar
							disabled={disabled}
							value={value}
							rating={rating}
							style={`${defaultStyle} ${style}`}
							hoverIndex={hoverIndex}
						/>
					</div>
				))}
				{showNumbers && ` (${value} / 5)`}
			</div>
			{errorMessage && <p className='text-sm text-error'>{t(errorMessage)}</p>}
		</div>
	)
}

export default Rating
