import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { IoCloseSharp } from 'react-icons/io5'
import { concat } from 'lodash'
import GridDragnDrop from './GridDragnDrop'

interface IFileUploadProps {
	files: any
	setFiles: (files: any) => void
	accept?: string
	max?: number
	isSingleView?: boolean
}

const ImageView = ({
	file,
	index,
	onDeleteHandler,
}: {
	file: any
	index: number
	onDeleteHandler: (index: number, file: any) => void
}) => (
	<div className='w-full h-full relative bg-white drop-shadow-lg rounded-md'>
		<img
			src={file.preview}
			className='h-full object-contain'
			alt={file.name}
			loading='lazy'
		/>
		<button
			type='button'
			title='Remove image from list'
			className='rounded-full p-1 bg-error absolute top-1 right-1 hover:brightness-125'
			onClick={() => onDeleteHandler(index, file)}
		>
			<IoCloseSharp className='text-white' />
		</button>
	</div>
)

const SingleView = ({
	file,
	onDeleteHandler,
}: {
	file: any
	onDeleteHandler: (index: number, file: any) => void
}) => (
	<div className='w-44 h-44'>
		<ImageView file={file} index={0} onDeleteHandler={onDeleteHandler} />
	</div>
)

const FileUpload = (props: IFileUploadProps) => {
	const { files, setFiles, accept, max = 20, isSingleView } = props
	const [rejectedFiles, setRejectedFiles] = useState<any>(null)

	const { getRootProps, getInputProps } = useDropzone({
		accept: accept || 'image/*',
		onDrop: (acceptedFiles, fileRejections) => {
			setRejectedFiles(fileRejections)
			setFiles(
				concat(
					files,
					acceptedFiles.map(file =>
						Object.assign(file, {
							preview: URL.createObjectURL(file),
						})
					)
				)
			)
		},
		maxFiles: max,
	})

	const onDeleteHandler = (index: number, file: any) => {
		const filtered = files.filter(
			(value: any, i: number) => file !== value && i !== index
		)
		setFiles(filtered)
	}

	const { t } = useTranslation()

	if (isSingleView && files.length === 1) {
		return <SingleView file={files[0]} onDeleteHandler={onDeleteHandler} />
	}

	return (
		<>
			<div
				{...getRootProps({
					className:
						'dropzone border-primary rounded border-2 border-dashed border-base-300 flex justify-center align-middle items-center files-center h-24',
				})}
			>
				<input {...getInputProps()} />
				<div>
					<p className='text-info text-center'>
						{t('api.drag_and_drop_or_click_to_upload_file')}
					</p>
					{rejectedFiles && rejectedFiles.length > 0 && (
						<p className='text-error'>
							{t('message.exceeds_upload_limit')} {max}
						</p>
					)}
				</div>
			</div>

			{files.length > 0 && (
				<GridDragnDrop list={files} setList={setFiles}>
					<>
						{files.map((file: any, index: number) => (
							<div className='p-2 w-44 h-44 relative' key={file.name + index}>
								<ImageView
									file={file}
									onDeleteHandler={onDeleteHandler}
									index={index}
								/>
							</div>
						))}
					</>
				</GridDragnDrop>
			)}
		</>
	)
}

export default FileUpload
