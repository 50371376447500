enum RATING {
	ZERO,
	ONE,
	TWO,
	THREE,
	FOUR,
	FIVE
}

export default RATING
