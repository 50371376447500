import { useState } from 'react'
import { useTranslation } from 'react-i18next'

interface ImageMagnifyProps {
	image: string
	s3Image: boolean
}

const zoomLevel = 1.5
const magnifier = {
	height: 250,
	width: 250,
}

const imageLocation = process.env.REACT_APP_S3_LOCATION || ''

const ImageMagnify = (props: ImageMagnifyProps) => {
	const { image, s3Image } = props
	const {t} = useTranslation()

	const [[x, y], setXY] = useState([0, 0])
	const [[imgWidth, imgHeight], setSize] = useState([0, 0])
	const [showMagnifier, setShowMagnifier] = useState(false)

	const handleMouseMove = (e: any): void => {
		const elem = e.currentTarget
		const { top, left } = elem.getBoundingClientRect()

		// calculate cursor position on the image
		const x = e.pageX - left - window.pageXOffset
		const y = e.pageY - top - window.pageYOffset
		setXY([x, y])
	}
	if (!image) {
		return (
			<div className='w-full h-full flex justify-center items-center'>
				<p>{t('api.no_image')}</p>
			</div>
		)
	}

	return (
		<div className='relative w-full h-full z-9 md:rounded-md'>
			<img
				src={s3Image ? `${imageLocation}/${image}` : image}
				alt={image}
				onMouseEnter={(e: any) => {
					const elem = e.currentTarget
					const { width, height } = elem.getBoundingClientRect()
					setSize([width, height])
					setShowMagnifier(true)
					e.stopPropagation()
				}}
				onMouseLeave={() => {
					setShowMagnifier(false)
				}}
				onMouseMove={(e: any) => handleMouseMove(e)}
				className='h-full w-full object-contain cursor-pointer md:rounded-md'
			/>
			<div
				className='hidden md:block'
				style={{
					display: showMagnifier ? '' : 'none',
					position: 'absolute',

					// prevent magnifier blocks the mousemove event of img
					pointerEvents: 'none',
					// set size of magnifier
					height: `${magnifier.height}px`,
					width: `${magnifier.width}px`,
					// move element center to cursor pos
					top: `${y - magnifier.height / 2}px`,
					left: `${x - magnifier.width / 2}px`,
					opacity: '1', // reduce opacity so you can verify position
					border: '1px solid lightgray',
					backgroundColor: 'white',
					backgroundImage: `url('${s3Image ? `${imageLocation}/${image}` : image}')`,
					backgroundRepeat: 'no-repeat',
					backgroundSize: `${imgWidth * zoomLevel}px ${imgHeight * zoomLevel}px`,
					backgroundPositionX: `${-x * zoomLevel + magnifier.width / 2}px`,
					backgroundPositionY: `${-y * zoomLevel + magnifier.height / 2}px`,
					zIndex: '99',
					objectFit: 'cover',
				}}
			/>
		</div>
	)
}

export default ImageMagnify
