import {Navigate, Route, Routes, useLocation} from 'react-router-dom'
import SuperAdmin from '../modules/admin'
import User from '../modules/user'
import Role from '../enums/Roles'

interface IPrivateRouteProps{
	role: Role
}

const PrivateRoute = ({role} : IPrivateRouteProps) => {
	const location = useLocation()
	const baseRoute = '/'

	// @ts-ignore
	const fromLocation = location.state ? location.state.from : location

	return (
		<Routes>
			<Route path='/auth/*' element={<Navigate to='/' replace state={{ from: fromLocation}}/>} />
			<Route
				path='/*'
				element={role !== Role.ADMIN ? <User /> : <Navigate to="/admin" replace state={{ from: fromLocation}}/>}
			/>
			{role === Role.ADMIN && (
				<Route path="/admin/*" element={<SuperAdmin />} />
			)}
			<Route path='*' element={<Navigate to={baseRoute} replace state={{ from: fromLocation}}/>} />
		</Routes>
	)
}

export default PrivateRoute
