import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import machLogo from 'assets/images/MACH_ICON-GRADIENT.png'
import mach from 'assets/images/logo.png'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from '../../../auth/context/useAuth'
import BackDrop from '../../../../shared/components/backDrop'
import './userNavigations.css'
import { BREAK_POINT } from '../../../../utils/constant'

interface INavigationMenuItemProps {
	link: string
	label: string
	setOpenNavBar: (value: boolean) => void
}

interface IUserNavigationProps {
	openNavBar: boolean
	setOpenNavBar: (value: boolean) => void
}

const NavigationMenuItem = (props: INavigationMenuItemProps) => {
	const { link, label, setOpenNavBar } = props
	const { t } = useTranslation()
	const { hasUserDetail } = useAuth()
	const navigate = useNavigate()
	const location = useLocation()

	const defaultStyle = `cursor-pointer uppercase md:text-xl`
	const activeStyle = `${defaultStyle} text-secondary`
	const notActiveStyle = `${defaultStyle} hover:text-primary hover:border-primary`

	const handleNavigationClick = (event: any) => {
		if (!hasUserDetail) {
			toast.error(t('message.first_fill_user_detail'))
			navigate('/settings/user')
			event.preventDefault()
		}
		setOpenNavBar(false)
	}

	return (
		<NavLink
			to={link}
			onClick={handleNavigationClick}
			className={({ isActive }) =>
				isActive || location.pathname === link ? activeStyle : notActiveStyle
			}
		>
			<p className=''>{label}</p>
		</NavLink>
	)
}

const UserNavigations = (props: IUserNavigationProps) => {
	const { openNavBar, setOpenNavBar } = props
	const { isUserLoggedIn } = useAuth()
	const { t } = useTranslation()
	const width = window.innerWidth
	
	const authorizedNavigationItem: INavigationMenuItemProps[] = [
		{
			label: t('api.add_machine'),
			setOpenNavBar,
			link: '/add-machine',
		},
		{
			label: t('api.home'),
			setOpenNavBar,
			link: '/auction',
		},
		{
			label: t('api.taxations'),
			setOpenNavBar,
			link: '/taxations',
		},
		{
			label: t('api.company_machine'),
			setOpenNavBar,
			link: '/company-machine',
		},
		{
			label: t('api.bidded_machine'),
			setOpenNavBar,
			link: '/bidded-machine',
		},
	]

	const unauthorizedNavigationItem: INavigationMenuItemProps[] = [
		{
			label: t('api.what_we_do'),
			setOpenNavBar,
			link: '#what_we_do',
		},
		{
			label: t('api.references'),
			setOpenNavBar,
			link: '#references',
		},
		{
			label: t('api.why'),
			setOpenNavBar,
			link: '#why',
		},
		{
			label: t('api.contact'),
			setOpenNavBar,
			link: '#contact',
		},
	]

	return (
		<>
			<BackDrop openNavBar={openNavBar} setBackDropStatus={setOpenNavBar} />
			<div
				className={`${
					width > BREAK_POINT
						? 'navbar-style'
						: `navbar-mobile-style ${openNavBar ? 'navbar-open' : 'navbar-close'}`
				}`}
			>
				<div className='flex lg:hidden'>
					<img
						src={machLogo}
						alt='Mach Machinery'
						className='h-5 w-5 xl:h-8 xl:w-8'
					/>
					<img src={mach} alt='Mach Machinery' className='h-4 xl:h-8 object-position-left' />
				</div>
				{isUserLoggedIn
					? authorizedNavigationItem.map((item, index) => (
							<NavigationMenuItem
								key={index}
								link={item.link}
								setOpenNavBar={item.setOpenNavBar}
								label={item.label}
							/>
					  ))
					: unauthorizedNavigationItem.map((item, index) => (
							<NavigationMenuItem
								key={index}
								link={item.link}
								setOpenNavBar={item.setOpenNavBar}
								label={item.label}
							/>
					  ))}
			</div>
		</>
	)
}

export default UserNavigations
