import { useLazyQuery } from '@apollo/client'
import { CALCULATE_COMMISSION } from 'modules/user/graphql/query'
import { createContext, ReactElement, useContext, useState } from 'react'
import { sumBy, sum } from 'lodash'

const bidContext = createContext<any>({})
const { Provider } = bidContext

const useBidContextProvider = () => {
	const [bidValue, setBidValue] = useState(0)
	const [machineId, setMachineId] = useState(0)
	const [isRefreshRequired, setIsRefreshRequired] = useState(false)
	const [commissionList, setCommissionList] = useState([])
	const [bidConfirmationScreen, setBidConfirmationScreen] =
		useState<boolean>(false)

	const placeBidAction = ({ id, amount }: { id: number; amount: number }) => {
		setMachineId(id)
		setBidValue(amount)
		setBidConfirmationScreen(true)
	}

	const clearBidState = () => {
		setBidValue(0)
		setMachineId(0)
		setBidConfirmationScreen(false)
	}

	const [calculateCommission] = useLazyQuery(CALCULATE_COMMISSION, {
		onCompleted: (data: any) => {
			setCommissionList(JSON.parse(data.allMachineCalculation || '[]'))
		},
		onError: () => {
			setCommissionList([])
		},
	})

	const getTotal = () => {
		const subTotal = sumBy(
			commissionList,
			(commission: { amount: number }) => commission.amount
		)
		const amount = sum([
			typeof bidValue === 'number' ? bidValue : parseFloat(bidValue),
			typeof subTotal === 'number' ? subTotal : parseFloat(subTotal),
		])
		return amount
	}

	return {
		bid: {
			bidValue,
			machineId,
			commission: commissionList,
			total: getTotal(),
		},
		bidConfirmationScreen,
		isRefreshRequired,
		setBidConfirmationScreen,
		placeBidAction,
		clearBidState,
		setIsRefreshRequired,
		calculateCommission,
	}
}

export const BidProvider = ({ children }: { children: ReactElement }) => {
	const data = useBidContextProvider()
	return <Provider value={data}>{children}</Provider>
}

export const useBidContext = () => useContext(bidContext)
