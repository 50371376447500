/* eslint-disable */
import machLogo from 'assets/images/MACH_ICON-GRADIENT.png'
import mach from 'assets/images/logo.png'
import machLogoMobile from 'assets/images/MACH.png'
import { Link, useNavigate } from 'react-router-dom'
import { MdExitToApp, MdSettings } from 'react-icons/md'
import { AiOutlineSecurityScan } from 'react-icons/ai'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import userImage from '../../../assets/images/user.png'
import UserProfileMenuItem from '../../../shared/components/UserProfileMenuItem'
import Translation from '../../translation'
import { useAuth } from '../../auth/context/useAuth'
import UserNavigations from './UserNavigations/UserNavigations'
import { GiHamburgerMenu } from 'react-icons/gi'
import ROLE from 'enums/Roles'

const getInitialLetter = (text: string) => {
	return text.charAt(0)
}
const Header = () => {
	const {
		logoutUser,
		role,
		user: { user_details, email, company },
	} = useAuth()
	const { t } = useTranslation()
	const navigate = useNavigate()

	const [openNavBar, setOpenNavBar] = useState<boolean>(false)

	return (
		<div className='w-full h-full container m-auto flex'>
			<Link
				to='landing'
				className='logo h-full hidden md:flex justify-center xl:justify-start items-center xl:py-4 cursor-pointer w-fit xl:w-1/6'
			>
				<div className='flex item-center gap-2'>
					<img
						src={machLogo}
						alt='Mach Machinery'
						className='h-5 w-5 xl:h-8 xl:w-8'
					/>
					<img src={mach} alt='Mach Machinery' className='h-4 xl:h-8' />
				</div>
			</Link>
			<div className='flex-1 flex flex-col px-2 md:pl-8'>
				<div className='h-full flex mr-4 justify-between md:justify-end items-center'>
					<GiHamburgerMenu
						className='text-primary md:hidden'
						size={20}
						onClick={() => setOpenNavBar(!openNavBar)}
					/>
					<Link to='landing' className='logo flex items-center w-1/2 md:hidden'>
						<img
							src={machLogo}
							alt='Mach Machinery'
							className='h-5 w-5 xl:h-8 xl:w-8'
						/>
						<img src={mach} alt='Mach Machinery' className='h-4 xl:h-8' />
					</Link>
					<div className='flex gap-8'>
						<UserNavigations openNavBar={openNavBar} setOpenNavBar={setOpenNavBar} />
					</div>
					<div className='h-full flex justify-end items-center md:px-4 gap-2 md:gap-4'>
						<div>
							<Translation />
						</div>
						<div className='dropdown dropdown-hover dropdown-end'>
							<div>
								{user_details ? (
									<button
										type='button'
										className='w-8 rounded-full bg-base-300 text-center grid place-content-center h-8'
										name='user-profile'
									>
										<span className='text-md leading-4 tracking-widest'>
											{`${getInitialLetter(user_details?.first_name)}${getInitialLetter(
												user_details?.last_name
											)}`}
										</span>
									</button>
								) : (
									<button type='button' className='avatar' name='user-profile'>
										<div className='w-8 rounded-full'>
											<img src={userImage} alt='user' />
										</div>
									</button>
								)}
							</div>
							<ul className='dropdown-content menu rounded-md shadow bg-base-200'>
								<div className='border-b-neutral flex flex-col flex-nowrap items-start px-4 py-4'>
									{user_details && (
										<span>{`${user_details.first_name} ${user_details.last_name}`}</span>
									)}
									<span className='text-secondary'>{email || ''}</span>
									<span className='text-gray-500 capitalize'>
										{ROLE[role || 3].toLocaleLowerCase()}
									</span>
									<span className='text-gray-500'>{company?.company_name}</span>
								</div>
								<hr />
								<UserProfileMenuItem
									icon={<MdSettings />}
									label={t('api.settings')}
									onClickHandler={() => navigate('settings')}
								/>
								<UserProfileMenuItem
									icon={<AiOutlineSecurityScan />}
									label={t('api.rules')}
									onClickHandler={() => navigate('settings/rules')}
								/>
								<UserProfileMenuItem
									icon={<MdExitToApp />}
									label={t('api.logout')}
									onClickHandler={logoutUser}
								/>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Header
