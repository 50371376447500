import AdminSideBar from './components/AdminSideBar'
import AdminRoute from './routes'
import AdminHeader from './components/AdminHeader'

const SuperAdmin = () => (
	<div className='h-full w-full flex flex-col'>
		<AdminHeader />
		<div className='h-[88vh] flex'>
			<div
				style={{
					transition: 'max-width 3s',
				}}
				className='w-fit max-w-[20em] border-r-2 transition delay-700 relative'
			>
				<AdminSideBar />
			</div>
			<div className='flex-1 overflow-auto'>
				<AdminRoute />
			</div>
		</div>
	</div>
)

export default SuperAdmin
