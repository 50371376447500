import {ReactElement} from "react";
import { useTranslation } from 'react-i18next'

interface ICheckboxProps {
	label?: string | ReactElement
	name: string
	checked: boolean
	disabled?: boolean
	onChange?: () => void
	labelStyles?: string
	inputStyles?: string
	errorMessage?: string
}

const Checkbox = (props: ICheckboxProps) => {
	const { label, name, checked, disabled, onChange, labelStyles, inputStyles, errorMessage } =
		props

	const {t} = useTranslation()

	const defaultLabelStyle = 'font-semibold'
	const defaultInputStyle =
		'outline outline-[1px] outline-gray-300 checkbox checkbox-primary checkbox-xs focus-within:outline-secondary rounded-sm mr-2'

	return (
		<div className='form-control'>
			<label
				className='label cursor-pointer w-fit flex items-center gap-2 p-1'
				htmlFor={name}
			>
				<input
					type='checkbox'
					disabled={disabled}
					name={name}
					checked={checked}
					onChange={onChange}
					className={`${defaultInputStyle} ${inputStyles}`}
				/>
				<p aria-hidden onClick={onChange} className={`${defaultLabelStyle} ${labelStyles}`}>{label}</p>
			</label>
			{errorMessage && <p className='text-sm text-error'>{t(errorMessage)}</p>}
		</div>
	)
}

export default Checkbox
