/* eslint-disable react/no-danger */
import { useTranslation } from 'react-i18next'
import { ReactElement } from 'react'

interface IAlertModalProps {
	title?: string
	description?: string
	disabled?: boolean
	children?: ReactElement
	cancelText?: string
	cancelOnClick?: () => void
	noCancel?: boolean
	fullSize?: boolean
	okText?: string
	okOnClick?: () => void
	okClickLoader?: boolean
}

const AlertModal = (props: IAlertModalProps) => {
	const {
		title,
		description,
		children,
		cancelText,
		cancelOnClick,
		noCancel,
		okText,
		okOnClick,
		okClickLoader,
		fullSize,
		disabled,
	} = props

	const { t } = useTranslation()

	return (
		<div>
			<input
				type='checkbox'
				id='my-modal-6'
				className='modal-toggle'
				defaultChecked
			/>
			<div className={`modal modal-bottom ${fullSize ? 'modal-middle' : 'sm:modal-middle'}`}>
				<div className={`modal-box ${fullSize && 'w-11/12 h-[100%] max-w-5xl'}`}>
					{title && <h3 className='font-medium text-2xl'>{title}</h3>}
					{description && <div className='py-4'>
						<div
							dangerouslySetInnerHTML={{
								__html: description,
							}}
						/>
					</div>}
					{children}
					<div className='modal-action'>
						{
							!noCancel && <button
								className='btn btn-error btn-sm'
								type='button'
								onClick={cancelOnClick}
							>
								{cancelText || t('api.cancel')}
							</button>
						}
						<button
							className={`btn btn-primary btn-sm ${
								okClickLoader ? 'loading btn-disabled' : ''
							}`}
							disabled={disabled}
							type='button'
							onClick={okOnClick}
						>
							{okText || t('api.ok')}
						</button>
					</div>
				</div>
			</div>
		</div>
	)
}

export default AlertModal
