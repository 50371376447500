import MachineUploadOverview from 'modules/admin/views/Machine/views/MachineUpload/components/MachineUploadOverview'
import { Route, Routes } from 'react-router-dom'
import CreateMachine from '../../admin/views/Machine/views/MachineUpload/components/createMachine'

const AddMachine = () => (
	<div className='h-full w-full pt-4 pb-4'>
		<Routes>
			<Route
				path=''
				element={
					<div className='h-full container pt-8 bg-white mx-auto'>
						<CreateMachine />
					</div>
				}
			/>
			<Route path=':machineId' element={<MachineUploadOverview />} />
			<Route path=':machineId/overview' element={<MachineUploadOverview />} />
			<Route path='*' element={<p> Not found</p>} />
		</Routes>
	</div>
)

export default AddMachine
