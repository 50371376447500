import DataTable from 'react-data-table-component'
import './table.css'
import Loader from '../loader'

interface DataTableProps {
	columns: any
	data: any
	loading?: boolean
	striped?: boolean
	dense?: boolean
	noHeader?: boolean
	pagination?: boolean
	paginationServer?: boolean
	paginationDefaultPage?: number
	paginationTotalRow?: number
	onChangeRowsPerPage?: (newPerPage: number) => void
	onChangePage?: (page: number) => void
	paginationPerPage?: number
	expandableRows?: boolean
	ExpandedComponent?: any
	tableHeight?: string
	selectableRows?: boolean
	onRowCLicked?: (item: any) => void
	conditionalRowStyles?: any
	onSelectedRowsChanged?: (selectedRows: any) => void
	rowSelectCriteria?: any
	selectableRowDisabled?: any
}

const Table = (props: DataTableProps) => {
	const {
		columns,
		data,
		loading,
		noHeader,
		pagination,
		paginationPerPage,
		paginationServer,
		paginationDefaultPage,
		paginationTotalRow,
		onChangeRowsPerPage,
		onChangePage,
		striped = true,
		dense = true,
		expandableRows,
		ExpandedComponent,
		tableHeight,
		selectableRows,
		onRowCLicked,
		conditionalRowStyles,
		onSelectedRowsChanged,
		rowSelectCriteria,
		selectableRowDisabled,
	} = props

	const customStyles = {
		headCell: {
			padding: '0',
			height: '1rem !important',
		},
		headRow: {
			style: {
				fontSize: '0.84rem',
				fontWeight: 'bolder',
				padding: '0',
				height: '.5rem !important',
				backgroundColor: '#2e4f6b',
				color: '#fff',
				borderRadius: '8px 8px 0 0',
				fontStyle: 'avenir heavy',
			},
		},
		table: {
			style: {
				borderWidth: '1px',
				borderColor: '#e5e5e5',
				borderRadius: '8px',
			},
		},
		rows: {
			style: {
				padding: '0',
			},
		},
		columnHeader: {
			style: {
				padding: '0',
			},
		},
		cells: {
			style: {
				fontStyle: 'avenir medium',
			},
		},
	}

	return (
		<DataTable
			columns={columns}
			data={data}
			dense={dense}
			progressPending={loading}
			pagination={!!pagination}
			paginationPerPage={paginationPerPage || 25}
			paginationServer={paginationServer}
			paginationDefaultPage={paginationDefaultPage}
			paginationTotalRows={paginationTotalRow}
			onChangeRowsPerPage={onChangeRowsPerPage}
			onChangePage={onChangePage}
			striped={striped}
			highlightOnHover
			persistTableHead
			noTableHead={noHeader}
			fixedHeader
			fixedHeaderScrollHeight={tableHeight || 'calc(100vh - 16rem)'}
			selectableRows={selectableRows}
			onRowClicked={onRowCLicked}
			expandableRows={expandableRows}
			expandableRowsComponent={ExpandedComponent}
			expandOnRowClicked={expandableRows}
			expandableRowExpanded={(row: any) => row.defaultExpanded}
			progressComponent={<Loader />}
			responsive
			customStyles={customStyles}
			conditionalRowStyles={conditionalRowStyles}
			onSelectedRowsChange={onSelectedRowsChanged}
			selectableRowSelected={rowSelectCriteria}
			selectableRowDisabled={selectableRowDisabled}
		/>
	)
}

export default Table
