enum BID_STATUS {
	STATUS_NEW = 1,
	STATUS_BIDDED,
	STATUS_ACCEPTED,
	STATUS_REJECTED,
	STATUS_PENDING,
	STATUS_NOT_INTERESTED,
	PERIOD_CLOSED,
}

export default BID_STATUS
