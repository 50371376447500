import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { FormikHelpers, FormikProps, useFormik } from 'formik'
import { toast } from 'react-toastify'
import { useMutation } from '@apollo/client'
import { IForgotPassword } from '../graphql/model'
import Input from '../../../shared/components/Input'
import { RESET_PASSWORD_LINK } from '../graphql/mutation'

const ForgotPasswordForm = () => {
	const { t } = useTranslation()

	const [forgotPassword, { loading }] = useMutation(RESET_PASSWORD_LINK, {
		onCompleted(data) {
			toast.success(data.message || t('message.forgot_password_success'))
		},
		onError(error) {
			toast.error(error.message || t('message.forgot_password_failed'))
		},
	})

	const handleForgotPassword = async (formData: IForgotPassword) => {
		await forgotPassword({
			variables: {
				email: formData.email.toLowerCase(),
			},
		})
	}

	const formik: FormikProps<IForgotPassword> = useFormik<IForgotPassword>({
		validateOnChange: false,
		initialValues: {
			email: '',
		},
		validationSchema: Yup.object().shape({
			email: Yup.string()
				.email('message.enter_valid_email')
				.required('message.email_is_required'),
		}),
		onSubmit: async (
			values,
			{ setSubmitting }: FormikHelpers<IForgotPassword>
		) => {
			await handleForgotPassword(values)
			setSubmitting(false)
		},
	})

	return (
		<form className='flex flex-col gap-5' onSubmit={formik.handleSubmit}>
			<Input
				type='email'
				value={formik.values.email}
				name='email'
				label={t('fields.email')}
				onChange={formik.handleChange}
				errorMessage={
					formik.errors.email && formik.touched.email ? formik.errors.email : ''
				}
			/>
			<button
				type='submit'
				className={`btn btn-primary btn-sm rounded-md ${
					loading ? 'loading btn-disabled' : ''
				}`}
			>
				{t('api.forgot_password')}
			</button>
		</form>
	)
}

export default ForgotPasswordForm
