import { gql } from '@apollo/client'

export const FIND_MACHINE_BY_ID = gql`
	query FindMachineById($id: Int!) {
		findMachineById(id: $id)
	}
`

export const FIND_MACHINE_IMAGES = gql`
	query FindMachineById($id: Int!) {
		findMachineById(id: $id)
	}
`

export const FIND_MACHINE_BY_ID_WITH_AUCTION = gql`
	query FindMachineById($id: Int!) {
		findMachineById(id: $id)
	}
`

export const FIND_ALL_MACHINE = gql`
	query FindAllMachine {
		findAllMachine {
			id
			category {
				id
				name
			}
			type {
				id
				name
			}
			subcategory {
				id
				name
			}
			images {
				thumbnail_image
				image
			}
			brand_id
			brand
			model_id
			model
			year_of_manufacture
			year_of_manufacture
			customer_name
			location
			status
		}
	}
`

export const FIND_ALL_MACHINE_BY_STATUS = gql`
	query FindAllMachineByStatus(
		$status: [Int]
		$bidStatus: [Int]
		$filterIsArchived: Boolean
	) {
		findAllMachineByStatus(
			status: $status
			bid_status: $bidStatus
			filterIsArchived: $filterIsArchived
		) {
			id
			category {
				name
			}
			type {
				name
			}
			subcategory {
				name
			}
			brand_id
			brand
			model_id
			model
			year_of_manufacture
			year_of_manufacture_id
			customer_name
			location
			status
			is_archive
			bid_status
			bid {
				status
			}
		}
	}
`

export const FIND_BID_BY_MACHINE_ID_WITH_AUTH = gql`
	query FindBidByMachineIdWithAuth($machine_id: Int!) {
		findBidByMachineIdWithAuth(machine_id: $machine_id) {
			id
			value
			value_currency
			valid_until
			bid_type
			status
			user {
				id
				company {
					id
				}
			}
			machine {
				bid_status
			}
		}
	}
`
