import {BsTruckFlatbed} from "react-icons/bs";

interface IMachineErrorProps {
    message: string
}

const MachineError = (props: IMachineErrorProps) => {

    const {message} = props

    return (
        <div className='flex flex-col justify-center items-center h-full w-full gap-2'>
            <p className='text-3xl'>{message}</p>
            <div className=' w-1/3 border-b-4 border-gray-300 border-dashed flex justify-center'>
                <BsTruckFlatbed size={50}/>
            </div>
        </div>
    )
}

export default MachineError