import { gql } from '@apollo/client'

const CREATE_MACHINE = gql`
	mutation CreateMachine(
		$categoryId: ID!
		$subcategoryId: ID!
		$typeId: ID!
		$brand_id: ID
		$brand: String
		$model_id: ID
		$model: String
		$customer_name: String
		$location: String
		$machine_type_id: ID
		$machine_type: String
		$yearOfManufacture: String
		$yearOfManufactureId: ID
		$licencePlate: String
		$licencePlateId: ID
		$runningHours: Float
		$extraInput: [ExtraInput]
	) {
		createMachine(
			category_id: $categoryId
			type_id: $typeId
			subcategory_id: $subcategoryId
			extra_input: $extraInput
			brand_id: $brand_id
			brand: $brand
			model_id: $model_id
			model: $model
			customer_name: $customer_name
			machine_type_id: $machine_type_id
			machine_type: $machine_type
			year_of_manufacture: $yearOfManufacture
			year_of_manufacture_id: $yearOfManufactureId
			licence_plate: $licencePlate
			licence_plate_id: $licencePlateId
			running_hours: $runningHours
			location: $location
		) {
			id
		}
	}
`

const UPDATE_MACHINE = gql`
	mutation updateMachine(
		$id: ID!
		$categoryId: ID!
		$subcategoryId: ID!
		$typeId: ID!
		$brand_id: ID
		$brand: String
		$model_id: ID
		$model: String
		$customer_name: String
		$machine_type_id: ID
		$machine_type: String
		$location: String
		$yearOfManufacture: String
		$yearOfManufactureId: ID
		$licencePlate: String
		$licencePlateId: ID
		$runningHours: Float
		$extraInput: [ExtraInput]
	) {
		updateMachine(
			id: $id
			category_id: $categoryId
			type_id: $typeId
			subcategory_id: $subcategoryId
			extra_input: $extraInput
			brand_id: $brand_id
			brand: $brand
			model_id: $model_id
			model: $model
			customer_name: $customer_name
			machine_type_id: $machine_type_id
			machine_type: $machine_type
			year_of_manufacture: $yearOfManufacture
			year_of_manufacture_id: $yearOfManufactureId
			licence_plate: $licencePlate
			licence_plate_id: $licencePlateId
			running_hours: $runningHours
			location: $location
		) {
			id
		}
	}
`

const REMOVE_MACHINE = gql`
	mutation RemoveMachine($id: ID!) {
		removeMachine(id: $id) {
			id
		}
	}
`

const UPDATE_MACHINE_IMAGE = gql`
	mutation UpdateMachineImage($id: ID!, $images: [Upload]!) {
		updateMachineImage(id: $id, images: $images) {
			id
		}
	}
`

const REMOVE_MACHINE_IMAGE = gql`
	mutation RemoveMachineImage($id: ID!) {
		removeMachineImage(id: $id) {
			id
		}
	}
`

const REORDER_IMAGE = gql`
	mutation ReOrderImage($machine_images: [MachineImageInput]!) {
		updateMachineImagePosition(machine_images: $machine_images) {
			id
		}
	}
`

export {
	CREATE_MACHINE,
	UPDATE_MACHINE_IMAGE,
	UPDATE_MACHINE,
	REMOVE_MACHINE,
	REMOVE_MACHINE_IMAGE,
	REORDER_IMAGE,
}
