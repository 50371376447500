/* eslint-disable react/no-danger */
import { useMutation, useQuery } from '@apollo/client'
import { useBidContext } from 'context/useBidContext'
import { IBidData } from 'modules/admin/views/Machine/graphql/model'
import { FIND_BID_BY_MACHINE_ID_WITH_AUTH } from 'modules/admin/views/Machine/views/MachineUpload/graphql/query'
import { Key, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import Loader from 'shared/components/loader'
import { CREATE_BID } from 'shared/graphql/mutation'
import { currencyFormatter } from 'utils/currencyFormatter'

const BidConfirmation = () => {
	const [success, setSuccess] = useState<boolean>(false)
	const { bid, placeBidAction, setBidConfirmationScreen, setIsRefreshRequired } =
		useBidContext()
	const { t } = useTranslation()
	const { machineId } = useParams()

	const { loading: alreadyBidedLoading, refetch } = useQuery<IBidData>(
		FIND_BID_BY_MACHINE_ID_WITH_AUTH,
		{
			variables: {
				machine_id: parseInt(`${bid.machineId}`, 10),
			},
			onCompleted: () => setIsRefreshRequired(true),
			onError: error => {
				setIsRefreshRequired(true)
				toast.error(t('message.bid_place_failed') || error.message)
			},
		}
	)

	const [createBid, { loading }] = useMutation(CREATE_BID, {
		onCompleted: () => {
			setSuccess(false)
			placeBidAction({
				id: bid.machineId || machineId,
				amount: 0,
			})
			toast.success(t('message.bid_created'))
			setBidConfirmationScreen(false)
			refetch()
		},
		onError: (err: any) => {
			setSuccess(false)
			placeBidAction({
				id: bid.machineId || machineId,
				amount: 0,
				bidConfirmation: false,
			})
			toast.error(err?.message || t('message.bid_place_failed'))
		},
	})

	const handleSubmit = () => {
		setSuccess(true)
		createBid({
			variables: {
				machineId: bid.machineId,
				value: bid.bidValue,
				valueCurrency: 'EUR',
				// remarks,
			},
		})
	}

	if (alreadyBidedLoading) {
		return (
			<div className='flex justify-center items-center'>
				<Loader />
			</div>
		)
	}

	if (success) {
		setBidConfirmationScreen(false)
		setIsRefreshRequired(true)
	}

	return (
		<div className='card bg-white h-full min-h-fit'>
			<div className='card-body'>
				<div className='card-title'>{t('api.confirm_bid_title')}</div>

				<div className='card-content flex flex-col gap-2 mt-4'>
					<div className='flex flex-col gap-1'>
						<div className='flex flex-1 justify-between items-center py-2 text-gray-500'>
							<div className='flex items-center gap-2'>
								<p className='font-bold'>{t('api.your_bid')}</p>
							</div>
							<p className=' font-bold flex justify-end'>
								{currencyFormatter(
									typeof bid.bidValue === 'number'
										? bid.bidValue
										: parseFloat(bid.bidValue)
								)}
							</p>
						</div>
						<div className='flex flex-col gap-2'>
							{(bid.commission || []).map(
								(
									commissionItem: {
										commission_detail: string
										amount: number
									},
									index: Key | null | undefined
								) => (
									<div
										className='flex flex-1 justify-between items-center py-2'
										key={index}
									>
										<div className='flex items-center gap-2'>
											<p className='font-bold'>
												{t(`api.${commissionItem?.commission_detail || 'not_exist'}`)}
											</p>
											<div
												title={t(`api.${commissionItem?.commission_detail || 'not_exist'}`)}
												className='cursor-pointer'
											>
												<AiOutlineInfoCircle />
											</div>
										</div>
										<p className=' font-bold flex justify-end'>
											{currencyFormatter(
												typeof commissionItem?.amount === 'number'
													? commissionItem?.amount
													: parseFloat(commissionItem?.amount)
											)}
										</p>
									</div>
								)
							)}
						</div>
					</div>
					<hr />
					<div className='flex flex-1 justify-between items-center py-4'>
						<p className='font-bold '>Total</p>
						<p className=' font-bold flex justify-end'>
							{currencyFormatter(
								typeof bid?.total === 'number' ? bid?.total : parseFloat(bid?.total)
							)}
						</p>
					</div>

					<div className='flex flex-col gap-8'>
						<div
							className='leading-4'
							dangerouslySetInnerHTML={{
								__html: t('api.confirm_bid_detail'),
							}}
						/>
						{/*
						<Input
							type='text'
							name='Remarks'
							label='Remarks for buyer'
							value={remarks}
							onChange={e => setRemarks(e.target.value)}
						/> */}
						{/*
						<div
							className='leading-4'
							dangerouslySetInnerHTML={{
								__html: t('api.confirm_bid_notice'),
							}}
						/>
						*/}
					</div>
				</div>

				<div className='card-actions justify-end mt-4'>
					<button
						type='button'
						className='btn btn-outline btn-xs btn-primary'
						onClick={() => setBidConfirmationScreen(false)}
					>
						Cancel
					</button>
					<button
						type='button'
						className='btn btn-xs btn-primary'
						onClick={() => handleSubmit()}
					>
						{loading ? 'Loading' : t('api.confirm_bid')}
					</button>
				</div>
			</div>
		</div>
	)
}

export default BidConfirmation
