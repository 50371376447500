import { useTranslation } from 'react-i18next'

const machineStatus: any = {
	0: {
		name: 'api.concept',
		bg: 'badge-info',
	},
	1: {
		name: 'api.taxation',
		bg: 'badge-secondary',
	},
	2: {
		name: 'api.auction',
		bg: 'badge-success',
	},
	3: {
		name: 'api.archived',
		bg: 'badge-ghost',
	},
}

const bidStatus: any = {
	1: {
		name: 'api.bid_new',
		bg: 'badge-info',
	},
	2: {
		name: 'api.bidded',
		bg: 'badge-primary',
	},
	3: {
		name: 'api.bid_accepted',
		bg: 'badge-success',
	},
	4: {
		name: 'api.bid_rejected',
		bg: 'badge-ghost',
	},
	5: {
		name: 'api.bid_pending',
		bg: 'badge-secondary',
	},
	6: {
		name: 'api.bid_decision_expired',
		bg: 'badge-warning',
	},
	7: {
		name: 'api.bid_expired',
		bg: 'badge-warning',
	},
}

const machineBidStatus: any = {
	0: {
		name: 'api.new',
		bg: 'badge-secondary'
	},
	1: {
		name: 'api.bid_accepted',
		bg: 'badge-success',
	},
	2: {
		name: 'api.bid_rejected',
		bg: 'badge-ghost',
	},
	3: {
		name: 'api.expired',
		bg: 'badge-ghost',
	},
	4: {
		name: 'api.bidded',
		bg: 'badge-primary',
	},
}

export const getMachineStatus = (statusIndex: number) => machineStatus[statusIndex]
export const getBidStatus = (statusIndex: number) =>
	bidStatus[statusIndex]

export const getMachineBidStatus = (statusIndex: number) => machineBidStatus[statusIndex]

const StatusCard = (props: {
	statusNumber: number
	statusType: 'machineStatus' | 'bidStatus' | 'machineBidStatus' | 'archive'
}) => {
	const { statusNumber, statusType } = props

	const { t } = useTranslation()

	const getStatus = (statusType: 'machineStatus' | 'bidStatus' | 'machineBidStatus' | 'archive') => {
		switch (statusType) {
			case 'bidStatus':
				return getBidStatus(statusNumber)
			case 'machineStatus':
				return getMachineStatus(statusNumber)
			case 'machineBidStatus':
				return getMachineBidStatus(statusNumber)
			case 'archive':
				return {
					name: 'api.archived',
					bg: 'badge-warning',
				}
			default:
				return null
		}
	}

	const status = getStatus(statusType)

	return status ? (
		<div
			title={`this machine is set as ${status.name}`}
			className={`badge mx-[1px] min-w-[7em] w-fit ${
				statusType === 'bidStatus' || statusType === 'archive' || statusType === 'machineBidStatus' ? '' : 'badge-outline'
			} cursor-pointer text-sm md:text-md ${status.bg}`}
		>
			{t(status.name)}
		</div>
	) : null
}

export default StatusCard
