import { useTranslation } from 'react-i18next'
import { useLazyQuery, useMutation } from '@apollo/client'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import { FIND_TRANSLATIONS } from '../graphql/query'
import { CHANGE_USER_LOCALE } from '../../../shared/graphql/mutation'
import { useAuth } from '../../auth/context/useAuth'

const useTranslationData = () => {
	const { t, i18n } = useTranslation()

	const { user } = useAuth()

	const savedLanguage = localStorage.getItem('i18nextLng') || window.navigator.language || ''

	const [selectedLanguage, setSelectedLanguage] = useState<string>(
		savedLanguage ? savedLanguage.includes('en') ? 'en' : savedLanguage.includes('nl') ? 'nl' : 'en' : 'en'
	)

	const [getTranslations] = useLazyQuery(FIND_TRANSLATIONS, {
		onCompleted: data => {
			const translations = JSON.parse(data.findTranslations)
			for (let i = 0; i < Object.entries(translations).length; i++) {
				const [key, value] = Object.entries(translations)[i]
				i18n.addResources(key, 'common', value)
			}
		},
	})

	const [updateLocale] = useMutation(CHANGE_USER_LOCALE, {
		onError(error) {
			toast.error(error.message || `${t('message.change_language_fail')}`)
		},
	})

	const setDefaultLanguage = async () => {
		const savedLanguage = selectedLanguage
		if (savedLanguage) {
			setSelectedLanguage(savedLanguage)
			await i18n.changeLanguage(savedLanguage)
		}
	}

	const handleLangChanged = async (lang: string) => {
		setSelectedLanguage(lang)
		if(user!.id) {
			await updateLocale({
				variables: {
					locale: lang,
				},
			})
		}
		await i18n.changeLanguage(lang)
	}

	useEffect(() => {
		if(selectedLanguage !== i18n.language) {
			handleLangChanged(selectedLanguage)
		}
	}, [selectedLanguage])

	return {
		t,
		selectedLanguage,
		handleLangChanged,
		getTranslations,
		setDefaultLanguage,
	}
}

export default useTranslationData
