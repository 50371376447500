import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { MdArrowDropDown, MdOutlineCancel, MdSearch } from 'react-icons/md'
import { filter, differenceBy, remove } from 'lodash'
import { useTranslation } from 'react-i18next'
import { IOptions } from './Select'
import Input from './Input'

interface ISelect {
	options: IOptions[]
	placeHolder?: string
	label?: string
	labelStyle?: string
	selectStyle?: string
	optionStyle?: string
	name: string
	disabled?: boolean
	showSearch?: boolean
	errorMessage?: string
	hasNone?: boolean
	other?: boolean
	translatable?: boolean
	translatableGroup?: string
	otherName?: string
	otherLabel?: string
	otherValue?: string
	otherPlaceholder?: string
	otherOnChange?: (event: ChangeEvent<HTMLInputElement>) => void
}

interface ISingleSelect {
	value: string | number | null
	onChange?: (value: string | number | null) => void
	variant: 'single'
}

interface IMultiSelectProps {
	value: IOptions[]
	onChange?: (value: IOptions[]) => void
	variant: 'multi'
}

type IConditionType = ISingleSelect | IMultiSelectProps

const defaultLabelStyle = 'font-semibold '
const defaultSelectStyle =
	'flex justify-between w-full outline outline-[1px] outline-gray-300 drop-shadow-sm p-2 rounded-sm text-md relative overflow-auto'
const defaultOptionStyle =
	'absolute top-12 z-10 w-full outline outline-[1px] bg-white outline-gray-300 drop-shadow-sm rounded-sm'
const defaultOptionItemStyle =
	'w-full px-2 hover:bg-secondary hover:text-white border-b-2 bg-slate-50 py-1 cursor-pointer focus:outline-none focus:bg-secondary focus:text-white'
const defaultTagStyle = ''

const MultiSelect = (props: ISelect & IConditionType) => {
	const {
		variant,
		name,
		label,
		value,
		options,
		selectStyle,
		labelStyle,
		onChange,
		showSearch,
		optionStyle,
		placeHolder,
		disabled = false,
		errorMessage,
		hasNone = true,
		translatable,
		translatableGroup,
		otherName,
		otherPlaceholder,
		otherLabel,
		otherValue,
		other,
		otherOnChange,
	} = props

	const { t } = useTranslation()

	const [searchTags, setSearchTags] = useState<IOptions[]>([])
	const [isOpen, setIsOpen] = useState<boolean>(false)
	const [search, setSearch] = useState<string>('')

	const selectContainerRef = useRef<HTMLDivElement>(null)

	const [tempOption, setTempOption] = useState<IOptions[]>([])

	useEffect(() => {
		const removeOptionsInSearchTags =
			variant === 'multi' ? differenceBy(options, searchTags, 'value') : options
		const filteredOptions = filter(removeOptionsInSearchTags, item =>
			item.label.toLowerCase().includes(search.toLowerCase())
		)
		setTempOption(filteredOptions)
	}, [options, search, searchTags])

	const toggleDropdown = () => {
		setIsOpen(!isOpen)
	}

	useEffect(() => {
		function handleClickOutside(event: MouseEvent) {
			if (
				selectContainerRef.current &&
				!selectContainerRef.current?.contains(event.target as Node)
			) {
				setIsOpen(false)
			}
		}

		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [selectContainerRef])

	const onChangeHandler = (option: IOptions | null) => {
		if (variant === 'multi') {
			option && setSearchTags([...searchTags, option])
			option && onChange && onChange([...searchTags, option])
		} else {
			onChange && onChange(option ? option.value : null)
			setIsOpen(false)
		}
	}

	const onCancelClicked = (value: string | number) => {
		const tempSearchTags = remove(searchTags, item => item.value !== value)
		setSearchTags(tempSearchTags)
	}

	return (
		<div className='flex flex-col gap-4'>
			<div
				ref={selectContainerRef}
				className='flex flex-col justify-start gap-1 w-full'
			>
				{label && (
					<label htmlFor={name} className={`${defaultLabelStyle} ${labelStyle}`}>
						{label}
					</label>
				)}
				<div className='relative'>
					<div
						role='button'
						tabIndex={0}
						onClick={() => {
							!disabled && toggleDropdown()
						}}
						onKeyDown={e => {
							!disabled &&
								(e.code === 'Enter' || e.code === 'Space') &&
								toggleDropdown()
						}}
						className={`${defaultSelectStyle} ${translatable} ${
							disabled
								? 'focus-within:outline-none text-gray-300'
								: 'focus-within:outline-secondary'
						} ${selectStyle}`}
					>
						{variant === 'single' && (
							<div
								className={`focus:outline-none cursor-pointer w-full flex ${
									options.find(option => option.value === value)?.label
										? ''
										: 'text-gray-400'
								}`}
							>
								{value === 'option'
									? 'option'
									: translatable && options && options
										.find(option => option.value === value) ? t(`${translatableGroup}.${options
										.find(option => option.value === value)
										?.label}`) : options
											.find(option => option.value === value)
											?.label.replaceAll('_', ' ') ||
									  placeHolder?.replaceAll('_', ' ') ||
									  'Select Option'}
							</div>
						)}
						{variant === 'multi' && (
							<div className='w-full flex flex-wrap gap-2'>
								{searchTags.length
									? searchTags.map(tag => (
											<div
												aria-hidden
												className={`${defaultTagStyle} outline outline-[1px] outline-gray-300 flex gap-2 w-fit py-[2px] px-2 rounded-sm bg-secondary text-white`}
												onClick={e => e.stopPropagation()}
											>
												<p className='text-sm font-semibold'>{tag.label}</p>
												<MdOutlineCancel
													className='text-xl cursor-pointer hover:text-error'
													onClick={() => onCancelClicked(tag.value)}
												/>
											</div>
									  ))
									: placeHolder || 'Select Options'}
							</div>
						)}
						<MdArrowDropDown
							className={`text-xl ${
								isOpen && 'rotate-180 transition duration-300 ease-in-out'
							}`}
						/>
					</div>
					<ul
						role='listbox'
						className={`${defaultOptionStyle} ${
							isOpen ? 'block' : 'hidden'
						} ${optionStyle}`}
					>
						{showSearch && (
							<div className='p-2'>
								<Input
									inputStyles='py-0'
									type='text'
									name='search'
									value={search}
									onChange={event => setSearch(event.target.value)}
									leftContent={<MdSearch />}
								/>
							</div>
						)}
						<div className='max-h-96 overflow-auto'>
							{hasNone && variant === 'single' && (
								<li
									role='option'
									aria-selected={value === null}
									tabIndex={0}
									className={`${defaultOptionItemStyle} ${translatable}`}
									onKeyDown={() =>
										variant === 'single'
											? onChangeHandler({
													label: '',
													value: '',
											  })
											: onChangeHandler(null)
									}
									onClick={() =>
										variant === 'single'
											? onChangeHandler({
													label: '',
													value: '',
											  })
											: onChangeHandler(null)
									}
								>
									{placeHolder?.replaceAll('_', ' ') || 'Select none'}
								</li>
							)}
							{tempOption.map((option, index) => (
								<li
									role='option'
									aria-selected={value === option.value}
									tabIndex={0}
									key={index}
									className={`${defaultOptionItemStyle} ${translatable}`}
									onClick={() => onChangeHandler(option)}
									onKeyDown={e => {
										;(e.code === 'Enter' || e.code === 'Space') && onChangeHandler(option)
									}}
									id={option.label}
								>
									{translatable ? t(`${translatableGroup}.${option.label}`) : option.label.replaceAll('_', ' ')}
								</li>
							))}
							{other && (
								<li
									role='option'
									aria-selected={value === 'other'}
									tabIndex={0}
									className={`${defaultOptionItemStyle} ${translatable}`}
									onClick={() => onChangeHandler({ label: 'other', value: 'other' })}
									onKeyDown={e => {
										;(e.code === 'Enter' || e.code === 'Space') &&
											onChangeHandler({
												label: 'other',
												value: 'other',
											})
									}}
									id='other'
								>
									{t('fields.other')}
								</li>
							)}
						</div>
					</ul>
				</div>
				{errorMessage && <p className='text-sm text-error'>{t(errorMessage)}</p>}
			</div>
			{value === 'other' && (
				<Input
					type='text'
					name={otherName || 'other'}
					label={otherLabel}
					value={otherValue}
					placeholder={otherPlaceholder}
					onChange={otherOnChange}
				/>
			)}
		</div>
	)
}

export default MultiSelect
