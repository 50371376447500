import { gql } from '@apollo/client'

const UPDATE_COMPANY = gql`
	mutation UpdateCompany(
		$id: ID!
		$companyName: String!
		$countryName: String!
		$chamberOfCommerceNumber: String
		$vatNumber: String
		$phoneNumber: String
		$postalCode: String
		$city: String
		$streetName: String
		$streetNumber: String
		$companyLogo: Upload
	) {
		updateCompany(
			company_id: $id
			company_name: $companyName
			country_name: $countryName
			chamber_of_commerce: $chamberOfCommerceNumber
			vat_number: $vatNumber
			phone_number: $phoneNumber
			postal_code: $postalCode
			city: $city
			street_name: $streetName
			street_number: $streetNumber
			company_logo: $companyLogo
		) {
			id
			company_name
			country_name
			chamber_of_commerce
			vat_number
			phone_number
			city
			postal_code
			street_name
			street_number
			company_logo
		}
	}
`

const UPDATE_USER_DETAIL = gql`
	mutation UpdateUserDetail(
		$id: ID!
		$firstName: String
		$lastName: String
		$email: Email
		$mobileNumber: String
	) {
		updateUserDetail(
			user_id: $id
			first_name: $firstName
			last_name: $lastName
			email: $email
			mobile_number: $mobileNumber
		) {
			id
			email
			user_details {
				first_name
				last_name
				mobile_number
			}
		}
	}
`

const CREATE_PREFERENCE = gql`
	mutation CreatePreference(
		$userId: ID!
		$categoryIds: String
		$typeIds: String
		$subCategoryIds: String
	) {
		createPreference(
			user_id: $userId
			category_ids: $categoryIds
			type_ids: $typeIds
			subcategory_ids: $subCategoryIds
		) {
			id
		}
	}
`

const UPDATE_PREFERENCE = gql`
	mutation UpdatePreference(
		$userId: ID!
		$categoryIds: String
		$typeIds: String
		$subCategoryIds: String
	) {
		updatePreference(
			user_id: $userId
			category_ids: $categoryIds
			type_ids: $typeIds
			subcategory_ids: $subCategoryIds
		) {
			id
		}
	}
`

const UPDATE_USER_PREFERENCE_SUBSCRIPTION = gql`
	mutation updateUserPreferenceSubscription($isSubscribed: Boolean!) {
		updateUserPreferenceSubscription(is_subscribed: $isSubscribed) {
			is_subscribed
		}
	}
`

export {
	UPDATE_COMPANY,
	UPDATE_USER_DETAIL,
	CREATE_PREFERENCE,
	UPDATE_PREFERENCE,
	UPDATE_USER_PREFERENCE_SUBSCRIPTION,
}
