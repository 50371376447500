import AdminHeader from 'modules/admin/components/AdminHeader'
import { useTranslation } from 'react-i18next'

function Help() {
	const { t } = useTranslation()
	return (
		<div className='h-full w-full flex flex-col'>
			<div className='h-[8vh]'>
				<AdminHeader noHeader />
			</div>
			<div className='h-[88vh] flex flex-col items-center px-4 py-6 md:p-16'>
				<div className='w-full md:w-3/4 flex flex-col gap-10'>
					<div className='text-3xl md:text-5xl font-bold tracking-wide  md:leading-8'>
						{t('api.title_help')}
					</div>
					<div className='text-xl leading-8 font-medium bg-white p-8 drop-shadow rounded-md min-h-[500px] md:min-h-[650px]'>
						{t('api.description_help')}
					</div>
				</div>
			</div>
		</div>
	)
}

export default Help
