import { NavLink } from 'react-router-dom'
import React from 'react'

export interface SideBarMenuProps {
	logo: React.ReactElement
	label: string
	link: string
	expandSidebar: boolean
}

const SideBarMenu = (props: SideBarMenuProps) => {
	const { logo, link, label, expandSidebar } = props

	const defaultStyle = `flex justify-start items-center gap-4 font-semibold py-4 px-4 ${
		expandSidebar && 'md:px-8'
	} cursor-pointer border-l-4 border-transparent`
	const activeStyle = `${defaultStyle} text-secondary border-secondary font-bold`
	const notActiveStyle = `${defaultStyle} hover:text-primary hover:border-primary font-bold`

	return (
		<NavLink
			to={link}
			className={({ isActive }) => (isActive ? activeStyle : notActiveStyle)}
		>
			<span className='text-2xl'>{logo}</span>
			<p className={`align-baseline hidden text-lg ${expandSidebar && 'md:block'}`}>{label}</p>
		</NavLink>
	)
}

export default SideBarMenu
