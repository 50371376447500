import { gql } from '@apollo/client'

const FIND_ALL_SPECIFICATION = gql`
	query FindAllSpecification {
		findAllSpecification {
			id
			category {
				name
			}
			type {
				name
			}
			subcategory {
				name
			}
			specification_attributes {
				select {
					name
				}
				attribute {
					attribute_name
					translatable
     translatable_value
				}
			}
		}
	}
`

const FIND_SPECIFICATION_BY_ID = gql`
	query FindSpecificationById($id: Int!) {
		findSpecificationById(id: $id) {
			id
			category {
				id
				name
			}
			type {
				id
				name
			}
			subcategory {
				id
				name
			}
			specification_attributes {
				select {
					id
					name
				}
				attribute {
					id
					attribute_name
				}
			}
		}
	}
`

const FIND_ALL_CATEGORY_WITH_TYPES = gql`
	query FindAllCategories {
		findAllCategories {
			id
			name
			type {
				id
				name
			}
		}
	}
`

const FIND_ALL_CATEGORY_WITH_TYPES_AND_SUBCATEGORY = gql`
	query FindAllCategories {
		findAllCategories {
			id
			name
			type {
				id
				name
				subcategory {
					id
					name
					extra_field {
						id
						field_name
						position
					}
				}
			}
		}
	}
`

const FIND_SELECTS_BY_ATTRIBUTE_IDS = gql`
	query FindSelectsByAttributeIds($ids: [ID]) {
		findSelectsByAttributeIds(ids: $ids) {
			id
			name
			attribute_id
			attribute {
					translatable
					translatable_value
			}
		}
	}
`

const FIND_SPECIFICATION_SELECTS_BY_ATTRIBUTE_IDS = gql`
	query FindSpecificationSelectsByAttributeIds(
		$ids: [ID]
		$category_id: ID!
		$type_id: ID!
		$subcategory_id: ID!
	) {
		findSpecificationSelectsByAttributeIds(
			ids: $ids
			category_id: $category_id
			type_id: $type_id
			subcategory_id: $subcategory_id
		) {
			select {
				id
				name
			}
			attribute {
				id
			}
		}
	}
`

export {
	FIND_ALL_SPECIFICATION,
	FIND_ALL_CATEGORY_WITH_TYPES,
	FIND_ALL_CATEGORY_WITH_TYPES_AND_SUBCATEGORY,
	FIND_SELECTS_BY_ATTRIBUTE_IDS,
	FIND_SPECIFICATION_BY_ID,
	FIND_SPECIFICATION_SELECTS_BY_ATTRIBUTE_IDS,
}
