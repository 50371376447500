/* eslint-disable react/jsx-no-useless-fragment */
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { filter, map } from 'lodash'
import { toast } from 'react-toastify'
import { FormikHelpers, FormikProps, useFormik } from 'formik'
import * as Yup from 'yup'

import GridDragnDrop from 'shared/components/GridDragnDrop'
import { useMutation } from '@apollo/client'
import { useNavigate, useParams } from 'react-router-dom'
import { useAuth } from 'modules/auth/context/useAuth'
import ROLE from 'enums/Roles'
import FileUpload from 'shared/components/FileUpload'
import Loader from 'shared/components/loader'
import { FIND_MACHINE_BY_ID } from '../graphql/query'
import { REMOVE_MACHINE_IMAGE, REORDER_IMAGE, UPDATE_MACHINE_IMAGE } from '../graphql/mutation'
import { IMachineImageUploadFormData } from '../graphql/model'

const imageLocation = process.env.REACT_APP_S3_LOCATION || ''

const MachineImageUpload = (props: any) => {
	const { id, images, refetch, loading: machineImageUploadLoading } = props
	const { machineId } = useParams()
	const { t } = useTranslation()
	const navigate = useNavigate()
	const { role } = useAuth()

	const [maxCount, setMaxCount] = useState<number>(20)
	const [uploadedImages, setUploadedImages] = useState<any>([])
	const [removeImageId, setRemoveImageId] = useState<number | null>(null)
	const [orderImageList, setOrderImageList] = useState<any>()

	const [reOrderImage] = useMutation(REORDER_IMAGE)

	const [updateMachineUpload, { loading }] = useMutation(UPDATE_MACHINE_IMAGE, {
		async onCompleted() {
			toast.success(`${t('message.machine_image_update_success')}`)
			navigate(machineId ? `overview` : `${id || machineId}/overview`)
		},
		onError(error) {
			toast.error(
				error.message || `${t('message.machine_image_update_failed')}`
			)
		},
		refetchQueries: [
			{ query: FIND_MACHINE_BY_ID, variables: { id: parseInt(String(id), 10) } },
		],
	})

	const [removeImage, { loading: imageRemoveLoading }] = useMutation(
		REMOVE_MACHINE_IMAGE,
		{
			onCompleted: async data => {
				const leftItems = filter(
					uploadedImages,
					image => String(image.id) !== String(data.removeMachineImage.id)
				)
				setUploadedImages(leftItems)
				setMaxCount(maxCount + 1)
				toast.success(`${t('message.machine_image_remove_success')}`)
			},
			onError(error) {
				toast.error(
					error.message || `${t('message.machine_image_remove_failed')}`
				)
			},
			refetchQueries: [
				{ query: FIND_MACHINE_BY_ID, variables: { id: parseInt(String(id), 10) } },
			],
		}
	)

	const handleImageReorder = async (newList: any) => {
		setUploadedImages(newList)
		const orderImageList = newList.map((item: any, index: number) => ({
			id: item.id,
			thumbnail_image: item.thumbnail_image,
			image: item.thumbnail_image,
			position: index
		}))
		setOrderImageList(orderImageList)
	}

	const setImagesOnUploadImages = async () => {
		const imageList = map(images, (item, index) => ({
			id: item.id,
			thumbnail_image: item.thumbnail_image,
			position: index,
			machine_id: id || machineId,
			chosen: false,
		}))
		setUploadedImages(imageList)
	}

	const formik: FormikProps<IMachineImageUploadFormData> =
		useFormik<IMachineImageUploadFormData>({
			initialValues: {
				imagesFile: [],
			},
			validationSchema:
				!uploadedImages || uploadedImages.length === 0
					? Yup.object().shape({
							imagesFile: Yup.array()
								.min(1,'message.at_least_one_image_required')
								.max(20,'message.max_image_limit_exceeded'),
					  })
					: null,
			onSubmit: async (
				values,
				{ setSubmitting }: FormikHelpers<IMachineImageUploadFormData>
			) => {
				await updateMachineUpload({
					variables: { id: id || machineId, images: values.imagesFile },
				})
				if(orderImageList) {
				 await	reOrderImage({
						variables: {
							machine_images: orderImageList,
						},
					})
				}
				setSubmitting(false)
			},
		})

	const updateFilesState = (values: any) => {
		formik.setFieldValue('imagesFile', values)
	}

	const onClickHandler = async (e: any, id: number) => {
		setRemoveImageId(id)
		await removeImage({
			variables: {
				id,
			},
		})
	}

	useEffect(() => {
		setImagesOnUploadImages()
	}, [images])

	useEffect(() => {
		if(id || machineId) {
			refetch({id: parseInt(String(id || machineId), 10)})
		}
	}, [id, machineId])

	return (
		<div className='w-full md:w-[760px] mx-auto p-5 bg-white shadow-sm'>
			<div className='flex justify-end items-center px-5'>
				<p>
					{t('api.count')}:{' '}
					{formik.values.imagesFile && formik.values.imagesFile.length} / {maxCount}
				</p>
			</div>
			<form
				className='w-full flex flex-col gap-5 p-5 bg-white shadow-sm'
				onSubmit={formik.handleSubmit}
			>
				<FileUpload
					files={formik.values.imagesFile}
					setFiles={updateFilesState}
					max={maxCount}
				/>
				{uploadedImages && uploadedImages.length > 0 && (
					<GridDragnDrop list={uploadedImages || []} setList={handleImageReorder}>
						<>
							{machineImageUploadLoading ? (
								<Loader />
							) : (
								<>
									{uploadedImages.map((image: any) => {
										if (imageRemoveLoading && image.id === removeImageId) {
											return <Loader />
										}
										return (
											<div className='relative w-1/3' key={image.id + id}>
												<img
													src={`${imageLocation}/${image.thumbnail_image}`}
													alt={t('api.uploaded_machine')}
												/>
												<p>{image.name}</p>
												<button
													type='button'
													className='absolute z-10 right-1 top-1 rounded-full bg-primary px-2 text-white text-xl'
													onClick={(e: any) => onClickHandler(e, image.id)}
												>
													x
												</button>
											</div>
										)
									})}
								</>
							)}
						</>
					</GridDragnDrop>
				)}

				<p className='text-right text-error'>{formik.errors.imagesFile}</p>
				<div className='flex justify-end flex-wrap items-center gap-4'>
					<button
						type='button'
						className={`btn btn-outline hidden md:block btn-sm rounded-md ${loading ? 'btn-disabled' : ''}`}
						onClick={() =>
							navigate(
								role === ROLE.ADMIN ? '../../../machines' : '../../company-machine'
							)
						}
					>
						{t('api.go_back_to_machine_list')}
					</button>
					<button type='submit' className={`btn btn-primary btn-sm  rounded-md ${loading ? 'loading btn-disabled' : ''}`}>
						{t('api.save_machine')}
					</button>
				</div>
			</form>
		</div>
	)
}

export default MachineImageUpload
