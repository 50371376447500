import { MdArrowBack, MdArrowForward, MdKeyboardBackspace } from 'react-icons/md'
import { useTranslation } from 'react-i18next'
import { ReactElement, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { IMyMachineData } from '../graphql/model'
import {
	FILTER_MACHINE_FOR_MACHINE_DETAILS,
	FIND_AUCTION_MACHINE_FOR_MACHINE_DETAIL,
	FIND_BIDDED_MACHINE_FOR_MACHINE_DETAIL,
	FIND_MY_TAXATION_FOR_MACHINE_DETAIL
} from '../graphql/query'
import { IAuctionData } from '../../admin/views/Auction/graphql/model'

interface IActionMenuButton {
	icon: ReactElement
	label?: string
	buttonStyle?: string
	labelStyle?: string
	onClick: () => void
}

const ActionMenuButton = (props: IActionMenuButton) => {
	const { icon, label, buttonStyle, labelStyle, onClick } = props

	return (
		<button
			type='button'
			onClick={onClick}
			className={`flex items-center gap-2 text-lg ${buttonStyle}`}
		>
			{icon}
			{label && <p className={`underline ${labelStyle}`}>{label}</p>}
		</button>
	)
}

const ActionMenu = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()

	const [searchParams] = useSearchParams()
	const categories: number[]  = searchParams && searchParams.get('categories') ? searchParams!.get('categories')!.split(',').map(str => Number(str)) : []
	const type: number[]  = searchParams && searchParams.get('type') ? searchParams!.get('type')!.split(',').map(str => Number(str)) : []
	const subCategories: number[] = searchParams && searchParams.get('subcategory') ? searchParams!.get('subcategory')!.split(',').map(str => Number(str)) : []
	const source: string | null = searchParams && searchParams.get('source') ? searchParams!.get('source') : null
	const page: number | null  = searchParams && searchParams.get('page') ? 	Number(searchParams.get('page')) : null
	const status: number[] = searchParams && searchParams.get('status') ? searchParams!.get('status')!.split(',').map(str => Number(str)) : []
	const bidStatus: number[]  = searchParams && searchParams.get('bid-status') ? searchParams!.get('bid-status')!.split(',').map(str => Number(str)) : []
	const auctionDate: number | null  = searchParams && searchParams.get('auction-date') ? Number(searchParams!.get('auction-date')) : null

	const setupUrl: () => string = () => {
		switch (source) {
			case 'auction':
				return '/machine'
			case 'taxation':
				return '/taxations'
			case 'company_machine':
				return '/company-machine'
			case 'company_bids':
				return '/bidded-machine'
			default:
				return '/machine'
		}
	}

	const backToResultUrl: () => string = () => {
		switch (source) {
			case 'auction':
				return 'auction'
			case 'taxation':
				return 'taxations'
			case 'company_machine':
				return 'company-machine'
			case 'company_bids':
				return 'bidded-machine'
			default:
				return ''
		}
	}

	const baseUrl = setupUrl()

	const [currentPage, setCurrentPage] = useState<number>(page || 0)
	const [total, setTotal] = useState<number | null>(null)
	const [last, setLast] = useState<number | null>(null)

	useQuery<IMyMachineData>(FILTER_MACHINE_FOR_MACHINE_DETAILS, {
		fetchPolicy: 'no-cache',
		skip: !source || !(source === 'company_machine'),
		variables: {
			category_ids: categories,
			type_ids: type,
			subcategory_ids: subCategories,
			status,
			bid_status: bidStatus,
			page: currentPage,
		},
		onCompleted: data => {
			setTotal(parseInt(String(data.findMachineByCompany.paginatorInfo.total), 10))
			setLast(parseInt(String(data.findMachineByCompany.paginatorInfo.lastPage), 10))
			navigate({pathname: `${baseUrl}/${data.findMachineByCompany.data[0].id}`, search: `?categories=${categories}&type=${type}&subcategory=${subCategories}&source=${source}&page=${currentPage}&status=${status}&bid-status=${bidStatus}&auction-date=${auctionDate}`})
		}
	})

	useQuery<IAuctionData>(FIND_AUCTION_MACHINE_FOR_MACHINE_DETAIL, {
		fetchPolicy: 'no-cache',
		skip: !source || !(source === 'auction'),
		variables: {
			categories_ids: categories,
			type_ids: type,
			subcategory_ids: subCategories,
			page: currentPage,
			auction_day_id: auctionDate
		},
		onCompleted: data => {
			setTotal(parseInt(String(data.filterAuctionMachine.total), 10))
			setLast(parseInt(String(data.filterAuctionMachine.last_page), 10))
			navigate({pathname: `${baseUrl}/${data.filterAuctionMachine.data[0].auction.machine.id}`, search: `?categories=${categories}&type=${type}&subcategory=${subCategories}&source=${source}&page=${currentPage}&status=${status}&bid-status=${bidStatus}&auction-date=${auctionDate}`})
		}
	})

	useQuery<IMyMachineData>(FIND_MY_TAXATION_FOR_MACHINE_DETAIL, {
		fetchPolicy: 'no-cache',
		skip: !source || !(source === 'taxation'),
		variables: {
			category_ids: categories,
			type_ids: type,
			subcategory_ids: subCategories,
			page: currentPage,
		},
		onCompleted: data => {
			setTotal(parseInt(String(data.findMyTaxationWithPagination.paginatorInfo.total), 10))
			setLast(parseInt(String(data.findMyTaxationWithPagination.paginatorInfo.lastPage), 10))
			navigate({pathname: `${baseUrl}/${data.findMyTaxationWithPagination.data[0].machine_id}`, search: `?categories=${categories}&type=${type}&subcategory=${subCategories}&source=${source}&page=${currentPage}&status=${status}&bid-status=${bidStatus}&auction-date=${auctionDate}`})
		}
	})

	useQuery(FIND_BIDDED_MACHINE_FOR_MACHINE_DETAIL, {
		fetchPolicy: 'no-cache',
		skip: !source || !(source === 'company_bids'),
		variables: {
			category_ids: categories,
			type_ids: type,
			subcategory_ids: subCategories,
			status,
			page: currentPage,
		},
		onCompleted: data => {
			setTotal(parseInt(String(data.filterBiddedMachine.total), 10))
			setLast(parseInt(String(data.filterBiddedMachine.last_page), 10))
			navigate({pathname: `${baseUrl}/${data.filterBiddedMachine.data[0].machine.id}`, search: `?categories=${categories}&type=${type}&subcategory=${subCategories}&source=${source}&page=${currentPage}&status=${status}&bid-status=${bidStatus}&auction-date=${auctionDate}`})
		}
	})

	return (
		<div className='flex h-[6vh] gap-6 w-full items-center justify-end'>
			<ActionMenuButton
				icon={<MdKeyboardBackspace />}
				label={t('api.back_to_result')}
				onClick={() => navigate(`/${backToResultUrl()}`)}
			/>
			{currentPage && total && currentPage > 0 && total > 0 && <>
				<ActionMenuButton
					icon={<MdArrowBack />}
					buttonStyle={`btn btn-primary btn-sm ${currentPage <= 1 && 'btn-disabled'}`}
					onClick={() => setCurrentPage(currentPage - 1)}
				/>
				{`${currentPage} of ${total}`}
				<ActionMenuButton
					icon={<MdArrowForward />}
					buttonStyle={`btn btn-secondary btn-sm ${currentPage === parseInt(String(last), 10) && 'btn-disabled'}`}
					onClick={() => setCurrentPage(currentPage + 1)}
				/>
			</>}
		</div>
	)
}

export default ActionMenu
