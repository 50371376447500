
export interface ITestimonialCard {
	name: string
	company: string
	position: string
	// rating: number
	description: string
	image: string
	handlePrev: () => void
	handleNext: () => void
	index: number
	length: number
}

const TestimonialCard = (props: ITestimonialCard) => {
	const { name, position, description, image, handleNext, company, handlePrev, index, length } =
		props

	return (
		<div className='relative bg-white w-full border-2 shadow-md flex items-center flex-col-reverse lg:flex-row py-2 lg:py-8 px-4 lg:px-12 gap-2 lg:gap-8'>
			<div className='flex flex-col gap-2 items-center'>
				<div className='h-28 w-28 2xl:h-36 2xl:w-36 rounded-full overflow-hidden'>
					<img src={image} alt={name} className='object-contain h-full w-full' loading="lazy" />
				</div>
				<p className='text-base 2xl:text-md font-semibold uppercase'>{name}</p>
				<p className='text-base 2xl:text-md text-gray-300 uppercase'>({company})</p>
				<p className='text-base hidden 2xl:text-md text-gray-300 uppercase'>({position})</p>
				{/* <Rating value={rating} disabled showNumbers /> */}
			</div>
			<div className='flex-1 h-full'>
				<p className='whitespace-pre-line max-w-3xl'>{description}</p>
			</div>
			{index > 0 && (
				<button
					type="button"
					className='h-8 w-8 absolute bottom-6 lg:hidden left-6 bg-black rounded-full flex justify-center hover:brightness-75 items-center text-white'
					onClick={handlePrev}
				>
					❮
				</button>
			)}
			{index !== length - 1 && (
				<button
					type="button"
					className='h-8 w-8 absolute bottom-6 right-6 lg:hidden bg-black rounded-full flex justify-center hover:brightness-75 items-center text-white'
					onClick={handleNext}
				>
					❯
				</button>
			)}
		</div>
	)
}

export default TestimonialCard
