import React, { createContext, useContext, useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { toast } from 'react-toastify'
import ROLE from 'enums/Roles'
import { useLocation, useNavigate } from 'react-router-dom'
import i18n from 'i18next'
import { IResetPassword, IUser } from '../graphql/model'
import { LOGIN, RESET_PASSWORD } from '../graphql/mutation'

export type LoginDataPropsType = {
	email: string
	password: string
}

export interface IAuthReducer {
	isLoginLoading: boolean
	resetPasswordLoading: boolean
	isUserLoggedIn: boolean | null
	token: string
	user: IUser
	tokenType: string
	loginUser?: (input: LoginDataPropsType) => void
	resetPasswordAndLogin?: (input: IResetPassword) => void
	setUser?: (user: IUser) => void
	logoutUser?: () => void
	hasUserDetail: boolean
	setHasUserDetail?: (value: boolean) => void
	role: ROLE | null
}

const authReducer: IAuthReducer = {
	isUserLoggedIn: null,
	isLoginLoading: false,
	resetPasswordLoading: false,
	token: '',
	tokenType: '',
	user: {
		id: 0,
		email: '',
		user_details: {
			id: 0,
			first_name: '',
			last_name: '',
			phone_number: '',
		},
		can_place_bid: false,
		company: {
			id: 0,
			company_name: '',
		},
	},
	hasUserDetail: false,
	role: null,
}

const initialUserState: IUser = {
	id: 0,
	email: '',
	user_details: {
		id: 0,
		first_name: '',
		last_name: '',
		phone_number: '',
	},
	can_place_bid: false,
	company: {
		id: 0,
		company_name: '',
	},
}

const authContext = createContext<IAuthReducer>(authReducer)

const { Provider } = authContext

const useAuthProvider = () => {
	const location = useLocation()
	const navigate = useNavigate()
	const [user, setUser] = useState<IUser>(initialUserState)
	const [isUserLoggedIn, setIsUserLoggedIn] = useState<boolean | null>(null)
	const [role, setRole] = useState<ROLE | null>(null)
	const [token, setToken] = useState<string>('')
	const [hasUserDetail, setHasUserDetail] = useState<boolean>(false)
	const [tokenType, setTokenType] = useState<string>('Bearer')

	const [login, { loading: isLoginLoading }] = useMutation(LOGIN, {
		onCompleted(data) {
			if (data.login!.user) {
				setUser(data.login.user)
				setIsUserLoggedIn(!!data.login.user)
				setToken(data.login.access_token)
				setTokenType('Bearer')
				// eslint-disable-next-line no-console
				console.log('sd', location.state)
				if (location.state) {
					// @ts-ignore
					navigate(location.state.from.pathname)
				}
				if (
					parseInt(data.login.user.roles[0].id, 10) !== ROLE.ADMIN &&
					!(
						data.login.user &&
						data.login.user.user_details &&
						data.login.user.user_details.first_name &&
						data.login.user.user_details.last_name &&
						data.login.user.user_details.mobile_number
					)
				) {
					setHasUserDetail(false)
					toast.error(i18n.t('message.first_fill_user_detail'))
					navigate('settings/user')
				} else {
					setHasUserDetail(true)
				}
				setRole(parseInt(data.login.user.roles[0].id, 10))
				localStorage.setItem('USER', JSON.stringify(data.login.user))
				localStorage.setItem('TOKEN', data.login.access_token)
				toast.success(data.login.message || 'message.login_success')
			} else {
				toast.error(data.login.message || 'message.login_failed')
			}
		},
		onError(error) {
			toast.error(error.message || 'message.login_failed')
		},
	})

	const loginUser = async (loginData: LoginDataPropsType) => {
		await login({
			variables: {
				email: loginData.email.toLowerCase(),
				password: loginData.password,
			},
		})
	}

	const [resetPassword, { loading: resetPasswordLoading }] = useMutation(
		RESET_PASSWORD,
		{
			onCompleted(data) {
				if (data.resetPassword!.user) {
					setUser(data.resetPassword.user)
					setIsUserLoggedIn(!!data.resetPassword.user)
					setToken(data.resetPassword.access_token)
					setTokenType('Bearer')
					if (location.state) {
						// @ts-ignore
						navigate(location.state.from.pathname)
					}
					if (
						parseInt(data.resetPassword.user.roles[0].id, 10) !== ROLE.ADMIN &&
						!(
							data.resetPassword.user &&
							data.resetPassword.user.user_details &&
							data.resetPassword.user.user_details.first_name &&
							data.resetPassword.user.user_details.last_name &&
							data.resetPassword.user.user_details.mobile_number
						)
					) {
						setHasUserDetail(false)
						toast.error(i18n.t('message.first_fill_user_detail'))
						navigate('settings/user')
					} else {
						setHasUserDetail(true)
					}
					setRole(parseInt(data.resetPassword.user.roles[0].id, 10))
					localStorage.setItem('USER', JSON.stringify(data.resetPassword.user))
					localStorage.setItem('TOKEN', data.resetPassword.access_token)
					toast.success(data.resetPassword.message || 'message.login_success')
				} else {
					toast.error(data.resetPassword.message || 'message.login_failed')
				}
			},
			onError(error) {
				toast.error(error.message || 'message.reset_password_failed')
			},
		}
	)

	const resetPasswordAndLogin = async (formData: IResetPassword) => {
		await resetPassword({
			variables: {
				email: formData.email,
				token: formData.token,
				password: formData.password,
				password_confirmation: formData.confirmPassword,
			},
		})
	}

	const logoutUser = () => {
		localStorage.removeItem('USER')
		localStorage.removeItem('TOKEN')
		setUser(initialUserState)
		setRole(null)
		setIsUserLoggedIn(false)
		navigate('')
	}

	const checkAuthentication = () => {
		const userInSession = localStorage.getItem('USER') || ''
		const tokenInSession = localStorage.getItem('TOKEN') || ''
		if (userInSession && tokenInSession) {
			const user = JSON.parse(userInSession)
			setUser(user)
			setRole(parseInt(user.roles[0].id, 10))
			setToken(tokenInSession)
			setIsUserLoggedIn(true)
			if (
				parseInt(user.roles[0].id, 10) !== ROLE.ADMIN &&
				!(
					user &&
					user.user_details &&
					user.user_details.first_name &&
					user.user_details.last_name &&
					user.user_details.mobile_number
				)
			) {
				setHasUserDetail(false)
				toast.error(i18n.t('message.first_fill_user_detail'))
				navigate('settings/user')
			} else {
				setHasUserDetail(true)
			}
		} else {
			setIsUserLoggedIn(false)
		}
	}

	useEffect(() => {
		checkAuthentication()
	}, [])

	return {
		user,
		isUserLoggedIn,
		token,
		tokenType,
		isLoginLoading,
		resetPasswordLoading,
		role,
		hasUserDetail,
		loginUser,
		resetPasswordAndLogin,
		logoutUser,
		setUser,
		setHasUserDetail,
	}
}

export const AuthProvider = ({
	children,
}: {
	children: React.ReactElement
}) => {
	const auth = useAuthProvider()
	return <Provider value={auth}>{children}</Provider>
}

export const useAuth = () => useContext(authContext)
