import { Route, Routes } from 'react-router-dom'
import NotFound from 'modules/errors/NotFound'
import Login from '../views/Login'
import Signup from '../views/Signup'
import ForgotPassword from '../views/ForgotPassword'
import ResetPassword from '../views/ResetPassword'

const AuthRoute = () => (
	<Routes>
		<Route path='' element={<Login />} />
		<Route path='login' element={<Login />} />
		<Route path='signup' element={<Signup />} />
		<Route path='forgot-password' element={<ForgotPassword />} />
		<Route path='password-reset/:token' element={<ResetPassword />} />
		<Route path='*' element={<NotFound />} />
	</Routes>
)

export default AuthRoute
