/* eslint-disable jsx-a11y/anchor-is-valid */
import { useNavigate } from 'react-router-dom'
import machLogo from 'assets/images/MACH_ICON-GRADIENT.png'
import mach from 'assets/images/logo.png'
import { useTranslation } from 'react-i18next'
import { GiHamburgerMenu } from 'react-icons/gi'
import BackDrop from 'shared/components/backDrop'
import { useState } from 'react'
import ScrollNavLink from 'shared/components/scrollNavLink'
import Translation from '../../translation'

const LandingHeader = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const [openNavBar, setOpenNavBar] = useState<boolean>(false)

	return (
		<div className='container px-2 xl:mx-auto xl:p-0  flex justify-between items-center'>
			<GiHamburgerMenu
				className='text-primary xl:hidden'
				size={20}
				onClick={() => setOpenNavBar(!openNavBar)}
			/>
			<ScrollNavLink
				to='what_we_do'
				className='logo h-full flex justify-center xl:justify-start items-center xl:py-4 cursor-pointer w-fit xl:w-1/6'
			>
				<div className='flex items-center gap-2'>
					<img
						src={machLogo}
						alt='Mach Machinery'
						className='h-5 w-5 xl:h-8 xl:w-8'
					/>
					<img src={mach} alt='Mach Machinery' className='h-4 xl:h-8' />
				</div>
			</ScrollNavLink>
			<div className='gap-6 hidden xl:flex'>
				<ul className='flex xl:gap-6 items-center'>
					<ScrollNavLink to='#what_we_do'>{t('api.what_we_do')}</ScrollNavLink>
					<ScrollNavLink to='#references'>{t('api.references')}</ScrollNavLink>
					<ScrollNavLink to='#why'>{t('api.why')}</ScrollNavLink>
					<ScrollNavLink to='#contact'>{t('api.contact')}</ScrollNavLink>
				</ul>
				<Translation />
				<button
					type='button'
					className='btn btn-outline btn-primary w-48 font-semibold'
					onClick={() => navigate('auth/login')}
				>
					{t('api.login')}
				</button>
				<button
					type='button'
					className='btn btn-primary w-48 font-semibold'
					onClick={() => navigate('auth/signup')}
				>
					{t('api.create_an_account')}
				</button>
			</div>
			<div className='xl:hidden'>
				<BackDrop openNavBar={openNavBar} setBackDropStatus={setOpenNavBar} />
				<div
					className={`${`navbar-mobile-style flex gap-2 flex-col justify-between ${
						openNavBar ? 'navbar-open' : 'navbar-close'
					}`}`}
				>
					<div className='flex gap-2 flex-col'>
						<div className='flex lg:hidden'>
							<img
								src={machLogo}
								alt='Mach Machinery'
								className='h-5 w-5 xl:h-8 xl:w-8'
							/>
							<img
								src={mach}
								alt='Mach Machinery'
								className='h-4 xl:h-8 object-position-left'
							/>
						</div>
						<div className='flex gap-2 flex-col'>
							<ScrollNavLink to='#what_we_do'>{t('api.what_we_do')}</ScrollNavLink>
							<ScrollNavLink to='#references'>{t('api.references')}</ScrollNavLink>
							<ScrollNavLink to='#why'>{t('api.why')}</ScrollNavLink>
							<ScrollNavLink to='#contact'>{t('api.contact')}</ScrollNavLink>
						</div>
					</div>
					<div className='flex gap-2 flex-col'>
						<button
							type='button'
							className='btn btn-outline btn-primary w-full font-semibold'
							onClick={() => navigate('auth/login')}
						>
							{t('api.login')}
						</button>
						<button
							type='button'
							className='btn btn-primary w-full font-semibold'
							onClick={() => navigate('auth/signup')}
						>
							{t('api.create_an_account')}
						</button>
					</div>
				</div>
			</div>
			<div className='xl:hidden'>
				<Translation />
			</div>
		</div>
	)
}

export default LandingHeader
