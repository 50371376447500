import Landing from 'modules/landing'
import { useState } from 'react'
import { MdCancel } from 'react-icons/md'
import { useLocation, useNavigate } from 'react-router-dom'
import AuthRoute from './routes'

const Auth = () => {
	const currentUrl = window.location.href
	const isAuth = currentUrl.includes('auth')
	const location = useLocation()
	const isSignUp = Boolean(location.pathname.includes('signup'))
	const [showModal, setShowModal] = useState(isAuth)
	const navigate = useNavigate()

	const handleClose = () => {
		navigate('/')
		setShowModal(false)
	}

	return (
		<div className={`relative h-full w-full ${showModal && 'overflow-hidden'}`}>
			<Landing />
			{showModal && (
				<div className='fixed z-10 top-6 left-0 w-full h-full bg-black-with-transparency flex items-center justify-center'>
					<div className='shadow-md'>
						<div className='relative h-full w-full bg-black-with-transparency'>
							<button
								type='button'
								className='absolute top-4 right-6 z-10 text-red-700'
								onClick={handleClose}
							>
								<MdCancel size='24' />
							</button>
							<div
								className={`container h-full m-auto flex items-center justify-center ${
									isSignUp ? 'md:justify-center' : 'md:justify-between'
								}`}
							>
								<div
									className={`card rounded-md bg-base-100 overflow-auto max-h-[90vh] md:max-h-[84vh] w-full md:w-11/12 ${
										isSignUp ? 'md:w-8/12 md:min-w-[64rem]' : 'md:w-1/4 md:min-w-[28rem]'
									} `}
								>
									<AuthRoute />
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	)
}

export default Auth
