import { Navigate, Route, Routes } from 'react-router-dom'

import Rules from '../components/Rules'
import Preferences from '../components/Preferences'
import CompanyDetail from '../components/CompanyDetail'
import UserDetail from '../components/UserDetail'

import PrivacyPolicy from '../../../../common/PrivacyPolicy'
import TermAndCondition from '../../../../common/TermAndCondition'
import UserManagement from '../../../../admin/views/UserManagement'
import { useAuth } from '../../../../auth/context/useAuth'

import Role from '../../../../../enums/Roles'

const SettingsRoutes = () => {
	const { role } = useAuth()

	return (
		<Routes>
			<Route path='' element={<Navigate to='company' />} />
			<Route path='company' element={<CompanyDetail />} />
			<Route path='user' element={<UserDetail />} />
			<Route path='privacy-policy' element={<PrivacyPolicy noHeader />} />
			<Route path='term-and-condition' element={<TermAndCondition noHeader />} />
			<Route path='preferences' element={<Preferences />} />
			<Route path='rules' element={<Rules />} />
			{role === Role.MANAGER && (
				<Route path='user-management/*' element={<UserManagement />} />
			)}
			<Route path='*' element={<p> Not found</p>} />
		</Routes>
	)
}

export default SettingsRoutes
