/* eslint-disable jsx-a11y/label-has-associated-control */
import { createRef, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import { toast } from 'react-toastify'

import ROLE from 'enums/Roles'
import MachineItemOverview from 'shared/MachineItemOverview'
import DatePicker from 'shared/components/DatePicker'
import { useAuth } from 'modules/auth/context/useAuth'
import { MdOutlineKeyboardBackspace } from 'react-icons/md'
import { BsArrowUp } from 'react-icons/bs'
import moment from 'moment'
import Input from 'shared/components/Input'
import useTranslationData from '../../../../../../translation/hooks/useTranslationData'
import { IBreadCrumbLink } from '../../../../../components/AdminSubHeader/model'
import AdminSubHeader from '../../../../../components/AdminSubHeader'

import { ADD_TO_AUCTION, ADD_TO_TAXATION } from '../../../graphql/mutation'
import { FIND_ALL_AUCTION_WITH_IS_ACTIVE } from '../../../../Auction/graphql/query'
import { IAuctionDayData } from '../../../../Auction/graphql/model'
import { FIND_MACHINE_BY_ID_WITH_AUCTION } from '../graphql/query'
import UserSubHeader from '../../../../../../user/components/UserSubHeader'
import AlertModal from '../../../../../../../shared/AlertModal'

const MachineUploadOverview = () => {
	const { t } = useTranslationData()
	const { role } = useAuth()
	const redirectAfterProcess =
		ROLE.ADMIN === role ? '/admin/machine' : '/company-machine'

	const navigate = useNavigate()
	const [auctionDate, setAuctionDate] = useState<Date>()
	const [tempAuctionDate, setTempAuctionDate] = useState<Date>()
	const [errorMessage, setErrorMessage] = useState<string>('')
	const { machineId } = useParams()
	const [activeDays, setActiveDays] = useState<any[]>([])
	const [availabilityDate, setAvailabilityDate] = useState<any>('')
	const [isDisabled, setIsDisabled] = useState(true)
	const [selectedTime, setSelectedTime] = useState<any>(undefined)
	const [auctionConfirmation, setAuctionConfirmation] = useState<boolean>(false)
	const [taxationConfirmation, setTaxationConfirmation] =
		useState<boolean>(false)
	const [minBidAmount, setMinBidAmount] = useState<number>(0)

	const getMinDate = () => new Date(new Date().setDate(new Date().getDate() + 1))

	useEffect(() => {
		const todayDate = new Date()
		if (auctionDate && auctionDate.getDate() > todayDate.getDate()) {
			setTempAuctionDate(auctionDate)
		}
	}, [auctionDate])

	const { data: machine } = useQuery<any>(FIND_MACHINE_BY_ID_WITH_AUCTION, {
		skip: !machineId,
		variables: {
			id: parseInt(machineId || '0', 10),
		},
	})

	const closeModelHandler = () => {
		setAuctionConfirmation(false)
		setTaxationConfirmation(false)
	}

	useEffect(() => {
		if (machine) {
			const machineData = JSON.parse(machine.findMachineById)
			if (machineData.auctions) {
				setAuctionDate(new Date(machineData.auctions.valid_until))
				if (machineData.status !== 0) {
					setSelectedTime(machineData.auctions.auctionDay.time)
				}
			}
			setIsDisabled(machineData.status !== 0)
		}
	}, [machine])

	const setAuctionTime = (data: IAuctionDayData, date: any) => {
		const auctionday = data.findAllAuctionWithIsActive.find(
			ad => new Date(date).getDay() === parseInt(String(ad.id), 10) - 1
		)
		setSelectedTime(auctionday?.time)
	}

	const { loading: auctionDaysLoading, data: auctionDays } =
		useQuery<IAuctionDayData>(FIND_ALL_AUCTION_WITH_IS_ACTIVE, {
			onCompleted: data => {
				const tempActiveDays = data.findAllAuctionWithIsActive.map(
					ad => parseInt(String(ad.id), 10) - 1
				)

				setActiveDays(tempActiveDays)

				const futureActiveDays = tempActiveDays.find(
					tad => tad > new Date().getDay()
				)
					? tempActiveDays.find(tad => tad > new Date().getDay())
					: tempActiveDays[0]

				if (futureActiveDays) {
					const addDayDate = futureActiveDays + 1
					const addDay: number = addDayDate - (new Date().getDay() + 1)
					const saveDate = new Date().setDate(new Date().getDate() + addDay)

					setAuctionDate(new Date(saveDate))
					setAuctionTime(data, saveDate)
				}
			},
		})

	const [addToTaxation, { loading: addToTaxationLoading }] = useMutation(
		ADD_TO_TAXATION,
		{
			onCompleted() {
				toast.success(`${t('message.add_to_taxation_success')}`)
				navigate(redirectAfterProcess)
			},
			onError(error) {
				toast.error(error.message || `${t('message.add_to_taxation_success_fail')}`)
			},
		}
	)

	const [addToAuction, { loading: addToAuctionLoading }] = useMutation(
		ADD_TO_AUCTION,
		{
			onCompleted() {
				toast.success(`${t('message.add_to_auction_success')}`)
				navigate(redirectAfterProcess)
			},
			onError(error) {
				toast.error(error.message || `${t('message.add_to_taxation_success_fail')}`)
			},
		}
	)

	const handleSentToAuction = async () => {
		if (!tempAuctionDate) {
			setErrorMessage('message.auction_date_required')
		} else {
			const selectedDate = new Date(tempAuctionDate)
			selectedDate.setHours(selectedTime.split(':')[0])
			selectedDate.setMinutes(selectedTime.split(':')[1])

			await addToAuction({
				variables: {
					machine_id: machineId,
					auction_day_id: new Date(tempAuctionDate).getDay() + 1,
					valid_until: moment(selectedDate).format('YYYY-MM-DD HH:mm:ss'),
					min_bid_amount: minBidAmount,
				},
			})
		}
	}

	const handleSentToTaxation = async () => {
		await addToTaxation({
			variables: {
				machine_id: machineId,
				availability_date: moment(availabilityDate).format('YYYY-MM-DD HH:mm:ss'),
			},
		})
	}

	const breadCrumbs: IBreadCrumbLink[] = [
		{
			label: t('api.machine_list'),
			link: redirectAfterProcess,
		},
	]

	const AsideContent = () => (
		<div className='flex flex-col gap-4 mt-4'>
			<div>
				<button
					id='send_to_taxation'
					type='button'
					className='btn btn-primary btn-sm  rounded-md w-full'
					onClick={() => setTaxationConfirmation(true)}
				>
					{t('api.send_to_taxation')}
				</button>
			</div>
			<div className='divider'>OR</div>
			<div className='flex flex-col gap-3'>
				<div className='flex justify-between items-center'>
					<label className='font-medium  text-primary' htmlFor='valid_util_date'>
						{t(selectedTime ? 'api.auction_end_time' : 'api.auction_date')}
					</label>
					{selectedTime && (
						<span className='font-avenirHeavy'>
							{selectedTime} {t('api.hr')}
						</span>
					)}
				</div>
				<hr />
				<div id='valid_util_date'>
					<div>
						<DatePicker
							dataEnableTime={false}
							value={tempAuctionDate}
							variant='date_picker'
							disabled={isDisabled}
							placeHolder={t('api.select_auction_date')}
							option={{
								minDate: getMinDate(),
								enable: [
									date => {
										if (!auctionDaysLoading && activeDays.length > 0) {
											return activeDays.includes(date.getDay())
										}
										return false
									},
								],
								dateFormat: 'Y-m-d',
							}}
							setDate={date => {
								setTempAuctionDate(new Date(date))
								const day = new Date(date).getDay()
								if (auctionDays) {
									const auctionDay = auctionDays.findAllAuctionWithIsActive.find(
										ad => parseInt(String(ad.id), 10) === day + 1
									)
									if (auctionDay !== undefined) {
										setSelectedTime(auctionDay.time)
									}
								}
							}}
							errorMessage={errorMessage}
						/>
					</div>
				</div>
				<button
					type='button'
					className={`btn btn-secondary btn-sm  rounded-md w-full ${
						tempAuctionDate || 'btn-disabled'
					}`}
					onClick={() => setAuctionConfirmation(true)}
				>
					{t('api.send_to_auction')}
				</button>
			</div>
		</div>
	)

	const userViewRef = createRef<any>()
	const executeScroll = () => {
		userViewRef.current.scrollIntoView({
			top: 0,
			behavior: 'smooth',
		})
	}

	return (
		<>
			<div className='flex flex-col h-full p-4' ref={userViewRef}>
				{role === ROLE.ADMIN && <AdminSubHeader breadCrumbs={breadCrumbs} />}
				{role !== ROLE.ADMIN && (
					<UserSubHeader>
						<div className='flex h-16 w-full gap-8 justify-between items-center pl-6'>
							<div
								role='button'
								tabIndex={0}
								className='flex gap-2 underline items-center hover:text-secondary cursor-pointer'
								onClick={() => navigate(-1)}
								onKeyDown={() => {}}
							>
								<MdOutlineKeyboardBackspace />
								<p>{t('api.back_to_result')}</p>
							</div>
						</div>
					</UserSubHeader>
				)}
				<MachineItemOverview
					asideContent={<AsideContent />}
					hideMagnifier={false}
				/>
				{auctionConfirmation && (
					<AlertModal
						title={t('api.auction_notice')}
						description={t('api.auction_notice_description')}
						cancelOnClick={closeModelHandler}
						okClickLoader={addToAuctionLoading}
						okOnClick={handleSentToAuction}
					>
						<Input
							name='minBidAmount'
							value={minBidAmount}
							type='number'
							onChange={e => setMinBidAmount(parseFloat(e.target.value))}
							label={t('fields.min_bid_amount')}
						/>
					</AlertModal>
				)}
				{taxationConfirmation && (
					<AlertModal
						title={t('api.taxation_notice')}
						description={t('api.taxation_notice_description')}
						cancelOnClick={closeModelHandler}
						okClickLoader={addToTaxationLoading}
						okOnClick={handleSentToTaxation}
						disabled={!availabilityDate}
					>
						<div className='flex flex-col gap-2'>
							<label className='font-medium  text-primary' htmlFor='valid_util_date'>
								{t('api.availability_date')}
							</label>
							<DatePicker
								dataEnableTime={false}
								value={availabilityDate}
								variant='date_picker'
								option={{
									minDate: getMinDate(),
									dateFormat: 'Y-m-d',
								}}
								setDate={date => {
									setAvailabilityDate(new Date(date))
								}}
							/>
						</div>
					</AlertModal>
				)}
			</div>
			<div className='fixed bottom-12 right-10' title='Scroll to top'>
				<div className='h-fit mx-auto container px-2 :p-0'>
					<button
						className='btn  btn-primary rounded-full transition-opacity'
						type='button'
						onClick={() => executeScroll()}
					>
						<BsArrowUp size={20} />
					</button>
				</div>
			</div>
		</>
	)
}

export default MachineUploadOverview
