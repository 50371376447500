import { gql } from '@apollo/client'

const LOGIN = gql`
	mutation Login($email: Email!, $password: String!) {
		login(email: $email, password: $password) {
			access_token
			user {
				id
				email
				can_place_bid
				company {
					id
					company_name
				}
				user_details {
					id
					first_name
					last_name
					mobile_number
				}
				roles {
					id
					role
				}
				company {
				    id
				    company_name
				}
			}
			message
		}
	}
`

const RESET_PASSWORD_LINK = gql`
	mutation ResetPasswordLink($email: Email!) {
		resetPasswordLink(email: $email)
	}
`

const RESET_PASSWORD = gql`
	mutation ResetPassword(
		$email: Email!
		$token: String!
		$password: String!
		$password_confirmation: String!
	) {
		resetPassword(
			email: $email
			token: $token
			password: $password
			password_confirmation: $password_confirmation
		) {
			access_token
			user {
				id
				email
				can_place_bid
				company {
					id
					company_name
				}
				user_details {
					id
					first_name
					last_name
					mobile_number
				}
				roles {
					id
					role
				}
				company {
					id
					company_name
				}
			}
			message
		}
	}
`

const REGISTER_USER = gql`
	mutation RegistrationUser(
		$first_name: String!
		$last_name: String!
		$mobile_number: String
		$email: Email!
		$role_id: ID
		$company_name: String!
		$country_name: String!
		$chamber_of_commerce: String
		$vat_number: String
		$phone_number: String
		$city: String
		$postal_code: String
		$street_name: String
		$street_number: String
		$company_logo: Upload
		$interest_trader: Int
		$locale: String
	) {
		registrationUser(
			first_name: $first_name
			last_name: $last_name
			mobile_number: $mobile_number
			email: $email
			role_id: $role_id
			company_name: $company_name
			country_name: $country_name
			chamber_of_commerce: $chamber_of_commerce
			vat_number: $vat_number
			phone_number: $phone_number
			city: $city
			company_logo: $company_logo
			postal_code: $postal_code
			street_name: $street_name
			street_number: $street_number
			interest_trader: $interest_trader
			locale: $locale
		) {
			id
		}
	}
`

export { LOGIN, RESET_PASSWORD_LINK, RESET_PASSWORD, REGISTER_USER }
