/* eslint-disable */
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import Maintenance from 'modules/maintenance'
import { useAuth } from '../modules/auth/context/useAuth'
import AuthRoutes from './authRoutes'
import PrivateRoute from './privateRoute'
import PrivacyPolicy from 'modules/common/PrivacyPolicy'
import TermAndCondition from 'modules/common/TermAndCondition'
import Help from 'modules/common/Help'
import Unsubscribe from 'modules/errors/Unsubscribe'
import Landing from 'modules/landing'
import NotFound from 'modules/errors/NotFound'

const MainRoute = () => {
	const location = useLocation()
	const { isUserLoggedIn, role } = useAuth()

	const setDefaultPath = '/'
	const isMaintenance: boolean = false

	// @ts-ignore
	const fromLocation = location.state ? location.state.from : location

	if (isUserLoggedIn === null || (isUserLoggedIn && role === null)) {
		return <p>Loading</p>
	}

	if (isMaintenance) {
		return <Maintenance />
	}

	return (
		<Routes>
			{isUserLoggedIn && role && (
				<Route path='/*' element={<PrivateRoute role={role} />} />
			)}
			{!isUserLoggedIn && !role && (
				<Route path='/auth/*' element={<AuthRoutes />} />
			)}
			{!isUserLoggedIn && !role && <Route path='' element={<Landing />} />}
			<Route path='/privacy-policy' element={<PrivacyPolicy />} />
			<Route path='/term-and-condition' element={<TermAndCondition />} />
			<Route path='/help' element={<Help />} />
			<Route path='/unsubscribe/:unsubscribeToken' element={<Unsubscribe />} />
			<Route path='*' element={isUserLoggedIn ? <NotFound /> : <Navigate to="/auth/login" replace state={{ from: fromLocation}}/>} />
		</Routes>
	)
}

export default MainRoute
