import { useTranslation } from 'react-i18next'

import { MdBusiness, MdPerson, MdRoomPreferences } from 'react-icons/md'

import { AiOutlineSecurityScan } from 'react-icons/ai'
import SettingsRoutes from './routes'
import SideBarMenu from '../../../../shared/components/sideBarMenu'
import ROLE from '../../../../enums/Roles'
import { useAuth } from '../../../auth/context/useAuth'

const Index = () => {
	const { t } = useTranslation()
	const { role } = useAuth()

	return (
		<div className='md:h-[calc(100vh-12rem)] mx-auto container'>
			<div className='w-100 md:mt-4 h-full flex md:gap-8 justify-between items-center'>
				<aside className='md:mt-32 md:w-3/12 h-full'>
					<div className='w-full text-center bg-white rounded-tr-md rounded-tl-md shadow-sm p-2 hidden md:block'>
						<h2 className='text-2xl font-avenirRegular'>{t('api.settings')}</h2>
					</div>
					<ul className='w-full h-[calc(86vh-7rem)] md:w-full menu rounded-br-md rounded-bl-md shadow-sm'>
						<SideBarMenu
							logo={<MdBusiness />}
							label={t('api.company_detail')}
							link='company'
							expandSidebar
						/>
						<SideBarMenu
							logo={<MdPerson />}
							label={t('api.accounts')}
							link='user'
							expandSidebar
						/>
						{/* <SideBarMenu */}
						{/*	logo={<MdPrivacyTip />} */}
						{/*	label={t('api.privacy_policy')} */}
						{/*	link='privacy-policy' */}
						{/*	expandSidebar */}
						{/* /> */}
						{/* <SideBarMenu */}
						{/*	logo={<MdConfirmationNumber />} */}
						{/*	label={t('api.terms_of_use')} */}
						{/*	link='term-and-condition ' */}
						{/*	expandSidebar */}
						{/* /> */}
						<SideBarMenu
							logo={<MdRoomPreferences />}
							label={t('api.preferences')}
							link='preferences'
							expandSidebar
						/>

						<SideBarMenu
							logo={<AiOutlineSecurityScan />}
							label={t('api.rules')}
							link='rules'
							expandSidebar
						/>

						{role === ROLE.MANAGER && (
							<SideBarMenu
								logo={<MdRoomPreferences />}
								label={t('api.user_management')}
								link='user-management'
								expandSidebar
							/>
						)}
					</ul>
				</aside>
				<div className='w-5/6 h-full'>
					<div className='p-4 md:px-10 md:py-5 bg-white shadow-sm rounded-md h-[86vh]'>
						<SettingsRoutes />
					</div>
				</div>
			</div>
		</div>
	)
}

export default Index
