import { gql } from '@apollo/client'

export const FIND_ALL_CATEGORIES_ONLY_NAME = gql`
	query FindAllCategories {
		findAllCategories {
			id
			name
		}
	}
`

export const FIND_ALL_TYPES_ONLY_NAME = gql`
	query findAllTypes {
		findAllTypes {
			id
			name
		}
	}
`

export const FIND_ALL_SUBCATEGORIES_ONLY_NAME = gql`
	query FindAllSubcategories {
		findAllSubcategories {
			id
			name
		}
	}
`

export const FIND_TYPE_BY_CATEGORY_ID = gql`
	query FindTypeByCategoryId($category_ids: [Int]!) {
		findTypeByCategory(category_ids: $category_ids) {
			id
			name
		}
	}
`

export const FIND_SUB_CATEGORY_BY_TYPE_ID = gql`
	query FindSubCategoryByTypeIds($type_ids: [Int]!, $category_ids: [Int]) {
		findSubCategoryByTypeIds(type_ids: $type_ids, category_ids: $category_ids) {
			id
			name
		}
	}
`

export const FIND_COMPANY_BY_ID = gql`
	query FindCompanyById($id: ID!) {
		findCompanyById(id: $id) {
			id
			company_name
			country_name
			chamber_of_commerce
			vat_number
			phone_number
			city
			postal_code
			street_name
			street_number
			company_logo
		}
	}
`

export const FIND_PREFERENCE = gql`
	query FindPreference(
		$user_id: ID
	) {
		findPreference(
			user_id: $user_id
		) {
			id
			category_ids
			type_ids
			subcategory_ids
			is_subscribed
		}
	}
`

export const FilterMachine = gql`
	query FindMachineByCompany(
		$category_ids: [Int]
		$type_ids: [Int]
		$subcategory_ids: [Int]
		$status: [Int]
		$bid_status: [Int]
		$first: Int!
		$page: Int
	) {
		findMachineByCompany(
			category_ids: $category_ids
			type_ids: $type_ids
			subcategory_ids: $subcategory_ids
			status: $status
			bid_status: $bid_status
			first: $first
			page: $page
		) {
			paginatorInfo {
				count
				currentPage
				hasMorePages
				total
			}
			data {
				id
				category {
					id
					name
				}
				type {
					id
					name
				}
				subcategory {
					id
					name
				}
				images {
					thumbnail_image
					image
				}
				brand_id
				brand
				model_id
				model
				machine_type
				year_of_manufacture
				year_of_manufacture_id
				licence_plate
				licence_plate_id
				running_hours
				min_bid_amount
				customer_name
				location
				status
				bid_status
				is_archive
				valid_until
				auctions {
					id
					valid_until
				}
				availability_date
				bid {
					id
					valid_until
				}
			}
		}
	}
`

export const FILTER_MACHINE_FOR_MACHINE_DETAILS = gql`
	query FindMachineByCompany(
		$category_ids: [Int]
		$type_ids: [Int]
		$subcategory_ids: [Int]
		$status: [Int]
		$bid_status: [Int]
		$page: Int
	) {
		findMachineByCompany(
			category_ids: $category_ids
			type_ids: $type_ids
			subcategory_ids: $subcategory_ids
			status: $status
			bid_status: $bid_status
			first: 1
			page: $page
		) {
			paginatorInfo {
				count
				currentPage
				hasMorePages
				total
				lastPage
			}
			data {
				id
			}
		}
	}
`

export const FIND_AUCTION_MACHINE = gql`
	query filterAuctionMachine(
		$categories_ids: [Int]
		$type_ids: [Int]
		$subcategory_ids: [Int]
		$auction_day_id: ID
		$first: Int!
		$page: Int!
	) {
		filterAuctionMachine(
			category_ids: $categories_ids
			type_ids: $type_ids
			subcategory_ids: $subcategory_ids
			auction_day_id: $auction_day_id
			first: $first
			page: $page
		) {
			total
			per_page
			current_page
			last_page
			data {
				auction {
					id
					machine {
						id
						category {
							id
							name
						}
						type {
							id
							name
						}
						subcategory {
							id
							name
						}
						images {
							thumbnail_image
							image
						}
						status
						bid_status
						brand_id
						brand
						model_id
						model
						year_of_manufacture
						year_of_manufacture
						customer_name
						location
						machine_type
						licence_plate
						licence_plate_id
						running_hours
						min_bid_amount
						valid_until
						is_archive
					}
					valid_until
				}
				bid {
					id
					value
					value_currency
					status
					user {
						id
						company_id
					}
				}
			}
		}
	}
`

export const FIND_AUCTION_MACHINE_FOR_MACHINE_DETAIL = gql`
	query filterAuctionMachine(
		$categories_ids: [Int]
		$type_ids: [Int]
		$subcategory_ids: [Int]
		$auction_day_id: ID
		$page: Int!
	) {
		filterAuctionMachine(
			category_ids: $categories_ids
			type_ids: $type_ids
			subcategory_ids: $subcategory_ids
			auction_day_id: $auction_day_id
			first: 1
			page: $page
		) {
			total
			per_page
			current_page
			last_page
			data {
				auction {
					machine {
						id
					}
				}
			}
		}
	}
`

export const FIND_MY_TAXATION = gql`
	query FindMyTaxationWithPagination(
		$category_ids: [Int]
		$type_ids: [Int]
		$subcategory_ids: [Int]
		$page: Int!
		$first: Int!
	) {
		findMyTaxationWithPagination(
			category_ids: $category_ids
			type_ids: $type_ids
			subcategory_ids: $subcategory_ids
			page: $page
			first: $first
		) {
			paginatorInfo {
				count
				currentPage
				hasMorePages
				total
				lastPage
			}
			data {
				machine_id
				machine_status
				machine_image
				category {
					id
					name
				}
				type {
					id
					name
				}
				subcategory {
					id
					name
				}
				bids_status
				brand_id
				brand
				model_id
				model
				year_of_manufacture
				customer_name
				location
				machine_type
				licence_plate
				licence_plate_id
				running_hours
				min_bid_amount
				availability_date
				value
				user_id
				company_id
				is_archive
			}
		}
	}
`

export const FIND_MY_TAXATION_FOR_MACHINE_DETAIL = gql`
	query FindMyTaxationWithPagination(
		$category_ids: [Int]
		$type_ids: [Int]
		$subcategory_ids: [Int]
		$page: Int!
	) {
		findMyTaxationWithPagination(
			category_ids: $category_ids
			type_ids: $type_ids
			subcategory_ids: $subcategory_ids
			page: $page
			first: 1
		) {
			paginatorInfo {
				count
				currentPage
				hasMorePages
				total
				lastPage
			}
			data {
				id
				machine_id
			}
		}
	}
`

export const FIND_ALL_BIDDED = gql`
	query filterBiddedMachine(
		$category_ids: [Int]
		$type_ids: [Int]
		$subcategory_ids: [Int]
		$status: [Int]
		$first: Int!
		$page: Int!
	) {
		filterBiddedMachine(
			category_ids: $category_ids
			type_ids: $type_ids
			subcategory_ids: $subcategory_ids
			status: $status
			first: $first
			page: $page
		) {
			total
			per_page
			current_page
			last_page
			data {
				machine {
					id
					category {
						id
						name
					}
					type {
						id
						name
					}
					subcategory {
						id
						name
					}
					images {
						thumbnail_image
						image
					}
					status
					brand_id
					brand
					model_id
					model
					availability_date
					machine_type
					year_of_manufacture
					customer_name
					location
					machine_type
					licence_plate
					licence_plate_id
					running_hours
					min_bid_amount
					valid_until
					bid_status
					is_archive
					auctions {
						id
						valid_until
					}
				}
				bid {
					id
					value
					value_currency
					status
					user {
						id
						company_id
					}
				}
			}
		}
	}
`

export const FIND_BIDDED_MACHINE_FOR_MACHINE_DETAIL = gql`
	query filterBiddedMachine(
		$category_ids: [Int]
		$type_ids: [Int]
		$subcategory_ids: [Int]
		$status: [Int]
		$page: Int!
	) {
		filterBiddedMachine(
			category_ids: $category_ids
			type_ids: $type_ids
			subcategory_ids: $subcategory_ids
			status: $status
			first: 1
			page: $page
		) {
			total
			per_page
			current_page
			last_page
			data {
				machine {
					id
				}
			}
		}
	}
`

export const CALCULATE_COMMISSION = gql`
	query allMachineCalculation($machine_id: ID!, $value: Float!) {
		allMachineCalculation(machine_id: $machine_id, value: $value)
	}
`
export const GET_COMMISSION_VIA_MACHINE = gql`
	query commissionInformationSingleInfo($machine_id: ID!) {
		commissionInformationSingleInfo(machine_id: $machine_id)
	}
`
