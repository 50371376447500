import { gql } from '@apollo/client'

export const FIND_ALL_USERS = gql`
	query findAllUsers {
		findAllUsers {
			id
			email
			is_enabled
			created_at
			can_place_bid
			roles {
				id
				role
			}
			company {
			    id
			    company_name
			}
		}
	}
`

export const FIND_ALL_USERS_BY_COMPANY_ID = gql`
	query FindAllUserByCompanyId($id: ID!) {
		findUsersByCompanyId(company_id:$id) {
			id
			email
			is_enabled
			created_at
			can_place_bid
			roles {
				id
				role
			}
			company {
			    id
			    company_name
			}
		}
	}
`

export const FIND_ALL_COMPANY_ONLY_NAME = gql`
    query findAllCompany {
      findAllCompany {
        id
        company_name
      }
    }
`

export const FIND_ALL_USERS_EMAIL = gql`
	query findAllUsers {
		findAllUsers {
			id
			email
		}
	}
`

export const FIND_ALL_ROLES = gql`
	query FindAllRoles {
		findAllRoles {
			id
			role
		}
	}
`

export const FIND_USER_BY_ID = gql`
    query FindUserById($id: ID!) {
        findUserById (id: $id) {
            id
												email
												company {
													id
													company_name
												}
												user_details {
													id
													first_name
													last_name
													mobile_number
												}
												roles {
													id
													role
												}
												company {
																id
																company_name
												}
        }
    }    
`
