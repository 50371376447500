import { gql } from '@apollo/client'

const TOGGLE_USER_STATUS = gql`
	mutation ToggleUserStatus($id: ID!, $remark: String!) {
		toggleUserStatus(id: $id, remark: $remark) {
			id
		}
	}
`

const TOGGLE_USER_CAN_PLACE_BID = gql`
	mutation ToggleUserCanPlaceBid($id: ID!) {
		toggleUserCanPlaceBid(id: $id) {
			id
		}
	}
`

const REGISTER_USER = gql`
	mutation RegisterUser(
		$email: Email!
		$roleId: ID!
		$companyId: ID!
		$canPlaceBid: Boolean
	) {
		registerUser(
			email: $email
			role_id: $roleId
			company_id: $companyId
			can_place_bid: $canPlaceBid
		) {
			id
		}
	}
`

const UPDATE_USER = gql`
	mutation updateUser(
		$id: ID!
		$email: Email!
		$roleId: ID
		$companyId: ID!
		$canPlaceBid: Boolean
	) {
		updateUser(
			id: $id
			email: $email
			role_id: $roleId
			company_id: $companyId
			can_place_bid: $canPlaceBid
		) {
			id
		}
	}
`

const REMOVE_COMPANY = gql`
	mutation RemoveUser($id: ID!) {
		removeUser(id: $id) {
			id
		}
	}
`

export {
	TOGGLE_USER_STATUS,
	TOGGLE_USER_CAN_PLACE_BID,
	REGISTER_USER,
	UPDATE_USER,
	REMOVE_COMPANY,
}
