import MainRoute from 'routes'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Footer from 'shared/components/Footer'
import { useEffect } from 'react'
import useTranslationData from './modules/translation/hooks/useTranslationData'

const App = () => {
	const { getTranslations, setDefaultLanguage } = useTranslationData()
	useEffect(() => {
		const fetchTranslations = async () => {
			await getTranslations()
			await setDefaultLanguage()
		}
		fetchTranslations().catch(() => toast.error('Failed To load Translation'))
	}, [])



	if (typeof window !== 'undefined') {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		})
	}

	return (
		<div className='h-full w-full flex flex-col' data-theme='mach'>
			<div className='h-[96vh] overflow-auto'>
				<MainRoute />
			</div>
			<div className='h-[4vh]'>
				<Footer />
			</div>
			<ToastContainer
				toastClassName='break-all h-fit'
				hideProgressBar
				closeButton={false}
				autoClose={2000}
			/>
		</div>
	)
}

export default App
