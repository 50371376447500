import { useQuery } from '@apollo/client'
import { FormikProps } from 'formik'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import Collapse from '../../../../shared/components/Collapse'
import { ICategoryData } from '../../../admin/views/Category/graphql/model'
import {
	ISubcategory,
	ISubcategoryData,
} from '../../../admin/views/Subcategories/graphql/model'
import { IType, ITypeData } from '../../../admin/views/Types/graphql/model'
import Checkbox from '../../../../shared/components/Checkbox'
import { FIND_ALL_SUBCATEGORIES } from '../../../admin/views/Subcategories/graphql/query'
import {
	FIND_ALL_CATEGORIES_ONLY_NAME,
	FIND_ALL_TYPES_ONLY_NAME,
	FIND_SUB_CATEGORY_BY_TYPE_ID,
	FIND_TYPE_BY_CATEGORY_ID,
} from '../../graphql/query'
import DAYS from '../../../../enums/DAYS'
import BackDrop from '../../../../shared/components/backDrop'
import './userSidebar.css'
import { BREAK_POINT } from '../../../../utils/constant'

export interface ISideBarForm {
	selectedCategories: number[]
	selectedTypes: number[]
	selectedSubCategories: number[]
	selectedDate?: DAYS
	search: string
	status?: number[]
}

interface IUserSideBarProps {
	formik: FormikProps<ISideBarForm>
	backDrop: boolean
	hideType?: boolean
	hideCategory?: boolean
	setBackDrop: (value: boolean) => void
	setPageNumber: (value: number) => void
}

const UserSidebar = (props: IUserSideBarProps) => {
	const {
		formik,
		backDrop,
		setBackDrop,
		setPageNumber,
		hideType,
		hideCategory,
	} = props

	const { t } = useTranslation()

	const { data: categories } = useQuery<ICategoryData>(
		FIND_ALL_CATEGORIES_ONLY_NAME
	)
	const { data: types } = useQuery<ITypeData>(FIND_ALL_TYPES_ONLY_NAME)
	const { data: subCategories } = useQuery<ISubcategoryData>(
		FIND_ALL_SUBCATEGORIES
	)

	const [filterTypes, setFilterTypes] = useState<IType[]>()
	const [filterSubCategory, setFilterSubCategory] = useState<ISubcategory[]>()

	const { data: typeByCategory } = useQuery<ITypeData>(
		FIND_TYPE_BY_CATEGORY_ID,
		{
			skip: Boolean(!formik.values.selectedCategories.length),
			variables: {
				category_ids: formik.values.selectedCategories,
			},
		}
	)

	const { data: subCategoryByType } = useQuery<ISubcategoryData>(
		FIND_SUB_CATEGORY_BY_TYPE_ID,
		{
			skip: Boolean(
				!(
					formik.values.selectedTypes.length ||
					formik.values.selectedCategories.length
				)
			),
			variables: {
				type_ids: formik.values.selectedTypes,
				category_ids: formik.values.selectedCategories,
			},
		}
	)

	useEffect(() => {
		if (formik.values.selectedCategories.length > 0) {
			typeByCategory?.findTypeByCategory &&
				setFilterTypes(typeByCategory?.findTypeByCategory)
		} else {
			types?.findAllTypes && setFilterTypes(types?.findAllTypes)
		}
	}, [formik.values.selectedCategories.length, types, typeByCategory])

	useEffect(() => {
		if (
			formik.values.selectedCategories.length > 0 ||
			formik.values.selectedTypes.length > 0
		) {
			subCategoryByType?.findSubCategoryByTypeIds &&
				setFilterSubCategory(subCategoryByType.findSubCategoryByTypeIds)
		} else {
			subCategories?.findAllSubcategories &&
				setFilterSubCategory(subCategories.findAllSubcategories)
		}
	}, [formik.values.selectedTypes, subCategories, subCategoryByType])

	const handleChange = (
		id: number,
		type: 'category' | 'type' | 'subCategory'
	) => {
		setPageNumber(1)
		switch (type) {
			case 'category':
				if (formik.values.selectedCategories.includes(id)) {
					const tempArray = formik.values.selectedCategories
					formik.setFieldValue(
						'selectedCategories',
						tempArray.filter((categoryId: any) => categoryId !== id)
					)
				} else {
					formik.setFieldValue('selectedCategories', [
						...formik.values.selectedCategories,
						id,
					])
				}
				break
			case 'type':
				if (formik.values.selectedTypes.includes(id)) {
					const tempArray = formik.values.selectedTypes
					formik.setFieldValue(
						'selectedTypes',
						tempArray.filter((typeId: any) => typeId !== id)
					)
				} else {
					formik.setFieldValue('selectedTypes', [...formik.values.selectedTypes, id])
				}
				break
			case 'subCategory':
				if (formik.values.selectedSubCategories.includes(id)) {
					const tempArray = formik.values.selectedSubCategories
					formik.setFieldValue(
						'selectedSubCategories',
						tempArray.filter((subCategoryId: any) => subCategoryId !== id)
					)
				} else {
					formik.setFieldValue('selectedSubCategories', [
						...formik.values.selectedSubCategories,
						id,
					])
				}
				break
			default:
		}
	}

	const width = window.innerWidth

	return (
		<>
			<BackDrop openNavBar={backDrop} setBackDropStatus={setBackDrop} />
			<div
				className={`${
					width > BREAK_POINT
						? 'sidebar-style'
						: `sidebar-mobile-style ${backDrop ? 'sidebar-open' : 'sidebar-close'}`
				}`}
			>
				<form
					onSubmit={formik.handleSubmit}
					className='flex flex-col h-fit sidebar-style overflow-auto px-2 gap-4'
				>
					<Collapse
						title='Categories'
						openByDefault
						body={
							<ul className='mt-2 overflow-auto md:overflow-visible h-full w-full flex flex-col px-2'>
								{categories?.findAllCategories?.map((category, index) => (
									<Checkbox
										key={index}
										name={category.name.replace('selectedCategories.', '')}
										checked={formik.values.selectedCategories!.includes(
											parseInt(category.id.toString(), 10)
										)}
										onChange={async () => {
											handleChange(parseInt(category.id.toString(), 10), 'category')
										}}
										labelStyles='break-normal font-normal text-lg'
										label={t(`categories.${category.name}`)}
									/>
								))}
							</ul>
						}
					/>
					{!hideType && (
						<Collapse
							title='Type'
							body={
								<ul className='mt-2 h-full overflow-auto md:overflow-visible w-full flex flex-col px-2'>
									{filterTypes &&
										filterTypes.map((type, index) => (
											<Checkbox
												key={index}
												name={type.name.replace('type.', '')}
												checked={formik.values.selectedTypes!.includes(
													parseInt(type.id.toString(), 10)
												)}
												onChange={async () => {
													handleChange(parseInt(type.id.toString(), 10), 'type')
												}}
												labelStyles='break-normal font-normal text-lg'
												label={t(`type.${type.name}`)}
											/>
										))}
								</ul>
							}
						/>
					)}
					{!hideCategory && (
						<Collapse
							title='Sub Categories'
							body={
								<ul className='mt-2 h-full overflow-auto md:overflow-visible w-full flex flex-col px-2'>
									{filterSubCategory &&
										filterSubCategory.map((subCategory, index) => (
											<Checkbox
												key={index}
												name={subCategory.name.replace('subcategory.', '')}
												checked={formik.values.selectedSubCategories!.includes(
													parseInt(subCategory.id.toString(), 10)
												)}
												onChange={async () => {
													handleChange(
														parseInt(subCategory.id.toString(), 10),
														'subCategory'
													)
												}}
												labelStyles='break-normal font-normal text-lg'
												label={t(`subcategory.${subCategory.name}`)}
											/>
										))}
								</ul>
							}
						/>
					)}
				</form>
			</div>
		</>
	)
}

export default UserSidebar
