interface IMachineCard {
	image: string
	name: string
	description: string
}

const MachineCard = (props: IMachineCard) => {
	const { image, name, description } = props

	return (
		<div className='card flex-1 rounded-none hover:shadow-md hover:brightness-90 cursor-pointer'>
			<figure className="h-64">
				<img src={image} alt={name} className='object-cover h-full' loading="lazy"/>
			</figure>
			<div className='card-body p-3 md:p-4 md:p-6 bg-primary text-white'>
				<h2 className='card-title uppercase'>{name}</h2>
				<p>{description}</p>
			</div>
		</div>
	)
}

export default MachineCard
