import { useTranslation } from 'react-i18next'
import { FormikHelpers, FormikProps, useFormik } from 'formik'
import * as Yup from 'yup'
import { useNavigate, useParams } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import { toast } from 'react-toastify'
import { ChangeEvent, useState } from 'react'

import { IRole, IRoleData, IUserData, IUserFormData } from '../graphql/model'
import Input from '../../../../../shared/components/Input'

import { REGISTER_USER, UPDATE_USER } from '../graphql/mutation'
import { FIND_ALL_COMPANY_ONLY_NAME, FIND_ALL_ROLES, FIND_ALL_USERS, FIND_USER_BY_ID } from '../graphql/query'
import Loader from '../../../../../shared/components/loader'
import { IOptions } from '../../../../../shared/components/Select'
import { IBreadCrumbLink } from '../../../components/AdminSubHeader/model'
import AdminSubHeader from '../../../components/AdminSubHeader'
import MultiSelect from '../../../../../shared/components/MultiSelect'
import { ICompanyData } from '../../../../auth/graphql/model'
import { useAuth } from '../../../../auth/context/useAuth'
import ROLE from '../../../../../enums/Roles'
import Checkbox from '../../../../../shared/components/Checkbox'
import { FIND_COMPANY_BY_ID } from '../../../../user/graphql/query'

interface IUserFormProps {
	breadCrumbProps?: IBreadCrumbLink[]
}

const UserForm = (props: IUserFormProps) => {
	const { breadCrumbProps } = props

	const { id, userId, companyId } = useParams()
	const { t } = useTranslation()
	const { user, role } = useAuth()
	const navigate = useNavigate()
	const [rolesOptions, setRolesOptions] = useState<IOptions[]>([])
	const [companyOptions, setCompanyOptions] = useState<IOptions[]>([])

	const breadCrumb: IBreadCrumbLink[] = breadCrumbProps || [
		{
			label: t('api.user_management'),
			link: '/admin/user-management'
		},
		{
			label: t('api.create'),
			link: ''
		}
	]

	const goBack = () => {
		navigate(-1)
	}

	const { loading: rolesLoading } = useQuery<IRoleData>(FIND_ALL_ROLES, {
		onCompleted: data => {
			const tempRolesOptions: IOptions[] = data?.findAllRoles.map(
				(userRole: IRole): IOptions => ({
					label: userRole.role,
					value: userRole.id
				})
			)
			if (role !== ROLE.ADMIN) {
				const filterTempOption = tempRolesOptions.filter(
					userRole => userRole.value.toString() !== ROLE.ADMIN.toString()
				)
				setRolesOptions(filterTempOption)
			} else {
				setRolesOptions(tempRolesOptions)
			}
		}
	})

	useQuery<ICompanyData>(FIND_ALL_COMPANY_ONLY_NAME, {
		skip: !!companyId,
		onCompleted: data => {
			const companyOption: IOptions[] = data.findAllCompany.map(data => ({
				label: data.company_name,
				value: data.id
			}))
			setCompanyOptions(companyOption)
		},
		onError: error => {
			toast.error(error.message || t('message.failed_to_load_company'))
		}
	})

	useQuery(FIND_COMPANY_BY_ID, {
		skip: !companyId,
		variables: {
			id: companyId
		},
		onCompleted: data => {
			const companyOption: IOptions[] = data.findCompanyById.map((data: any) => ({
				label: data.company_name,
				value: data.id
			}))
			setCompanyOptions(companyOption)
		},
		onError: error => {
			toast.error(error.message || t('message.failed_to_load_company'))
		}
	})

	const [registerUser, { loading }] = useMutation(REGISTER_USER, {
		onCompleted() {
			toast.success(`${t('message.user_add_success')}`)
			goBack()
		},
		onError(error) {
			toast.error(error.message || `${t('message.user_add_fail')}`)
		},
		refetchQueries: [{ query: FIND_ALL_USERS }]
	})

	const [updateUser, { loading: updateLoading }] = useMutation(UPDATE_USER, {
		onCompleted() {
			toast.success(`${t('message.user_update_success')}`)
			goBack()
		},
		onError(error) {
			toast.error(error.message || `${t('message.user_update_fail')}`)
		},
		refetchQueries: [{ query: FIND_ALL_USERS }]
	})

	const formik: FormikProps<IUserFormData> = useFormik<IUserFormData>({
		initialValues: {
			email: '',
			roleId: 1,
			companyId: role !== ROLE.ADMIN ? user.company.id : 0,
			canPlaceBid: false
		},
		validationSchema: Yup.object().shape({
			email: Yup.string()
				.email('message.enter_valid_email')
				.required('message.email_is_required'),
			roleId: Yup.number().required('message.role_is_required')
		}),
		onSubmit: async (values, { setSubmitting }: FormikHelpers<IUserFormData>) => {
			if (id || userId) {
				await updateUser({
					variables: {
						id: id || userId,
						email: values.email,
						roleId: values.roleId,
						companyId: values.companyId,
						canPlaceBid: values.canPlaceBid
					}
				})
			} else {
				await registerUser({
					variables: {
						email: values.email,
						roleId: values.roleId,
						companyId: values.companyId,
						canPlaceBid: values.canPlaceBid
					}
				})
			}
			setSubmitting(false)
		}
	})

	useQuery<IUserData>(FIND_USER_BY_ID, {
		skip: !(id || userId),
		variables: { id: parseInt(id || userId || '', 10) },
		onCompleted: data => {
			formik.setFieldValue('email', data.findUserById?.email)
			formik.setFieldValue(
				'roleId',
				data.findUserById?.roles &&
				data.findUserById?.roles.length > 0 &&
				data.findUserById?.roles[0].id
			)
			formik.setFieldValue('companyId', data.findUserById?.company.id)
			formik.setFieldValue('canPlaceBid', data.findUserById?.can_place_bid)
		},
		onError: error => {
			toast.success(error.message || t('message.failed_to_load_user'))
		}
	})

	const onEmailChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target
		formik.setFieldValue('email', value.toLowerCase())
	}

	return (
		<>
			<AdminSubHeader breadCrumbs={role === ROLE.ADMIN ? breadCrumb : null} />
			<div className='h-[75vh] w-full md:w-[800px] mx-auto flex flex-col items-center'>
				{rolesLoading && (
					<div className='w-full h-full justify-center items-center'>
						<Loader />
					</div>
				)}
				{!rolesLoading && (
					<div className='w-full md:w-[800px] mx-auto'>
						<form
							className='p-5 rounded w-full flex flex-col gap-5 bg-white shadow-sm'
							onSubmit={formik.handleSubmit}
						>
							<Input
								type='email'
								value={formik.values.email}
								name='email'
								onChange={e => onEmailChangeHandler(e)}
								label={t('fields.email')}
								errorMessage={formik.touched.email ? formik.errors.email : ''}
							/>
							<MultiSelect
								variant='single'
								options={rolesOptions}
								name='roleId'
								value={formik.values.roleId}
								label={t('fields.role')}
								onChange={value => formik.setFieldValue('roleId', value)}
								errorMessage={formik.touched.roleId ? formik.errors.roleId : ''}
							/>
							{role === ROLE.ADMIN && (
								<MultiSelect
									variant='single'
									options={companyOptions}
									name='companyId'
									value={formik.values.companyId}
									label={t('fields.company_name')}
									onChange={value => formik.setFieldValue('companyId', value)}
									errorMessage={formik.touched.companyId ? formik.errors.companyId : ''}
								/>
							)}
							{formik.values.roleId.toString() === ROLE.USER.toString() && (
								<Checkbox
									name='canPlaceBid'
									label='fields.can_place_bid'
									checked={formik.values.canPlaceBid}
									onChange={() =>
										formik.setFieldValue('canPlaceBid', !formik.values.canPlaceBid)
									}
								/>
							)}
							<div className='flex justify-end items-center gap-4'>
								<button
									type='button'
									className='btn btn-outline btn-sm rounded-md '
									onClick={goBack}
								>
									{t('api.cancel')}
								</button>
								<button type='submit'
																className={`btn btn-primary btn-sm  rounded-md ${loading && updateLoading && 'btn-disabled loading'}`}>
									{id || userId ? (
										t('api.edit')
									) : (
										t('api.save')
									)}
								</button>
							</div>
						</form>
					</div>
				)}
			</div>
		</>
	)
}

export default UserForm
