import { gql } from '@apollo/client'

export const FIND_ALL_AUCTION_DAYS = gql`
	query FindAllAuctionDays {
		findAllAuctionDays {
			id
			day
			time
			is_active
		}
	}
`

export const FIND_ALL_AUCTION_WITH_IS_ACTIVE = gql`
	query findAllAuctionWithIsActive {
		findAllAuctionWithIsActive {
			id
			day
			time
		}
	}
`

export const FIND_AUCTION_DAY_BY_ID = gql`
	query FindAuctionDayById($id: Int!) {
		findAuctionDayById(id: $id) {
			id
			day
			time
			is_active
		}
	}
`
