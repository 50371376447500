/* eslint-disable */
import User from 'assets/images/user.png'
import LogoMunsterman from 'assets/images/LogoMunsterman.png'
import LogoFeetra from 'assets/images/logoFeetra.png'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import TestimonialCard from './testimonialCard'

const Testimonial = () => {
	const { t } = useTranslation()
	const [activeIndex, setActiveIndex] = useState<number>(0)
	const [testimonialCardWidth, setTestimonialCardWidth] = useState<number>(0)
	const testimonialCardRef = useRef<HTMLDivElement>(null)
	const testimonialContainerRef = useRef<HTMLDivElement>(null)

	const testimonialData: any[] = [
		{
			name: 'Wouter Eevers',
			company: 'FEETRA BVBA',
			position: t('api.company_1_commenter_position'),
			image: LogoFeetra,
			rating: 4,
			description: t('api.company_1_comment'),
		},
		{
			name: 'Michiel Munsterman',
			company: 'MUNSTERMAN',
			position: t('api.company_2_commenter_position'),
			image: LogoMunsterman,
			rating: 4,
			description: t('api.company_2_comment'),
		},
		// {
		// 	name: 'Michiel Munsterman',
		// 	position: 'Munsterman',
		// 	image: User,
		// 	rating: 4,
		// 	description:
		// 		'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam non fugiat, blanditiis impedit ut mollitia asperiores dolorem minus excepturieum voluptatum est cupiditate corrupti quia odio reiciendis natus. Placeat, labore? Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam non fugiat, blanditiis impedit ut mollitia asperiores dolorem minus excepturieum voluptatum est cupiditate corrupti quia odio reiciendis natus. Placeat, labore? Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam non fugiat, blanditiis impedit ut mollitia asperiores dolorem minus excepturieum voluptatum est cupiditate corrupti quia odio reiciendis natus. Placeat, labore?',
		// },
	]

	useEffect(() => {
		if (testimonialCardRef?.current?.offsetWidth) {
			setTestimonialCardWidth(testimonialCardRef?.current?.offsetWidth)
		}
	}, [])

	const handleNext = () => {
		if (testimonialData.length - 1 > activeIndex) {
			setActiveIndex(activeIndex + 1)
			testimonialContainerRef.current?.scrollTo(
				testimonialCardWidth * (activeIndex + 1),
				0
			)
		} else {
			setActiveIndex(0)
			testimonialContainerRef.current?.scrollTo(0, 0)
		}
	}

	const handlePrev = () => {
		if (activeIndex > 0) {
			testimonialContainerRef.current?.scrollTo(
				testimonialCardWidth * (activeIndex - 1),
				0
			)
			setActiveIndex(activeIndex - 1)
		} else {
			testimonialContainerRef.current?.scrollTo(
				testimonialCardWidth * (testimonialData.length - 1),
				0
			)
			setActiveIndex(testimonialData.length - 1)
		}
	}

	return (
		<div className='flex flex-col lg:flex-row gap-6 md:gap-12'>
			<div
				className='carousel gap-2 flex w-full'
				ref={testimonialContainerRef}
			>
				{testimonialData.map((data, index: number) => (
					<div key={index} className='carousel-item w-full lg:w-[calc(50%-0.25rem)]' ref={testimonialCardRef}>
						<TestimonialCard
							name={data.name}
							description={data.description}
							image={data.image}
							position={data.position}
							company={data.company}
							// rating={data.rating}
							handlePrev={handlePrev}
							handleNext={handleNext}
							index={index}
							length={testimonialData.length}
						/>
					</div>
				))}
			</div>
			<div className='flex flex-col gap-4 lg:gap-8'>
				<h2 className='text-xl lg:text-5xl leading-tight uppercase font-extrabold max-w-sm'>
					{t('api.what_coustomers_says_about_us')}
				</h2>
				{/* {!isUserLoggedIn && <button
					type='button'
					className='btn btn-primary w-48'
					onClick={() => navigate('auth/signup')}
				>
					{t('api.create_an_account')}
				</button>} */}
				<div className='hidden gap-4 items-center'>
					<button
						className='h-8 w-8 flex justify-center items-center hover:brightness-75 bg-black rounded-full text-white'
						onClick={handlePrev}
					>
						❮
					</button>
					{testimonialData.map((data, index: number) => (
						<span
							key={index}
							className={`w-4 h-4 rounded-full border border-primary ${
								index === activeIndex && 'bg-primary'
							}`}
						/>
					))}
					<button
						className='h-8 w-8 bg-black rounded-full flex justify-center hover:brightness-75 items-center text-white'
						onClick={handleNext}
					>
						❯
					</button>
				</div>
			</div>
		</div>
	)
}

export default Testimonial
