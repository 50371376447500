/* eslint-disable camelcase */
import { MdExitToApp } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import userImage from 'assets/images/user.png'
import ROLE from 'enums/Roles'
import machMachineryLogo from '../../../assets/images/MACH.png'
import UserProfileMenuItem from '../../../shared/components/UserProfileMenuItem'
import { useAuth } from '../../auth/context/useAuth'
import Translation from '../../translation'

interface AdminHeaderProps {
	noHeader?: boolean
}

const getInitialLetter = (text: string) => text.charAt(0)

const AdminHeader = (props: AdminHeaderProps) => {
	const { noHeader } = props
	const navigate = useNavigate()
	const {
		logoutUser,
		role,
		user: { user_details, email },
	} = useAuth()

	return (
		<div className='h-[8vh] flex items-center border-b-2'>
			<div
				role='button'
				aria-hidden
				className='logo h-full p-4 cursor-pointer w-fit md:w-1/6'
				onClick={() => navigate('/')}
			>
				<img
					src={machMachineryLogo}
					alt='Mach Machinery'
					className='h-full object-contain'
				/>
			</div>
			{!noHeader && (
				<div className='flex justify-end items-center px-8 gap-4 h-full w-ful flex-1'>
					<Translation />
					<div className='dropdown dropdown-hover dropdown-end'>
						<div className='m-1'>
							{user_details ? (
								<button
									type='button'
									className='w-8 rounded-full bg-base-300 text-center grid place-content-center h-8'
									name='user-profile'
								>
									<span className='text-md leading-4 tracking-widest'>
										{`${getInitialLetter(user_details?.first_name)}${getInitialLetter(
											user_details?.last_name
										)}`}
									</span>
								</button>
							) : (
								<button type='button' className='avatar' name='user-profile'>
									<div className='w-8 rounded-full'>
										<img src={userImage} alt='user' />
									</div>
								</button>
							)}
						</div>
						<ul className='dropdown-content menu rounded-md shadow bg-white'>
							{/* <UserProfileMenuItem icon={<MdSettings />} label='Setting' /> */}
							<div className='border-b-neutral flex flex-col flex-nowrap items-start px-4 py-4'>
								{user_details && (
									<span>{`${user_details.first_name} ${user_details.last_name}`}</span>
								)}
								<span className='text-secondary'>{email || ''}</span>
								<span className='text-gray-500 capitalize'>
									{ROLE[role || 3].toLocaleLowerCase()}
								</span>
							</div>
							<hr />
							<UserProfileMenuItem
								icon={<MdExitToApp />}
								label='Logout'
								onClickHandler={logoutUser}
							/>
						</ul>
					</div>
				</div>
			)}
		</div>
	)
}

export default AdminHeader
