import { MdLanguage } from 'react-icons/md'
import useTranslationData from './hooks/useTranslationData'

type Options = {
	label: string
	value: string
}

const Translation = () => {
	const { selectedLanguage, handleLangChanged } = useTranslationData()

	const languageList: Options[] = [
		{ label: 'nl', value: 'nl' },
		{ label: 'en', value: 'en' },
	]

	return (
		<div className='flex justify-start items-center text-gray-600 text-xl ml-4'>
			<div>
				<MdLanguage />
			</div>
			<select
				name='Language'
				className='outline-none bg-transparent w-8 md:w-fit underline appearance-none pl-2 md:px-4 cursor-pointer'
				value={selectedLanguage}
				onChange={event => handleLangChanged(event.target.value)}
			>
				{languageList.map((option: Options, index: number) => (
					<option key={`${index}`} value={option.value}>
						{option.label}
					</option>
				))}
			</select>
		</div>
	)
}

export default Translation
