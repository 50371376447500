import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { useState } from 'react'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import { FormikHelpers, FormikProps, useFormik } from 'formik'
import { useParams } from 'react-router-dom'
import { IResetPassword } from '../graphql/model'
import Input from '../../../shared/components/Input'
import ButtonLoader from '../../../shared/components/buttonLoader'
import { useAuth } from '../context/useAuth'

const ResetPasswordForm = () => {
	const { token } = useParams()
	const { t } = useTranslation()
	const { resetPasswordAndLogin, resetPasswordLoading } = useAuth()
	const [showPass, setShowPass] = useState<boolean>(false)
	const [showConfirmPass, setShowConfirmPass] = useState<boolean>(false)

	const formik: FormikProps<IResetPassword> = useFormik<IResetPassword>({
		validateOnChange: false,
		initialValues: {
			email: '',
			token: token || '',
			password: '',
			confirmPassword: '',
		},
		validationSchema: Yup.object().shape({
			email: Yup.string()
				.email('message.enter_valid_email')
				.required('message.email_is_required'),
			token: Yup.string().required('message.token_is_required'),
			password: Yup.string().required('message.password_is_required'),
			confirmPassword: Yup.string()
				.required('message.confirm_password')
				.oneOf([Yup.ref('password'), null], 'message.password_no_match'),
		}),
		onSubmit: async (
			values,
			{ setSubmitting }: FormikHelpers<IResetPassword>
		) => {
			resetPasswordAndLogin && resetPasswordAndLogin(values)
			setSubmitting(false)
		},
	})

	return (
		<form className='flex flex-col gap-5' onSubmit={formik.handleSubmit}>
			<Input
				type='email'
				value={formik.values.email}
				name='email'
				label={t('fields.email')}
				onChange={formik.handleChange}
				errorMessage={
					formik.errors.email && formik.touched.email ? formik.errors.email : ''
				}
			/>
			<Input
				type={`${showPass ? 'text' : 'password'}`}
				value={formik.values.password}
				name='password'
				label={t('fields.password')}
				onChange={formik.handleChange}
				rightContent={showPass ? <AiFillEyeInvisible /> : <AiFillEye />}
				onClickRightContent={() => setShowPass(!showPass)}
				errorMessage={
					formik.errors.password && formik.touched.password
						? formik.errors.password
						: ''
				}
			/>
			<Input
				type={`${showConfirmPass ? 'text' : 'password'}`}
				value={formik.values.confirmPassword}
				name='confirmPassword'
				label={t('fields.confirm_password')}
				onChange={formik.handleChange}
				rightContent={showConfirmPass ? <AiFillEyeInvisible /> : <AiFillEye />}
				onClickRightContent={() => setShowConfirmPass(!showConfirmPass)}
				errorMessage={
					formik.errors.confirmPassword && formik.touched.confirmPassword
						? formik.errors.confirmPassword
						: ''
				}
			/>
			<button type='submit' className='btn btn-primary btn-sm rounded-md'>
				{resetPasswordLoading ? <ButtonLoader /> : t('api.reset_password')}
			</button>
		</form>
	)
}

export default ResetPasswordForm
