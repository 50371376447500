import LandingHeader from './components/header'
// import Stat from './components/stat'
import Testimonial from './components/testimonisal'
import WhatWeDo from './components/whatWeDo'
import WhoWeAre from './components/whoWeAre'
import WhyMach from './components/whyMach'

interface ILanding {
	hideHeader?: boolean
}

const Landing = (props: ILanding) => {
	const { hideHeader } = props

	return (
		<div className='font-metropolisMedium leading-8'>
			{!hideHeader && (
				<div className='w-full py-2 bg-white border-b-2 z-10 sticky top-0'>
					<LandingHeader />
				</div>
			)}
			<div className='flex flex-col'>
				<main
					id='what_we_do'
					className='md:mx-auto container px-4 xl:px-0 pb-6 lg:pb-28'
				>
					<WhatWeDo />
				</main>
				{/* <section id='statistic' className='md:mx-auto container p-4 xl:p-0'>
					<Stat />
				</section> */}
				<section id='references' className='background-color'>
					<div className='md:mx-auto container px-4 xl:px-0 py-6 lg:py-28'>
						<Testimonial />
					</div>
				</section>
				<section
					id='why'
					className='md:mx-auto container px-4 xl:px-0 py-6 lg:py-28'
				>
					<WhyMach />
				</section>
				<section id="contact" className='md:mx-auto container px-4 xl:px-0 py-6 lg:pt-28'>
					<WhoWeAre />
				</section>
			</div>
		</div>
	)
}

export default Landing
