import { gql } from '@apollo/client'

const ADD_TO_TAXATION = gql`
	mutation AddToTaxation($machine_id: ID!, $availability_date: DateTime!) {
		addToTaxation(
			machine_id: $machine_id
			availability_date: $availability_date
		) {
			id
		}
	}
`

const INVITE_TRADER_AUCTION = gql`
	mutation createBidRequest(
		$machine_id: ID!
		$group_ids: [Int]!
		$time_to_bid: Int!
	) {
		createBidRequest(
			group_ids: $group_ids
			machine_id: $machine_id
			time_to_bid: $time_to_bid
		) {
			id
		}
	}
`

const ADD_TO_AUCTION = gql`
	mutation AddToAuction(
		$machine_id: ID!
		$auction_day_id: ID!
		$valid_until: DateTime!
		$min_bid_amount: Float
	) {
		addToAuction(
			machine_id: $machine_id
			auction_day_id: $auction_day_id
			valid_until: $valid_until
			min_bid_amount: $min_bid_amount
		) {
			id
		}
	}
`

export { ADD_TO_TAXATION, ADD_TO_AUCTION, INVITE_TRADER_AUCTION }
