/* eslint-disable spaced-comment */
import { useTranslation } from 'react-i18next'
import Input from '../../../../../../../shared/components/Input'
import TextArea from '../../../../../../../shared/components/TextArea'
import { IOptions } from '../../../../../../../shared/components/Select'
import Toggle from '../../../../../../../shared/components/Toggle'
import Rating from '../../../../../../../shared/components/Rating'
import DatePicker from '../../../../../../../shared/components/DatePicker'
import MultiSelect from '../../../../../../../shared/components/MultiSelect'
import Tags from '../../../../../../../shared/components/tags'

interface IMachineDetailProps {
	isEdit: boolean
	formik: any
	fields: any[]
	stepperIndex: number
	setStepperIndex: (index: number) => void
	getOptions: (
		field: string,
		attributeId?: string,
		fieldType?: string
	) => IOptions[]
	ratingAndDateChangeHandler: (
		rating: any,
		fieldName: string,
		field: any
	) => void
	onToggleChangeHandler: (event: any, fieldName: string, field: any) => void
	onValueChangeHandler: (
		event: any,
		field: any,
		fieldName: string,
		tags?: any
	) => void
	machineLoading: boolean
}

const MachineSpecification = (props: IMachineDetailProps) => {
	const { t } = useTranslation()

	const {
		isEdit,
		fields,
		getOptions,
		onValueChangeHandler,
		onToggleChangeHandler,
		ratingAndDateChangeHandler,
		stepperIndex,
		setStepperIndex,
		formik,
		machineLoading,
	} = props

	const selectValue = (formattedName: string) => {
		if (
			!(
				formik.values &&
				(formik.values[formattedName] || formik.values[`${formattedName}_id`])
			)
		) {
			return ''
		}

		if (
			formattedName.toLowerCase() === 'brand' ||
			formattedName.toLowerCase() === 'model' ||
			formattedName.toLowerCase() === 'year_of_manufacture' ||
			formattedName.toLowerCase() === 'licence_plate'
		) {
			return String(formik.values[`${formattedName}_id`])
		}
		return formik.values[`${formattedName}`]
	}

	return (
		<form
			className='w-full md:w-[760px] mx-auto p-4 bg-white shadow-sm'
			onSubmit={formik.handleSubmit}
		>
			{fields &&
				Object.keys(fields).map((field: any, i: number) => (
					<div key={i} className='mb-5'>
						{fields[field].type === 'input' && (
							<Input
								type='text'
								value={
									formik.values && formik.values[fields[field].attributeName]
										? formik.values[fields[field].attributeName]
										: ''
								}
								name={fields[field].attributeName}
								onChange={event =>
									onValueChangeHandler(
										event.target.value,
										fields[field],
										fields[field].attributeName
									)
								}
								placeholder={`${
									fields[field].translatable
										? t(`fields.${fields[field].formattedName}`)
										: t(fields[field].formattedName.replaceAll('_', ' '))
								}`}
								label={`${
									fields[field].translatable
										? t(`fields.${fields[field].formattedName}`)
										: t(fields[field].formattedName.replaceAll('_', ' '))
								} ${fields[field].mandatory ? '*' : ''}`}
								errorMessage={formik.errors[fields[field].attributeName]}
							/>
						)}
						{fields[field].type === 'input_number' && (
							<Input
								type='number'
								value={
									formik.values && formik.values[fields[field].attributeName]
										? formik.values[fields[field].attributeName]
										: ''
								}
								name={fields[field].attributeName}
								onChange={event =>
									onValueChangeHandler(
										event.target.value,
										fields[field],
										fields[field].attributeName
									)
								}
								placeholder={`${
									fields[field].translatable
										? t(`fields.${fields[field].formattedName}`)
										: t(fields[field].formattedName.replaceAll('_', ' '))
								}`}
								label={`${
									fields[field].translatable
										? t(`fields.${fields[field].formattedName}`)
										: t(fields[field].formattedName.replaceAll('_', ' '))
								} ${fields[field].mandatory ? '*' : ''}`}
								errorMessage={formik.errors[fields[field].attributeName]}
							/>
						)}
						{fields[field].type === 'input_specification' && (
							<Input
								type='text'
								value={
									formik.values && formik.values[fields[field].attributeName]
										? formik.values[fields[field].attributeName]
										: ''
								}
								name={fields[field].attribute_name}
								onChange={event =>
									onValueChangeHandler(
										event.target.value,
										fields[field],
										fields[field].attributeName
									)
								}
								placeholder={`${
									fields[field].translatable
										? t(`fields.${fields[field].formattedName}`)
										: t(fields[field].formattedName.replaceAll('_', ' '))
								}`}
								label={`${
									fields[field].translatable
										? t(`fields.${fields[field].formattedName}`)
										: t(fields[field].formattedName.replaceAll('_', ' '))
								} ${fields[field].mandatory ? '*' : ''}`}
								errorMessage={formik.errors[fields[field].attributeName]}
							/>
						)}
						{fields[field].type === 'textarea' && (
							<TextArea
								value={
									formik.values && formik.values[fields[field].attributeName]
										? formik.values[fields[field].attributeName]
										: ''
								}
								name={fields[field].attribute_name}
								onChange={event =>
									onValueChangeHandler(
										event.target.value,
										fields[field],
										fields[field].attributeName
									)
								}
								placeholder={`${
									fields[field].translatable
										? t(`fields.${fields[field].formattedName}`)
										: t(fields[field].formattedName.replaceAll('_', ' '))
								}`}
								label={`${
									fields[field].translatable
										? t(`fields.${fields[field].formattedName}`)
										: t(fields[field].formattedName.replaceAll('_', ' '))
								} ${fields[field].mandatory ? '*' : ''}`}
								errorMessage={formik.errors[fields[field].attributeName]}
							/>
						)}
						{fields[field].type === 'select_direct' && (
							<MultiSelect
								variant='single'
								options={getOptions(
									fields[field].attributeName,
									fields[field].attributeId,
									fields[field].type
								)}
								name={fields[field].attribute_name}
								label={`${
									fields[field].translatable
										? t(`fields.${fields[field].formattedName}`)
										: t(fields[field].formattedName.replaceAll('_', ' '))
								} ${fields[field].mandatory ? '*' : ''}`}
								placeHolder={`${
									fields[field].translatable
										? t(`fields.${fields[field].formattedName}`)
										: t(fields[field].formattedName.replaceAll('_', ' '))
								}`}
								translatable={fields[field].translatable_value}
								translatableGroup='fields'
								value={selectValue(fields[field].attributeName)}
								onChange={value =>
									onValueChangeHandler(value, fields[field], fields[field].attributeName)
								}
								errorMessage={formik.errors[fields[field].attributeName]}
							/>
						)}
						{fields[field].type === 'select' && (
							<MultiSelect
								variant='single'
								other
								otherPlaceholder={t(`api.enter_${field}`)}
								options={getOptions(
									fields[field].attributeName,
									fields[field].attributeId,
									fields[field].type
								)}
								name={fields[field].attribute_name}
								label={`${
									fields[field].translatable
										? t(`fields.${fields[field].formattedName}`)
										: t(fields[field].formattedName.replaceAll('_', ' '))
								} ${fields[field].mandatory ? '*' : ''}`}
								placeHolder={`${
									fields[field].translatable
										? t(`fields.${fields[field].formattedName}`)
										: t(fields[field].formattedName.replaceAll('_', ' '))
								}`}
								translatable={fields[field].translatable_value}
								translatableGroup='fields'
								value={selectValue(fields[field].attributeName)}
								otherValue={
									formik.values && formik.values[`${fields[field].attributeName}_other`]
										? formik.values[`${fields[field].attributeName}_other`].replaceAll(
												'_',
												' '
										  )
										: ''
								}
								otherOnChange={event =>
									onValueChangeHandler(
										event.target.value,
										fields[field],
										`${fields[field].attributeName}_other`
									)
								}
								onChange={value =>
									onValueChangeHandler(value, fields[field], fields[field].attributeName)
								}
								errorMessage={formik.errors[fields[field].attributeName]}
							/>
						)}
						{fields[field].type === 'select_direct_from_specification' && (
							<MultiSelect
								variant='single'
								options={getOptions(
									fields[field].attributeName,
									fields[field].attributeId,
									fields[field].type
								)}
								name={fields[field].attribute_name}
								label={`${
									fields[field].translatable
										? t(`fields.${fields[field].formattedName}`)
										: t(fields[field].formattedName.replaceAll('_', ' '))
								} ${fields[field].mandatory ? '*' : ''}`}
								placeHolder={`${
									fields[field].translatable
										? t(`fields.${fields[field].formattedName}`)
										: t(fields[field].formattedName.replaceAll('_', ' '))
								}`}
								value={
									formik.values && formik.values[fields[field].attributeName]
										? formik.values[fields[field].attributeName].replaceAll('_', ' ')
										: ''
								}
								translatable={fields[field].translatable_value}
								translatableGroup='fields'
								onChange={value =>
									onValueChangeHandler(value, fields[field], fields[field].attributeName)
								}
								errorMessage={formik.errors[fields[field].attributeName]}
							/>
						)}
						{fields[field].type === 'select_from_specification' && (
							<MultiSelect
								variant='single'
								options={getOptions(
									fields[field].attributeName,
									fields[field].attributeId,
									fields[field].type
								)}
								name={fields[field].attribute_name}
								label={`${
									fields[field].translatable
										? t(`fields.${fields[field].formattedName}`)
										: t(fields[field].formattedName.replaceAll('_', ' '))
								} ${fields[field].mandatory ? '*' : ''}`}
								placeHolder={`${
									fields[field].translatable
										? t(`fields.${fields[field].formattedName}`)
										: t(fields[field].formattedName.replaceAll('_', ' '))
								}`}
								translatable={fields[field].translatable_value}
								translatableGroup='fields'
								value={
									formik.values && formik.values[fields[field].attributeName]
										? formik.values[fields[field].attributeName].replaceAll('_', ' ')
										: ''
								}
								onChange={value =>
									onValueChangeHandler(value, fields[field], fields[field].attributeName)
								}
								errorMessage={formik.errors[fields[field].attributeName]}
							/>
						)}
						{fields[field].type === 'boolean' && (
							<div className='flex justify-between items-center'>
								{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
								<label className='font-semibold '>
									{`${
										fields[field].translatable
											? t(`fields.${fields[field].formattedName}`)
											: t(fields[field].formattedName.replaceAll('_', ' '))
									} ${fields[field].mandatory ? '*' : ''}`}
								</label>
								<Toggle
									name={fields[field].attributeName}
									onChange={event =>
										onToggleChangeHandler(
											event.target.value,
											fields[field].attributeName,
											fields[field]
										)
									}
									checked={
										formik.values && formik.values[fields[field].attributeName]
											? formik.values[fields[field].attributeName]
											: false
									}
								/>
							</div>
						)}
						{fields[field].type === 'rating' && (
							<div className='flex justify-between items-center'>
								{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
								<label className='font-semibold '>
									{`${
										fields[field].translatable
											? t(`fields.${fields[field].formattedName}`)
											: t(fields[field].formattedName.replaceAll('_', ' '))
									} ${fields[field].mandatory ? '*' : ''}`}
								</label>
								<Rating
									value={
										formik.values && formik.values[fields[field].attributeName]
											? formik.values[fields[field].attributeName]
											: ''
									}
									setRating={rating =>
										ratingAndDateChangeHandler(
											rating,
											fields[field].attributeName,
											fields[field]
										)
									}
									errorMessage={formik.errors[fields[field].attributeName]}
								/>
							</div>
						)}
						{fields[field].type === 'select_date' && (
							<div className='flex flex-col'>
								{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
								<label className='font-semibold '>
									{`${
										fields[field].translatable
											? t(`fields.${fields[field].formattedName}`)
											: t(fields[field].formattedName.replaceAll('_', ' '))
									} ${fields[field].mandatory ? '*' : ''}`}
								</label>
								<div className='w-100'>
									<DatePicker
										variant='date_picker'
										dataEnableTime={false}
										option={{ enableTime: false }}
										setDate={date =>
											ratingAndDateChangeHandler(
												date,
												fields[field].attributeName,
												fields[field]
											)
										}
										value={
											formik.values && formik.values[fields[field].attributeName]
												? formik.values[fields[field].attributeName]
												: ''
										}
										errorMessage={formik.errors[fields[field].attributeName]}
									/>
								</div>
							</div>
						)}
						{fields[field].type === 'dropdown_year' && (
							<div className='flex flex-col'>
								{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
								<label className='font-semibold '>
									{`${
										fields[field].translatable
											? t(`fields.${fields[field].formattedName}`)
											: t(fields[field].formattedName.replaceAll('_', ' '))
									} ${fields[field].mandatory ? '*' : ''}`}
								</label>
								<div className='w-100'>
									<DatePicker
										variant='year_picker'
										dataEnableTime={false}
										option={{ enableTime: false, dateFormat: 'Y' }}
										setDate={date =>
											ratingAndDateChangeHandler(
												date,
												fields[field].attributeName,
												fields[field]
											)
										}
										value={
											formik.values && formik.values[fields[field].attributeName]
												? formik.values[fields[field].attributeName]
												: ''
										}
										errorMessage={formik.errors[fields[field].attributeName]}
									/>
								</div>
							</div>
						)}
						{fields[field].type === 'tag' && (
							<div className='w-100'>
								<Tags
									label={`${
										fields[field].translatable
											? t(`fields.${fields[field].formattedName}`)
											: t(fields[field].formattedName.replaceAll('_', ' '))
									} ${fields[field].mandatory ? '*' : ''}`}
									tags={getOptions(
										fields[field].attributeName,
										fields[field].attributeId,
										fields[field].type
									)}
									translatable={fields[field].translatable_value}
									translatableGroup='fields'
									selectTagIds={
										formik.values && formik.values[fields[field].attributeName]
									}
									onChange={tagIds =>
										onValueChangeHandler(
											tagIds,
											fields[field],
											fields[field].attributeName,
											getOptions(
												fields[field].attributeName,
												fields[field].attributeId,
												fields[field].type
											)
										)
									}
								/>
							</div>
						)}
					</div>
				))}
			<div className='flex justify-end mt-4 gap-4'>
				{!isEdit && (
					<button
						type='button'
						className={`btn btn-primary btn-sm w-24 ${
							machineLoading && 'btn-disabled'
						}`}
						onClick={() => setStepperIndex(stepperIndex - 1)}
					>
						{t('api.back')}
					</button>
				)}
				<button
					type='submit'
					className={`btn btn-secondary btn-sm  min-w-24 w-fit ${
						machineLoading && 'loading btn-disabled'
					}`}
				>
					{isEdit ? t('api.update') : t('api.next')}
				</button>
			</div>
		</form>
	)
}

export default MachineSpecification
