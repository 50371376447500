import { gql } from '@apollo/client'

const FIND_BID_BY_MACHINE_ID = gql`
	query FindBidByMachineId($machineID: ID!) {
		findBidByMachineId(machine_id: $machineID) {
			id
			value
			value_currency
			bid_date
			valid_until
			status
			remarks
			machine {
					status
			}
			user {
				user_details {
					first_name
					last_name
				}
				company {
					company_name
				}
			}
			bid_type
		}
		findHighestBidByMachineId(machine_id: $machineID) {
			id
			value
			value_currency
			bid_date
			valid_until
			status
			remarks
			user {
				user_details {
					first_name
					last_name
				}
				company {
					company_name
				}
			}
			bid_type
		}
	}
`

const FIND_PDF_URL_BY_MACHINE_ID = gql`
	query findPdfUrlByMachine($machine_id: ID!) {
		findPdfUrlByMachineId(machine_id: $machine_id)
	}
`

const GENERATE_PDF = gql`
	mutation generatePdf($id: ID!) {
		generatePdf(id: $id)
	}
`

export { FIND_BID_BY_MACHINE_ID, FIND_PDF_URL_BY_MACHINE_ID, GENERATE_PDF }

