import { MdFilterAlt, MdSearch } from 'react-icons/md'
import { ReactElement } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import Input from '../../../shared/components/Input'

interface IFilterBarProps {
	search: string
	searchName: string
	searchPlaceholder?: string
	children?: ReactElement
	setSearch: (search: string) => void
	handleFilterClick: () => void
}

const FilterBar = (props: IFilterBarProps) => {
	const {
		search,
		searchName,
		searchPlaceholder,
		children,
		setSearch,
		handleFilterClick,
	} = props

	const { t } = useTranslation()

	return (
		<div className='flex container mx-auto gap-4'>
			<div className='sidebar-style hidden px-2 md:flex flex-col'>
				<p className='text-right'>{`${t('api.year')} : ${moment().year()}`}</p>
				<p className='text-right'>
					{`${t('api.week')} : ${moment().week()}`}
					<sup>{`${
						moment().week() === 1 ? 'st' : moment().week() === 2 ? 'nd' : 'th'
					}`}</sup>
				</p>
			</div>
			<div className='flex justify-between items-center flex-wrap-reverse gap-2'>
				{children}
				<div className='hidden gap-2 justify-between items-center w-full md:w-60'>
					<Input
						type='text'
						value={search}
						name={searchName}
						placeholder={searchPlaceholder}
						onChange={event => setSearch(event.target.value)}
						leftContent={<MdSearch />}
					/>
					<MdFilterAlt size={20} className='md:hidden' onClick={handleFilterClick} />
				</div>
			</div>
		</div>
	)
}

export default FilterBar
