import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useTranslation } from 'react-i18next'

type PhoneInputComponentProps = {
	value?: string
	name: string
	placeHolder?: string
	onChange: (value: string) => void
	label?: string
	errorMessage?: string
}

const PhoneInputComponent = (props: PhoneInputComponentProps) => {
	const { value, name, placeHolder, label, onChange, errorMessage } = props

	const { t } = useTranslation()

	return (
		<div className='flex flex-col gap-2'>
			<label htmlFor='phoneNumber' className='font-semibold'>
				{label}
			</label>
			<PhoneInput
				country='nl'
				placeholder={placeHolder || 'Enter phone number'}
				value={value}
				onChange={(value: string) => onChange(value)}
				inputProps={{
					name,
				}}
				inputStyle={{
					width: '100%',
				}}
			/>
			{errorMessage && <p className='text-sm text-error'>{t(errorMessage)}</p>}
		</div>
	)
}

export default PhoneInputComponent
