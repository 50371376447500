/* eslint-disable camelcase */
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import { ReactElement, useState } from 'react'
import { omit, find, extend } from 'lodash'
import { toast } from 'react-toastify'

import Image from 'shared/components/Image'

import { IMachineData } from 'modules/admin/views/Machine/views/MachineUpload/graphql/model'
import { FIND_MACHINE_BY_ID } from 'modules/admin/views/Machine/views/MachineUpload/graphql/query'
import { useAuth } from 'modules/auth/context/useAuth'
import moment from 'moment'
import { currencyFormatter } from 'utils/currencyFormatter'
import ImageMagnify from './components/ImageMagnify'
import Loader from './components/loader'
import MachineSubHeader from './components/MachineSubHeader'
import MachineItemExtraInfo from './MachineItemExtraInfo'
import StatusCard from '../utils/getMachineStatus'
import STATUS from '../enums/STATUS'
import ROLE from '../enums/Roles'

const replaceText = (text: string) => text.replaceAll('_', ' ')

interface MachineItemOverviewProps {
	asideContent: ReactElement | null
	content?: ReactElement | null
	hideMagnifier: boolean
}

const MachineItemOverview = (props: MachineItemOverviewProps) => {
	const { asideContent, content, hideMagnifier } = props
	const { machineId } = useParams()
	const { t } = useTranslation()
	const { user } = useAuth()

	const isMyBids = window.location.href.includes('bidded-machine')
	const isCompanyMachine = window.location.href.includes('company-machine')

	const [machineData, setMachineData] = useState<any>({
		category: {},
		extra_data: {
			extra_data: '{}',
		},
	})

	const [machineImages, setMachineImages] = useState<any>([])
	const [machineExtraData, setMachineExtraData] = useState([])
	const [activeImageIndex, setActiveImageIndex] = useState<number>(0)
	const [machineUpdated, setMachineUpdated] = useState<boolean>(true)

	const companyId: string = String(user.company.id)

	const { role } = useAuth()

	const { loading, refetch } = useQuery<IMachineData>(FIND_MACHINE_BY_ID, {
		fetchPolicy: 'no-cache',
		skip: !machineId,
		variables: {
			id: parseInt(machineId || '0', 10),
		},
		onCompleted: (data: any) => {
			const machineData = JSON.parse(data.findMachineById)
			setMachineUpdated(machineData.updated)
			const acceptedBid: any = find(machineData.bid, [
				'user.company.id',
				companyId,
			])
			const machine = omit(machineData, ['extra_data', 'images'])
			extend(machine, {
				noBids: machineData?.bid?.length === 0,
				machine_bid_status: acceptedBid?.status,
				isNewBid: parseInt(acceptedBid?.value, 10) === 0,
			})
			setMachineData(machine || {})
			const { images, extra_data } = machineData
			setMachineExtraData(
				extra_data ? JSON.parse(extra_data?.extra_data || []) : []
			)
			setMachineImages(images || [])
		},
		onError: error => {
			toast.error(t('message.machine_load_fail') || error.message)
		},
	})

	if (loading) {
		return <Loader />
	}

	return (
		<div className='py-4 flex flex-col gap-4'>
			<div className='flex flex-col md:flex-row justify-between w-full gap-4'>
				<div
					className={`flex-1 w-full h-[400px] md:h-[600px]  flex flex-col justify-between md:flex-row gap-6 `}
				>
					{machineImages.length > 0 ? (
						<div className='w-full md:w-1/4 h-fit md:h-44 md:h-full flex md:flex-col justify-start gap-4 overflow-x-scroll overflow-y-hidden md:overflow-y-scroll md:overflow-x-hidden'>
							{machineImages.length ? (
								(machineImages || []).map(
									(image: { image: string; thumbnail_image: string }, index: number) => (
										<div
											tabIndex={0}
											role='button'
											className='cursor-pointer h-32 md:h-44 md:w-full '
											key={index}
											onMouseEnter={() => {
												setActiveImageIndex(index)
											}}
											onMouseLeave={() => {
												setActiveImageIndex(index)
											}}
											onClick={() => {
												setActiveImageIndex(index)
											}}
											onKeyDown={() => {}}
										>
											<div className='cursor-pointer h-44 w-[200px] md:w-full '>
												<Image
													source={image.thumbnail_image || image.image}
													altText={image.thumbnail_image || image.image}
													s3Image
													imageStyle={`h-44 w-[200px] md:w-full object-cover md:rounded-sm ${
														activeImageIndex !== index && 'filter grayscale'
													}`}
												/>
											</div>
										</div>
									)
								)
							) : (
								<div className='w-full h-full flex justify-center items-center'>
									<p>No Images</p>
								</div>
							)}
						</div>
					) : (
						<div className='w-full md:w-1/4 h-44 md:h-full gap-4 overflow-x-scroll overflow-y-hidden md:overflow-y-scroll md:overflow-x-hidden grid place-content-center'>
							<p>{t('api.no-thumbnail-images')}</p>
						</div>
					)}
					<div className='flex-1 rounded-md min-h-fit md:min-h-[500px]'>
						{hideMagnifier && content !== null ? (
							content
						) : (
							<ImageMagnify
								s3Image
								image={
									machineImages[activeImageIndex] &&
									machineImages[activeImageIndex].image
								}
							/>
						)}
					</div>
				</div>
				<div className='w-full flex flex-col gap-2 lg:w-1/4 min-h-[500px] bg-white drop-shadow-md rounded-md p-4 relative'>
					<div className='flex flex-col gap-1 justify-start'>
						<div className='flex justify-between'>
							<div>
								<p className='text-primary text-xl tracking-wide font-semibold'>
									{`#${machineId} ${machineData.brand || ''} ${
										machineData.model ? `- ${machineData.model}` : ''
									}`}
								</p>
								<h4 className='text-md tracking-wide font-semibold text-gray-500'>
									{machineData?.location || '-'}
								</h4>
							</div>
							<div className='w-full md:w-fit flex flex-wrap justify-end gap-2'>
								<StatusCard
									statusNumber={machineData.status}
									statusType='machineStatus'
								/>
								{role !== ROLE.ADMIN &&
									!machineData.isNewBid &&
									!machineData.noBids &&
									isMyBids && (
										<StatusCard
											statusNumber={machineData.machine_bid_status}
											statusType='bidStatus'
										/>
									)}
								{(role === ROLE.ADMIN || isCompanyMachine) && (
									<StatusCard
										statusNumber={machineData.bid_status}
										statusType='machineBidStatus'
									/>
								)}
							</div>
						</div>
						<hr />
						<div className='flex flex-col gap-2 py-2'>
							<div className='flex items-center'>
								<p className='text-primary text-md leading-6  break-words  font-avenirMedium'>
									{t('api.brand')}
								</p>
								<span>:</span>
								<p className='text-md leading-6 text-right flex-1 '>
									{machineData?.brand || t('api.not_available')}
								</p>
							</div>
							{/* machine type */}
							<div className='flex items-center'>
								<p className='text-primary text-md leading-6  break-words  font-avenirMedium'>
									{t('api.machine_type')}
								</p>
								<span>:</span>
								<p className=' text-md leading-6 text-right flex-1 '>
									{machineData?.machine_type || t('api.not_available')}
								</p>
							</div>
							{/* manufacture */}
							<div className='flex items-center'>
								<p className='text-primary text-md leading-6  break-words  font-avenirMedium'>
									{replaceText(t('api.year_of_manufacture'))}
								</p>
								<span>:</span>
								<p className=' text-md leading-6 text-right flex-1'>
									{machineData.year_of_manufacture || t('api.not_available')}
								</p>
							</div>
							{/* licence plate */}
							<div className='flex items-center'>
								<p className='text-primary text-md leading-6  break-words  font-avenirMedium'>
									{replaceText(t('api.licence_plate'))}
								</p>
								<span>:</span>
								<p className='text-md leading-6 text-right flex-1'>
									{machineData.licence_plate || t('api.not_available')}
								</p>
							</div>
							{/* running hours */}
							<div className='flex items-center'>
								<p className='text-primary text-md leading-6  break-words  font-avenirMedium'>
									{replaceText(t('api.running_hours'))}
								</p>
								<span>:</span>
								<p className='text-md leading-6 text-right flex-1'>
									{machineData.running_hours || t('api.not_available')}
								</p>
							</div>
							{/* location */}
							<div className='flex items-center'>
								<p className='text-primary text-md leading-6  break-words  font-avenirMedium'>
									{replaceText(t('api.location'))}
								</p>
								<span>:</span>
								<p className=' text-md leading-6 text-right flex-1'>
									{machineData.location || t('api.not_available')}
								</p>
							</div>
							{/* customer (seller) hours */}
							<div className='flex items-center'>
								<p className='text-primary text-md leading-6  break-words font-avenirMedium '>
									{t('api.customer_name')}
								</p>
								<span>:</span>
								<p className='text-md leading-6 text-right flex-1'>
									{machineData.customer_name || t('api.not_available')}
								</p>
							</div>
							{/* availability date (seller) hours */}
							{machineData.status === STATUS.TAXATION && (
								<div className='flex items-center'>
									<p className='text-primary text-md leading-6  break-words font-avenirMedium '>
										{t('api.availability_date')}
									</p>
									<span>:</span>
									<p className='text-md leading-6 text-right flex-1'>
										{moment(machineData.availability_date).format('DD-MM-YYYY') ||
											t('api.not_available')}
									</p>
								</div>
							)}

							{/* valid until */}
							<div className='flex items-center'>
								<p className='text-primary text-md leading-6  break-words  font-avenirMedium'>
									{t('api.validity')}
								</p>
								<span>:</span>
								<p className='text-md leading-6 text-right flex-1 '>
									{machineData?.valid_until
										? moment(machineData?.valid_until).format('DD-MM-YYYY hh:mm A')
										: t('api.valid_until_failed')}
								</p>
							</div>

							{/* machine auction Date */}
							{machineData.status === STATUS.AUCTION && (
								<div className='flex items-center'>
									<p className='text-primary text-md leading-6  break-words  font-avenirMedium'>
										{t('api.auction_date')}
									</p>
									<span>:</span>
									<p className=' text-md leading-6 text-right flex-1'>
										{(machineData.auctions &&
											moment(machineData?.auctions).format('DD-MM-YYYY')) ||
											t('api.not_available')}
									</p>
								</div>
							)}
							{machineData.min_bid_amount && (
								<div className='flex items-center'>
									<p className='text-primary text-md leading-6  break-words  font-avenirMedium'>
										{t('api.min_bid_amount')}
									</p>
									<span>:</span>
									<p className=' text-md leading-6 text-right flex-1'>
										{currencyFormatter(machineData.min_bid_amount) ||
											t('api.not_available')}
									</p>
								</div>
							)}
						</div>
					</div>
					<hr />
					<div>{asideContent}</div>
				</div>
			</div>
			<div className='flex flex-col gap-4'>
				<MachineSubHeader
					machineId={machineId}
					machineUpdated={machineUpdated}
					title={t('api.extra_fields')}
					machineStatus={machineData.status}
					refetch={refetch}
					machineLoading={loading}
				/>
				<MachineItemExtraInfo extraInfo={machineExtraData} />
			</div>
		</div>
	)
}

export default MachineItemOverview
