import {gql} from "@apollo/client";

const FIND_TRANSLATIONS = gql`
    query findTranslations{
        findTranslations
    }
`;

export {
    FIND_TRANSLATIONS
}
