import { useTranslation } from 'react-i18next'
import Table from '../../../../../shared/components/table/table'

const commissions = [
	{
		id: 1,
		transaction: '€ 0, -t/m € 10,000, - ',
		commission: '3%',
	},
	{
		id: 2,
		transaction: '€ 10,000, -t/m € 30,000, - ',
		commission: '2,5%',
	},
	{
		id: 3,
		transaction: '€ 30,000, -t/m € 50,000, - ',
		commission: '2%',
	},
	{
		id: 4,
		transaction: '€ 50,001, - en hoger',
		commission: '1,5%',
	},
]

const Rules = () => {
	const { t } = useTranslation()

	const columns: any = [
		{
			id: 'transaction',
			name: t('api.transaction'),
			sortable: true,
			selector: (row: { transaction: string }) => row.transaction,
			cell: (row: { transaction: string }) => (
				<p className='text-lg'> {row.transaction}</p>
			),
		},
		{
			id: 'commission',
			name: t('api.commission'),
			sortable: true,
			selector: (row: { commission: string }) => row.commission,
			cell: (row: { commission: string }) => (
				<p className='text-lg'> {row.commission}</p>
			),
		},
	]

	return (
		<div className='flex flex-col gap-4 max-h-screen overflow-y-auto'>
			<h1 className='text-2xl'>
				{t('api.company_name')} - {t('api.company_note')}
			</h1>

			<div className='flex flex-col gap-4'>
				<div className='flex flex-col gap-2'>
					<p className='font-bold text-lg'>{t('api.commissions')}</p>
					<Table columns={columns} data={commissions} pagination={false} />
				</div>
				<div className='flex flex-col gap-2'>
					<p className='font-bold text-lg'>{t('api.subscription')}</p>
					<p>{t('api.subscription_cost')}</p>
				</div>
				<div className='flex flex-col gap-2'>
					<p className='font-bold text-lg'>{t('api.delivery')}</p>
					<p>{t('api.terms_of_delivery')}</p>
				</div>
			</div>

			<p>{t('api.notes')}</p>

			<div>
				<p>{t('api.contact')}</p>
				<p>{t('api.company_name')}</p>
				<p>T - {t('api.company_phone')}</p>
				<p>M - {t('api.company_email')}</p>
			</div>
		</div>
	)
}
export default Rules
