import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md'
import { ReactElement, useState } from 'react'

interface ICollapseProps {
	title: string
	collapseStyle?: string
	titleStyle?: string
	bodyStyle?: string
	body: ReactElement
	openByDefault?: boolean
}

const Collapse = (props: ICollapseProps) => {
	const { title, body, titleStyle, collapseStyle, bodyStyle, openByDefault } =
		props

	const [openCollapse, setOpenCollapse] = useState<boolean>(
		Boolean(openByDefault)
	)

	return (
		<div
			className={`bg-white max-h-[33%] md:max-h-fit collapse w-full card rounded-md text overflow-hidden border-primary ${collapseStyle}`}
		>
			<input
				type='checkbox'
				checked={openCollapse}
				onChange={() => setOpenCollapse(!openCollapse)}
				className='p-0'
			/>
			<div
				className={`collapse-title p-4 flex items-center justify-between bg-primary text-md text-white  text-md ${titleStyle}`}
			>
				<p>{title}</p>
				{openCollapse && (
					<span className='text-2xl'>
						<MdKeyboardArrowDown />
					</span>
				)}
				{!openCollapse && (
					<span className='text-2xl'>
						<MdKeyboardArrowUp />
					</span>
				)}
			</div>
			<div className={`collapse-content p-0 ${bodyStyle}`}>{body}</div>
		</div>
	)
}

export default Collapse
