import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import LoginForm from '../components/LoginForm'
import Translation from '../../translation'

const Login = () => {
	const { t } = useTranslation()

	return (
		<div className='h-full w-full p-8 md:p-12 flex flex-col gap-5'>
			<div className='flex justify-between items-end'>
				<h1 className='text-2xl font-semibold'>{t('api.login')}</h1>
				<div className='flex gap-2 items-center'>
					<Translation />
					{/* <Link className='text-lg underline' to='/help'>
						{t('api.help')}
					</Link> */}
				</div>
			</div>
			<LoginForm />
			<Link to='/auth/forgot-password' className='text-center underline'>
				{t('api.forgot_password')}
			</Link>
			<Link
				to='/auth/signup'
				type='button'
				className='btn btn-outline btn-sm btn-primary rounded-md '
			>
				{t('api.signup')}
			</Link>
		</div>
	)
}

export default Login
