import { useTranslation } from 'react-i18next'
// import { useNavigate } from 'react-router-dom'
import whoWeAre from 'assets/images/who_we_are.png'
// import { useAuth } from 'modules/auth/context/useAuth'

const WhoWeAre = () => {
	const { t } = useTranslation()
	// const navigate = useNavigate()
	// const { isUserLoggedIn } = useAuth()

	return (
		<div className='w-full flex flex-col lg:flex-row justify-between gap-6'>
			<div className='flex-1 flex flex-col gap-4 md:gap-8'>
				<h2 className='text-2x md:text-5xl uppercase leading-tight font-bold max-w-sm'>
					{t('api.who_are_we')}
				</h2>
				<p className='font-normal'>{t('api.who_we_are_description')}</p>
				<div className='flex flex-col md-8 md:mt-16'>
					<p>{t('api.contact_name')}</p>
					<a href="tel:0031-6-13142820">{t('api.contact_number')}</a>
					<a href="mailto:joris@machmachinery.com">{t('api.contact_email')}</a>
					<a href='https://www.machmachinery.com'>
						{t('api.contact_website')}
					</a>
				</div>
			</div>
			<div className='flex-1 h-full'>
				<img
					src={whoWeAre}
					alt='user'
					className='object-cover h-full'
					loading='lazy'
				/>
			</div>
		</div>
	)
}

export default WhoWeAre
