interface IBackDropProps {
	openNavBar: boolean
	setBackDropStatus: (backDropStatus: boolean) => void
}

const BackDrop = (props: IBackDropProps) => {
	const {openNavBar ,setBackDropStatus} = props

	return (
	<div className={`z-30 fixed inset-0 transparent-opacity ${!openNavBar && 'hidden'}`}>
		<div aria-hidden onClick={() => setBackDropStatus(false)} className="absolute inset-0 bg-black opacity-50" />
	</div>
)}

export default BackDrop
