import { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'

interface ITextAreaProps {
	name: string
	label?: string
	placeholder?: string
	value: string
	onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void
	labelStyles?: string
	textAreaStyle?: string
	rows?: number
	cols?: number
	errorMessage?: string
}

function TextArea(props: ITextAreaProps) {
	const {
		name,
		label,
		placeholder,
		value,
		onChange,
		labelStyles,
		textAreaStyle,
		rows,
		cols,
		errorMessage,
	} = props

	const { t } = useTranslation()

	const defaultLabelStyle = 'font-semibold '
	const defaultTextAreaStyle =
		'w-full text-md outline outline-[1px] outline-gray-300 drop-shadow-sm focus-within:outline-secondary p-2 rounded-sm flex justify-between items-center'

	return (
		<div className='flex flex-col gap-1 w-full'>
			{label && (
				<label
					htmlFor={name}
					className={`${defaultLabelStyle} ${labelStyles || ''}`}
				>
					{label}
				</label>
			)}
			<div className={`${defaultTextAreaStyle} ${textAreaStyle}`}>
				<textarea
					className='rounded-none w-full focus:outline-none flex-1'
					id={name}
					name={name}
					value={value}
					placeholder={placeholder}
					onChange={onChange}
					rows={rows}
					cols={cols}
				/>
			</div>
			{errorMessage && 	<p className='text-sm text-error'>{t(errorMessage)}</p>}
		</div>
	)
}

export default TextArea
