import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { lazy, Suspense } from 'react'
import NotFound from 'modules/errors/NotFound'
import Commission from '../views/Commission'
import Loader from '../../../shared/components/loader'

const Types = lazy(() => import('../views/Types'))
const Currencies = lazy(() => import('../views/Currencies'))
const Translations = lazy(() => import('../views/Translations'))
const Specifications = lazy(() => import('../views/Specification'))
const Attributes = lazy(() => import('../views/Attributes'))
const ColumnPlatform = lazy(() => import('../views/ColumnPlatforms'))
const UserManagement = lazy(() => import('../views/UserManagement'))

const Categories = lazy(() => import('../views/Category'))
const Subcategories = lazy(() => import('../views/Subcategories'))
const Dealer = lazy(() => import('../views/Dealers'))
const Groups = lazy(() => import('../views/Groups'))
const Auction = lazy(() => import('../views/Auction'))
const MachineList = lazy(() => import('../views/Machine'))
const CompanyManagement = lazy(() => import('../views/CompanyManagement'))

const AdminRoute = () => {
	const location = useLocation()
	// @ts-ignore
	const fromLocation = location.state ? location.state.from : location

	return (
		<Suspense fallback={<Loader />}>
			<Routes>
				<Route
					path='/'
					element={
						<Navigate to='categories' replace state={{ from: fromLocation }} />
					}
				/>
				<Route path='/categories/*' element={<Categories />} />
				<Route path='/types/*' element={<Types />} />
				<Route path='/sub-categories/*' element={<Subcategories />} />
				<Route path='/currencies' element={<Currencies />} />
				<Route path='/translations/*' element={<Translations />} />
				<Route path='/specifications/*' element={<Specifications />} />
				<Route path='/attributes/*' element={<Attributes />} />
				<Route path='/column-platform/*' element={<ColumnPlatform />} />
				<Route path='/machines/*' element={<MachineList />} />
				<Route path='/user-management/*' element={<UserManagement />} />
				<Route path='/company-management/*' element={<CompanyManagement />} />
				<Route path='/dealer/*' element={<Dealer />} />
				<Route path='/group/*' element={<Groups />} />
				<Route path='/auction/*' element={<Auction />} />
				<Route path='/commission/*' element={<Commission />} />
				<Route path='*' element={<NotFound />} />
			</Routes>
		</Suspense>
	)
}

export default AdminRoute
