import { FormikHelpers, FormikProps, useFormik } from 'formik'
import * as Yup from 'yup'
import { useState } from 'react'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import { useTranslation } from 'react-i18next'
import { LoginDataPropsType, useAuth } from '../context/useAuth'
import Input from '../../../shared/components/Input'
import ButtonLoader from '../../../shared/components/buttonLoader'

const LoginForm = () => {
	const { t } = useTranslation()

	const { loginUser, isLoginLoading } = useAuth()

	const formik: FormikProps<LoginDataPropsType> = useFormik<LoginDataPropsType>({
		initialValues: {
			email: '',
			password: '',
		},
		validateOnChange: false,
		validateOnBlur: false,
		validationSchema: Yup.object().shape({
			email: Yup.string()
				.email('message.enter_valid_email')
				.required('message.email_is_required'),
			password: Yup.string().required('message.password_is_required'),
		}),
		onSubmit: async (
			values,
			{ setSubmitting }: FormikHelpers<LoginDataPropsType>
		) => {
			loginUser && loginUser(values)
			setSubmitting(false)
		},
	})

	const [showPass, setShowPass] = useState<boolean>(false)

	return (
		<form className='flex flex-col gap-5' onSubmit={formik.handleSubmit}>
			<Input
				type='email'
				value={formik.values.email}
				name='email'
				label={t('fields.email')}
				onChange={formik.handleChange}
				errorMessage={
					formik.touched.email && formik.errors.email ? formik.errors.email : ''
				}
			/>
			<Input
				type={`${showPass ? 'text' : 'password'}`}
				value={formik.values.password}
				name='password'
				label={t('fields.password')}
				onChange={formik.handleChange}
				rightContent={showPass ? <AiFillEyeInvisible /> : <AiFillEye />}
				onClickRightContent={() => setShowPass(!showPass)}
				errorMessage={
					formik.touched.password && formik.errors.password
						? formik.errors.password
						: ''
				}
			/>
			<button type='submit' className='btn btn-primary btn-sm  rounded-md '>
				{isLoginLoading ? <ButtonLoader /> : t('api.login')}
			</button>
		</form>
	)
}

export default LoginForm
