import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

export interface IStepperStep {
	id: number
	label: string
	description: string
}

export interface IStepBody {
	step: ReactElement
	id: number
}

export interface IStepperBarProps {
	Steps: IStepperStep[]
	activeStep: number
	hasTitleLabel?: boolean
	children?: ReactElement
	stepBodies: IStepBody[]
}

const Stepper = (props: IStepperBarProps) => {
	const { Steps, activeStep, stepBodies, children, hasTitleLabel } = props
	const { t } = useTranslation()

	return (
		<div className='h-full w-full flex flex-col gap-8'>
			<div className='flex justify-between items-center'>
				<ul className='flex h-fit w-full justify-center flex-wrap gap-8'>
					{Steps.map(step => (
						<li key={step.id}>
							<div
								aria-hidden='true'
								className='flex items-center gap-4 cursor-pointer'
								key={step.id}
							>
								<div
									className={`rounded-full border-2 border-primary h-12 w-12 text-lg font-bold flex items-center justify-center ${
										step.id === activeStep && 'text-white bg-primary'
									}`}
								>
									0{step.id}
								</div>
								<div className='hidden md:flex flex-col justify-center font-bold'>
									<p className={`${step.id === activeStep && 'text-secondary'}`}>
										{step.label}
									</p>
									<p
										className={`text-gray-400 font-bold text-sm ${
											step.id === activeStep && 'text-primary'
										}`}
									>
										{step.description}
									</p>
								</div>
							</div>
						</li>
					))}
				</ul>
				{children}
			</div>
			{hasTitleLabel && (
				<div className='w-full md:w-[760px] mx-auto'>
					<p className='text-lg text-center'>{t('api.add_machine_info')}</p>
				</div>
			)}
			<div className='flex-1'>
				{stepBodies.map(stepBody => {
					if (stepBody.id === activeStep) {
						return <div key={stepBody.id}>{stepBody.step}</div>
					}
					return null
				})}
			</div>
		</div>
	)
}

export default Stepper
