import { gql } from '@apollo/client'

export const FIND_ALL_SUBCATEGORIES = gql`
    query FindAllSubcategories {
        findAllSubcategories {
            id
            name
            extra_field {
            	id
            	field_name
            }
        }
    }
`

export const FIND_ALL_SUBCATEGORY_WITH_PAGINATION = gql`
	query FindALlSubCategoryWithPagination($first: Int!, $page: Int, $name: String) {
		findAllSubcategoriesWithPagination(first: $first, page: $page, name: $name){
				paginatorInfo {
					count
					currentPage
					hasMorePages
					total
				}
				data {
						id
						name
						extra_field {
							id
							field_name
						}
				}
			}
	}
`

export const FIND_ALL_EXTRA_FIELD_SUBCATEGORIES = gql`
    query FindAllExtraFieldSubcategories {
        findAllExtraFieldSubcategories {
            	id
            	field_name
        }
    }
`

export const FIND_SUBCATEGORY_BY_ID = gql`
    query findSubcategoryById($id: Int!) {
        findSubcategoryById(id:$id) {
            id
            name
            extra_field {
            		id
														field_name
														position
            }
        }
    }
`


const SubcategoryQueries = {
	FIND_ALL_SUBCATEGORIES,
	FIND_SUBCATEGORY_BY_ID,
	FIND_ALL_EXTRA_FIELD_SUBCATEGORIES,
	FIND_ALL_SUBCATEGORY_WITH_PAGINATION
}

export default SubcategoryQueries
