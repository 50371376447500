import { useTranslation } from 'react-i18next'
import { FormikProps, useFormik } from 'formik'
import { useMutation, useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useParams, useSearchParams } from 'react-router-dom'
import { ICategoryData } from '../../../../admin/views/Category/graphql/model'
import {
	FIND_ALL_CATEGORIES_ONLY_NAME,
	FIND_ALL_TYPES_ONLY_NAME,
	FIND_PREFERENCE,
	FIND_SUB_CATEGORY_BY_TYPE_ID,
	FIND_TYPE_BY_CATEGORY_ID
} from '../../../graphql/query'
import { IType, ITypeData } from '../../../../admin/views/Types/graphql/model'
import {
	ISubcategory,
	ISubcategoryData
} from '../../../../admin/views/Subcategories/graphql/model'
import { FIND_ALL_SUBCATEGORIES } from '../../../../admin/views/Subcategories/graphql/query'
import Checkbox from '../../../../../shared/components/Checkbox'
import { CREATE_PREFERENCE, UPDATE_PREFERENCE, UPDATE_USER_PREFERENCE_SUBSCRIPTION } from '../../../graphql/mutation'
import { useAuth } from '../../../../auth/context/useAuth'
import { IPreferenceData } from '../../../graphql/model'
import Loader from '../../../../../shared/components/loader'
import AlertModal from '../../../../../shared/AlertModal'

interface IPreferenceForm {
	selectedCategories: number[]
	selectedTypes: number[]
	selectedSubCategories: number[]
}

const Preferences = () => {
	const { t } = useTranslation()
	const { user } = useAuth()
	const { id } = useParams()
	const [searchParams, setSearchParams] = useSearchParams()
	const [isSubscribed, setIsSubscribed] = useState<boolean>(false)

	const model: string | null = searchParams && searchParams.get('model') ? searchParams.get('model') : null

	const { data: categories, loading: categoriesLoading } = useQuery<ICategoryData>(
		FIND_ALL_CATEGORIES_ONLY_NAME
	)
	const { data: types } = useQuery<ITypeData>(FIND_ALL_TYPES_ONLY_NAME)
	const { data: subCategories } = useQuery<ISubcategoryData>(
		FIND_ALL_SUBCATEGORIES
	)

	const [filterTypes, setFilterTypes] = useState<IType[]>()
	const [filterSubCategory, setFilterSubCategory] = useState<ISubcategory[]>()
	const [preferenceAvailable, setPreferenceAvailable] = useState<boolean>(false)

	const [createPreference, { loading: createPreferenceLoading }] = useMutation(
		CREATE_PREFERENCE,
		{
			refetchQueries: [{ query: FIND_PREFERENCE, variables: { user_id: id } }],
			onCompleted() {
				toast.success(`${t('message.create_preference_success')}`)
			},
			onError(error) {
				toast.error(error.message || `${t('message.create_preference_fail')}`)
			}
		}
	)

	const [updatePreference, { loading: updatePreferenceLoading }] = useMutation(
		UPDATE_PREFERENCE,
		{
			refetchQueries: [{ query: FIND_PREFERENCE, variables: { user_id: id } }],
			onCompleted() {
				toast.success(`${t('message.update_preference_success')}`)
			},
			onError(error) {
				toast.error(error.message || `${t('message.update_preference_fail')}`)
			}
		}
	)

	const removeParams = () => {
		searchParams.delete('model')
		setSearchParams(searchParams)
	}

	const setParams = () => {
		searchParams.set('model','subscription')
		setSearchParams(searchParams)
	}

	const formik: FormikProps<IPreferenceForm> = useFormik<IPreferenceForm>({
		initialValues: {
			selectedCategories: [],
			selectedTypes: [],
			selectedSubCategories: []
		},
		onSubmit: async values => {
			if (preferenceAvailable) {
				await updatePreference({
					variables: {
						userId: id || user.id,
						categoryIds: values.selectedCategories.join(','),
						typeIds: values.selectedTypes.join(','),
						subCategoryIds: values.selectedSubCategories.join(',')
					}
				})
			} else {
				await createPreference({
					variables: {
						userId: id || user.id,
						categoryIds: values.selectedCategories.join(','),
						typeIds: values.selectedTypes.join(','),
						subCategoryIds: values.selectedSubCategories.join(',')
					}
				})
			}
		}
	})

	const { loading: preferenceLoading } = useQuery<IPreferenceData>(
		FIND_PREFERENCE,
		{
			variables: {
				user_id:  id,
			},
			onCompleted: data => {
				if (data.findPreference) {
					setPreferenceAvailable(true)
					setIsSubscribed(data.findPreference.is_subscribed)
					data.findPreference.category_ids &&
					formik.setFieldValue(
						'selectedCategories',
						data.findPreference.category_ids.split(',').map(id => Number(id))
					)
					data.findPreference.type_ids &&
					formik.setFieldValue(
						'selectedTypes',
						data.findPreference.type_ids.split(',').map(id => Number(id))
					)
					data.findPreference.subcategory_ids &&
					formik.setFieldValue(
						'selectedSubCategories',
						data.findPreference.subcategory_ids.split(',').map(id => Number(id))
					)
				}
			},
			onError: error => {
				toast.error(error.message || `${t('message.preference_lo_fail')}`)
			}
		}
	)

	const { data: typeByCategory, loading: typeLoading } = useQuery<ITypeData>(
		FIND_TYPE_BY_CATEGORY_ID,
		{
			skip: Boolean(!formik.values.selectedCategories.length),
			variables: {
				category_ids: formik.values.selectedCategories
			}
		}
	)

	const { data: subCategoryByType, loading: subCategoryLoading } = useQuery<ISubcategoryData>(
		FIND_SUB_CATEGORY_BY_TYPE_ID,
		{
			skip: Boolean(!(formik.values.selectedTypes.length || formik.values.selectedCategories.length)),
			variables: {
				type_ids: formik.values.selectedTypes,
				category_ids: formik.values.selectedCategories,
			}
		}
	)
	const [updateUserPreferenceSubscription, {loading: userPreferenceSubscriptionLoading}] = useMutation(UPDATE_USER_PREFERENCE_SUBSCRIPTION,
		{
			refetchQueries: [{ query: FIND_PREFERENCE }],
			onCompleted() {
				toast.success(`${isSubscribed ? t('message.unsubscription_success') : t('message.subscription_success')}`)
				removeParams()
			},
			onError(error) {
				toast.error(error.message || `${isSubscribed ? t('message.unsubscription_fail') : t('message.subscription_fail')}`)
			}
		})

	useEffect(() => {
		if (formik.values.selectedCategories.length > 0) {
			const tempTypes = typeByCategory?.findTypeByCategory
			const tempSelectedTypes = tempTypes?.filter(type =>
				formik.values.selectedTypes.includes(parseInt(String(type.id), 10))
			)
			if (tempTypes) {
				if (tempSelectedTypes) {
					formik.setFieldValue(
						'selectedTypes',
						tempSelectedTypes.map(type => parseInt(String(type.id), 10))
					)
				}
				setFilterTypes(tempTypes)
			}
		} else {
			types?.findAllTypes && setFilterTypes(types?.findAllTypes)
		}
	}, [formik.values.selectedCategories.length, types, typeByCategory])

	useEffect(() => {
		if (formik.values.selectedCategories.length > 0 || formik.values.selectedTypes.length > 0) {
			const tempSubCategories = subCategoryByType?.findSubCategoryByTypeIds
			const tempSelectedSubCategory = tempSubCategories?.filter(type =>
				formik.values.selectedSubCategories.includes(parseInt(String(type.id), 10))
			)
			if (tempSubCategories) {
				if (tempSelectedSubCategory) {
					formik.setFieldValue(
						'selectedSubCategories',
						tempSelectedSubCategory.map(subCategory =>
							parseInt(String(subCategory.id), 10)
						)
					)
				}
				setFilterSubCategory(tempSubCategories)
			}
		} else {
			subCategories?.findAllSubcategories &&
			setFilterSubCategory(subCategories.findAllSubcategories)
		}
	}, [formik.values.selectedTypes.length, subCategories, subCategoryByType])

	const handleChange = (
		id: number,
		type: 'category' | 'type' | 'subCategory'
	) => {
		switch (type) {
			case 'category':
				if (formik.values.selectedCategories.includes(id)) {
					const tempArray = formik.values.selectedCategories
					formik.setFieldValue(
						'selectedCategories',
						tempArray.filter((categoryId: any) => categoryId !== id)
					)
				} else {
					formik.setFieldValue('selectedCategories', [
						...formik.values.selectedCategories,
						id
					])
				}
				break
			case 'type':
				if (formik.values.selectedTypes.includes(id)) {
					const tempArray = formik.values.selectedTypes
					formik.setFieldValue(
						'selectedTypes',
						tempArray.filter((typeId: any) => typeId !== id)
					)
				} else {
					formik.setFieldValue('selectedTypes', [...formik.values.selectedTypes, id])
				}
				break
			case 'subCategory':
				if (formik.values.selectedSubCategories.includes(id)) {
					const tempArray = formik.values.selectedSubCategories
					formik.setFieldValue(
						'selectedSubCategories',
						tempArray.filter((subCategoryId: any) => subCategoryId !== id)
					)
				} else {
					formik.setFieldValue('selectedSubCategories', [
						...formik.values.selectedSubCategories,
						id
					])
				}
				break
			default:
		}
	}

	if (preferenceLoading) {
		return <Loader />
	}

	return (
		<>
			<div className='h-full w-full flex flex-col gap-4 justify-between'>
				<div className='flex flex-col md:flex-row w-full justify-between'>
					<h1 className='text-2xl'>{t('api.preference')}</h1>
					{preferenceAvailable && <button type='button' className='btn btn-sm btn-primary' onClick={setParams}>
						{isSubscribed ? t('api.unsubscribe') : t('api.subscribe')}
					</button>}
				</div>
				<form
					className='flex-1 flex flex-col justify-between gap-2'
					onSubmit={formik.handleSubmit}
				>
					<div className='h-[68vh] overflow-auto flex flex-col gap-6'>
						<div className='flex flex-col gap-2'>
							<p className='text-md md:text-xl'>{t('api.select_categories_to_get_notifications')}</p>
							<div className='grid gap-2 grid-cols-1 md:grid-cols-3 lg:grid-cols-4'>
								{categoriesLoading ? <Loader /> : categories?.findAllCategories?.map((category, index) => (
									<Checkbox
										key={index}
										name={category.name.replace('selectedCategories.', '')}
										checked={formik.values.selectedCategories!.includes(
											parseInt(category.id.toString(), 10)
										)}
										onChange={async () => {
											handleChange(parseInt(category.id.toString(), 10), 'category')
										}}
										labelStyles='break-words font-normal text-lg'
										label={t(`categories.${category.name}`)}
									/>
								))}
							</div>
						</div>
						<div className='flex flex-col gap-2'>
							<p className='text-md md:text-xl'>{t('api.select_types_to_get_notifications')}</p>
							<div className='grid gap-2 grid-cols-1 md:grid-cols-3 lg:grid-cols-4'>
								{typeLoading ? <Loader /> : filterTypes &&
									filterTypes.map((type, index) => (
										<Checkbox
											key={index}
											name={type.name.replace('type.', '')}
											checked={formik.values.selectedTypes!.includes(
												parseInt(type.id.toString(), 10)
											)}
											onChange={async () => {
												handleChange(parseInt(type.id.toString(), 10), 'type')
											}}
											labelStyles='break-words font-normal text-lg'
											label={t(`type.${type.name}`)}
										/>
									))}
							</div>
						</div>
						<div className='flex flex-col gap-2'>
							<p className='text-md md:text-xl'>{t('api.select_subcategories_to_get_notifications')}</p>
							<div className='grid gap-2 grid-cols-1 md:grid-cols-3 lg:grid-cols-4'>
								{subCategoryLoading ? <Loader /> : filterSubCategory &&
									filterSubCategory.map((subCategory, index) => (
										<Checkbox
											key={index}
											name={subCategory.name.replace('subcategory.', '')}
											checked={formik.values.selectedSubCategories!.includes(
												parseInt(subCategory.id.toString(), 10)
											)}
											onChange={async () => {
												handleChange(parseInt(subCategory.id.toString(), 10), 'subCategory')
											}}
											labelStyles='break-words font-normal text-lg'
											label={t(`subcategory.${subCategory.name}`)}
										/>
									))}
							</div>
						</div>
					</div>
					<div className='flex h-10 justify-end gap-4'>
						<button
							type='submit'
							className={`btn btn-sm btn-secondary ${
								createPreferenceLoading || updatePreferenceLoading
									? 'disabled loading'
									: ''
							}`}
						>
							{preferenceAvailable ? t('api.update') : t('api.create')}
						</button>
					</div>
				</form>
			</div>
			{
				model === 'subscription' && (
					<AlertModal
						title={isSubscribed ? t('message.unsubscribe_email_title') : t('message.subscribe_email_title')}
						description={isSubscribed ? t('message.unsubscribe_email_description') : t('message.subscribe_email_description')}
						okText={isSubscribed ? t('api.unsubscribe') : t('api.subscribe')}
						cancelText={t('api.cancel')}
						okOnClick={() => updateUserPreferenceSubscription({variables: {
							isSubscribed: !isSubscribed
							}})}
						okClickLoader={userPreferenceSubscriptionLoading}
						cancelOnClick={() => removeParams()}/>
				)
			}
		</>
	)
}

export default Preferences
