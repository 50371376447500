import { ReactElement } from 'react'
import { ReactSortable } from 'react-sortablejs'

interface GridDragnDropType {
	list: any
	setList: any
	onChange?: any
	children?: ReactElement
}

const GridDragnDrop = (props: GridDragnDropType) => {
	const { list, setList, children, onChange } = props
	return (
		<ReactSortable
			list={list}
			setList={setList}
			onChange={onChange && onChange}
			group='cards'
			animation={200}
			delay={2}
			className='w-full flex justify-center items-center flex-wrap gap-4 border-2 p-4 border-dashed border-base-300'
		>
			{children}
		</ReactSortable>
	)
}

export default GridDragnDrop
